import { Link } from "react-router-dom";
import { Anchor, List, Paper, Space, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { TermsLayout } from "../../layouts/TermsLayout";

export const PrivacyPolicy = () => {
  const matches = useMediaQuery("(max-width: 485px)");

  return (
    <TermsLayout>
      <Title size={matches ? 36 : 48}>Privacy Policy</Title>
      <Text>Effective date: 6th of JListy, 2024</Text>
      <Space h={matches ? 40 : 80} />
      <Paper bg="#000" p="xl" c="#fff" radius={16} px={16} py={16}>
        <Text ta="start">
          At Competenz Lab, we take your privacy seriously. Please read this
          Privacy Policy to learn how we treat your personal data. By using or
          accessing our Services in any manner, you acknowledge that you accept
          the practices and policies outlined below, and you hereby consent that
          we will collect, use and share your information as described in this
          Privacy Policy.
        </Text>
        <br />
        <Text ta="start">
          Remember that your use of Competenz Lab's Services is at all times
          subject to our Terms of Service, which incorporates this Privacy
          Policy. Any terms we use in this Policy without defining them have the
          definitions given to them in the Terms of Service.
        </Text>
        <br />
        <Text ta="start">
          If you have a disability, you may access this Privacy Policy in an
          alternative format by contacting{" "}
          <Anchor
            c="#DE57CC"
            to="mailto:hello@competenzlab.com."
            component={Link}
          >
            hello@competenzlab.com.
          </Anchor>
        </Text>
      </Paper>
      <Space h={matches ? 40 : 80} />
      <Title size={36} ta="start">
        Will these terms ever change?
      </Title>
      <br />
      <Text ta="start">
        <List>
          <List.Item>What this Privacy Policy Covers</List.Item>
          <List.Item>Personal Data</List.Item>
          <List.Item>How We Share Your Personal Data</List.Item>
          <List.Item>Tracking Tools and Opt-Out</List.Item>
          <List.Item> Data Security and Retention</List.Item>
          <List.Item>Personal Data of Children</List.Item>
          <List.Item>Other State Law Privacy Rights</List.Item>
          <List.Item>European Union Data Subject Rights</List.Item>
          <List.Item>Changes to this Privacy Policy</List.Item>
          <List.Item>Contact Information</List.Item>
        </List>
      </Text>
      <br />
      <Title size={36} ta="start">
        Personal data
      </Title>
      <br />
      <Text ta="start">
        This list details the categories of Personal Data that we collect and
        have collected over the past 12 months:
      </Text>
      <br />
      <Title size={24} ta="start">
        Profile or contact data
      </Title>
      <br />
      <Text ta="start">
        Examples of Personal Data We Collect:
        <List>
          <List.Item>First and last name</List.Item>
          <List.Item>Email</List.Item>
          <List.Item>Date of birth</List.Item>
          <List.Item>Physical address</List.Item>
          <List.Item>Unique identifiers such as passwords</List.Item>
          <List.Item>
            Categories of Third Parties With Whom We Share this Personal Dat
          </List.Item>
        </List>
        <br />
        Service Providers Parties:
        <List>
          <List.Item>You Authorize, Access or Authenticate</List.Item>
        </List>
      </Text>
      <br />
      <Title size={24} ta="start">
        Payment data
      </Title>
      <br />
      <Text ta="start">
        Examples of Personal Data We Collect:
        <List>
          <List.Item>Payment card type</List.Item>
          <List.Item>Last 4 digits of payment card</List.Item>
          <List.Item>Billing address, phone number, and email</List.Item>
        </List>
        <br />
        Categories of Third Parties With Whom We Share this Personal Data:
        <List>
          <List.Item>
            Service Providers (specifically our payment processing partner)
          </List.Item>
        </List>
      </Text>
      <br />
      <Title size={24} ta="start">
        Device/IP data
      </Title>
      <br />
      <Text ta="start">
        Examples of Personal Data We Collect:
        <List>
          <List.Item>IP address</List.Item>
          <List.Item>Device ID</List.Item>
          <List.Item>Domain server</List.Item>
          <List.Item>
            Type of device/operating system/browser used to access the Services
          </List.Item>
        </List>
        <br />
        Categories of Third Parties With Whom We Share this Personal Data:
        <List>
          <List.Item>Service Providers</List.Item>
          <List.Item>Parties You Authorize, Access or Authenticate</List.Item>
        </List>
      </Text>
      <br />
      <Title size={24} ta="start">
        Web analytics
      </Title>
      <br />
      <Text ta="start">
        Examples of Personal Data We Collect:
        <List>
          <List.Item>Web page interactions</List.Item>
          <List.Item>
            Referring webpage/source through which you accessed the Services
          </List.Item>
          <List.Item>Non-identifiable request IDs</List.Item>
          <List.Item>
            Statistics associated with the interaction between device or browser
            and the Services
          </List.Item>
        </List>
        <br />
        Categories of Third Parties With Whom We Share this Personal Data:
        <List>
          <List.Item>Service Providers</List.Item>
          <List.Item>Parties You Authorize, Access or Authenticate</List.Item>
        </List>
      </Text>
      <br />
      <Title size={24} ta="start">
        Other identifying information that you voluntarily choose to provide
      </Title>
      <br />
      <Text ta="start">
        Examples of Personal Data We
        <List>
          <List.Item>
            Collect Identifying information in emails or letters you send us
          </List.Item>
        </List>
        <br />
        Categories of Third Parties With Whom We Share this Personal Data:
        <List>
          <List.Item>Service Providers</List.Item>
          <List.Item>Business Partners</List.Item>
          <List.Item>Parties You Authorize, Access or Authenticate</List.Item>
        </List>
      </Text>
      <br />
      <Title size={36} ta="start">
        Categories of sources of personal data
      </Title>
      <br />
      <Text ta="start">
        We collect Personal Data about you from the following categories of
        sources:
      </Text>
      <br />
      <Title size={24} ta="start">
        You
      </Title>
      <br />
      <Text ta="start">
        <List>
          <List.Item>
            When you provide such information directly to us.
          </List.Item>
          <List.Item>
            When you create an account or use our interactive tools and
            Services.
          </List.Item>
          <List.Item>
            When you voluntarily provide information in free-form text boxes
            through the Services or through responses to surveys or
            questionnaires.
          </List.Item>
          <List.Item>
            When you send us an email or otherwise contact us.
          </List.Item>
          <List.Item>
            When you use the Services and such information is collected
            automatically.
          </List.Item>
          <List.Item>
            Through Cookies (as defined and described further below).
          </List.Item>
          <List.Item>
            If you download our mobile application or use a location-enabled
            browser, we may receive information about your location and mobile
            device, as applicable.
          </List.Item>
          <List.Item>
            If you download and install certain applications and software we
            make available, we may receive and collect information transmitted
            from your computing device for the purpose of providing you the
            relevant Services, such as information regarding when you are logged
            on and available to receive updates or alert notices.
          </List.Item>
        </List>
      </Text>
      <br />
      <Title size={24} ta="start">
        Third Parties
      </Title>
      <br />
      <Text ta="start">
        <List>
          <List.Item>Vendors</List.Item>
        </List>
        <br />
        We may use analytics providers to analyze how you interact and engage
        with the Services, or third parties may help us provide you with
        customer support.
        <br />
        <List>
          <List.Item>
            Providing, Customizing and Improving the Services.
          </List.Item>
          <List.Item>
            Creating and managing your account or other user profiles.
          </List.Item>
          <List.Item>
            Processing orders or other transactions; billing.
          </List.Item>
          <List.Item>
            Providing you with the products, services or information you
            request.
          </List.Item>
          <List.Item>
            Meeting or fulfilling the reason you provided the information to us.
          </List.Item>
          <List.Item>
            Providing support and assistance for the Services.
          </List.Item>
          <List.Item>
            Improving the Services, including testing, research, internal
            analytics and product development.
          </List.Item>
          <List.Item>
            Personalizing the Services, website content and communications based
            on your preferences.
          </List.Item>
          <List.Item>Doing fraud protection, security and debugging.</List.Item>
          <List.Item>
            Carrying out other business purposes stated when collecting your
            Personal Data or as otherwise set forth in applicable data privacy
            laws.
          </List.Item>
          <List.Item>Marketing the Services</List.Item>
          <List.Item>Marketing and selling the Services.</List.Item>
          <List.Item>Corresponding with You</List.Item>
          <List.Item>
            Responding to correspondence that we receive from you, contacting
            you when necessary or requested, and sending you information about
            Competenz Lab or the Services.
          </List.Item>
          <List.Item>
            Sending emails and other communications according to your
            preferences or that display content that we think will interest you.
          </List.Item>
          <List.Item>
            Meeting Legal Requirements and Enforcing Legal Terms.
          </List.Item>
          <List.Item>
            Fulfilling our legal obligations under applicable law, regulation,
            court order or other legal process, such as preventing, detecting
            and investigating security incidents and potentially illegal or
            prohibited activities.
          </List.Item>
          <List.Item>
            Protecting the rights, property or safety of you, Competenz Lab or
            another party.
          </List.Item>
          <List.Item>Enforcing any agreements with you.</List.Item>
          <List.Item>
            Responding to claims that any posting or other content violates
            third-party rights.
          </List.Item>
          <List.Item>Resolving disputes.</List.Item>
        </List>
        <br />
        We will not collect additional categories of Personal Data or use the
        Personal Data we collected for materially different, unrelated or
        incompatible purposes without providing you notice.
      </Text>
      <br />
      <Title size={36} ta="start">
        Tracking tools and opt-out
      </Title>
      <br />
      <Text ta="start">
        The Services use cookies and similar technologies such as pixel tags,
        web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to
        enable our servers to recognize your web browser, tell us how and when
        you visit and use our Services, analyze trends, learn about our user
        base and operate and improve our Services. Cookies are small pieces of
        data– usually text files – placed on your computer, tablet, phone or
        similar device when you use that device to access our Services. We may
        also supplement the information we collect from you with information
        received from third parties, including third parties that have placed
        their own Cookies on your device(s). Please note that because of our use
        of Cookies, the Services do not support “Do Not Track” requests sent
        from a browser at this time. <br /> <br />
        We use the following types of Cookies: <br /> <br />
        <List>
          <List.Item>
            Essential Cookies. Essential Cookies are required for providing you
            with features or services that you have requested. For example,
            certain Cookies enable you to log into secure areas of our Services.
            Disabling these Cookies may make certain features and services
            unavailable.
          </List.Item>
          <List.Item>
            Functional Cookies. Functional Cookies are used to record your
            choices and settings regarding our Services, maintain your
            preferences over time and recognize you when you return to our
            Services. These Cookies help us to personalize our content for you,
            greet you by name and remember your preferences (for example, your
            choice of language or region).
          </List.Item>
          <List.Item>
            Performance/Analytical Cookies. Performance/Analytical Cookies allow
            us to understand how visitors use our Services. They do this by
            collecting information about the number of visitors to the Services,
            what pages visitors view on our Services and how long visitors are
            viewing pages on the Services. Performance/Analytical Cookies also
            help us measure the performance of our advertising campaigns in
            order to help us improve our campaigns and the Services’ content for
            those who engage with our advertising.
          </List.Item>
        </List>
        You can decide whether or not to accept Cookies through your internet
        browser’s settings. Most browsers have an option for turning off the
        Cookie feature, which will prevent your browser from accepting new
        Cookies, as well as (depending on the sophistication of your browser
        software) allow you to decide on acceptance of each new Cookie in a
        variety of ways. You can also delete all Cookies that are already on
        your device. If you do this, however, you may have to manually adjust
        some preferences every time you visit our website and some of the
        Services and functionalities may not work. <br /> <br />
        To explore what Cookie settings are available to you, look in the
        “preferences” or “options” section of your browser’s menu. To find out
        more information about Cookies, including information about how to
        manage and delete Cookies, please visit{" "}
        <Anchor
          c="#000"
          to="http://www.allaboutcookies.org/"
          target="_blank"
          component={Link}
        >
          http://www.allaboutcookies.org/
        </Anchor>
      </Text>
      <br />
      <Title size={36} ta="start">
        Data security and retention
      </Title>
      <br />
      <Text ta="start">
        We're constantly trying to improve our Services, so we may need to
        change this Privacy Policy from time to time, but we will alert you to
        any such changes by placing a notice on the Competenz Lab website, by
        sending you an email and/or by some other means. Please note that if
        you've opted not to receive legal notice emails from us (or you haven't
        provided us with your email address), those legal notices will still
        govern your use of the Services, and you are still responsible for
        reading and understanding them. If you use the Services after any
        changes to the Privacy Policy have been posted, that means you agree to
        all of the changes. Use of information we collect is subject to the
        Privacy Policy in effect at the time such information is collected.{" "}
        <br /> <br />
        We retain Personal Data about you for as long as you have an open
        account with us or as otherwise necessary to provide you with our
        Services. In some cases we retain Personal Data for longer, if doing so
        is necessary to comply with our legal obligations, resolve disputes or
        collect fees owed, or is otherwise permitted or required by applicable
        law, rule or regulation. We may further retain information in an
        anonymous or aggregated form where that information would not identify
        you personally.
      </Text>
      <br />
      <Title size={36} ta="start">
        Changes to this privacy policy
      </Title>
      <br />
      <Text ta="start">
        We're constantly trying to improve our Services, so we may need to
        change this Privacy Policy from time to time, but we will alert you to
        any such changes by placing a notice on the Competenz Lab website, by
        sending you an email and/or by some other means. Please note that if
        you've opted not to receive legal notice emails from us (or you haven't
        provided us with your email address), those legal notices will still
        govern your use of the Services, and you are still responsible for
        reading and understanding them. If you use the Services after any
        changes to the Privacy Policy have been posted, that means you agree to
        all of the changes. Use of information we collect is subject to the
        Privacy Policy in effect at the time such information is collected.
      </Text>
      <br />
      <Title size={36} ta="start">
        Contact information
      </Title>
      <br />
      <Text ta="start">
        If you have any questions or comments about this Privacy Policy, the
        ways in which we collect and use your Personal Data or your choices and
        rights regarding such collection and use, please do not hesitate to
        contact us at: <br /> <br />
        <Text ta="start">
          Email:{" "}
          <Anchor
            c="#DE57CC"
            to="mailto:ahello@competenzlab.com"
            component={Link}
          >
            ahello@competenzlab.com
          </Anchor>
        </Text>
        <Text ta="start">
          Address:{" "}
          <Anchor c="#6F40E2">Bräuhasgasse 40/10, 1050 Vienna, Austria</Anchor>
        </Text>
      </Text>
    </TermsLayout>
  );
};
