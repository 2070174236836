import { Link } from "react-router-dom";
import { Anchor, Divider, Stack, Text, Title } from "@mantine/core";
import { SocialButtons } from "../../entities/components/SocialButtons.tsx";
import { SingInForm } from "../../features/sing-in/SingInForm";

export const SingInWiget = () => {
  return (
    <Stack align="stretch" justify="center" gap="24px">
      <Title size={36}>Sign In</Title>
      <Text c="dimmed" size="md">
        Log in with a social media account
      </Text>
      <SocialButtons />
      <Divider
        label={
          <Text c="dimmed" size="md">
            or
          </Text>
        }
        labelPosition="center"
      />
      <Text c="dimmed" size="md">
        Log in with email
      </Text>
      <SingInForm />
      <Text c="dimmed" size="md">
        Don’t have an account?{" "}
        <Anchor
          c="dimmed"
          to="/auth/sing-up"
          underline="always"
          component={Link}
        >
          Sign up
        </Anchor>
      </Text>
      <Anchor
        c="dimmed"
        to="/login/forgot-password"
        underline="always"
        component={Link}
      >
        Forgot your password?
      </Anchor>
    </Stack>
  );
};
