export const RatingIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5005 3.99939C12.788 3.99939 13.0505 4.16189 13.1755 4.42126L15.3193 8.83689L20.1068 9.54314C20.388 9.58376 20.6224 9.78064 20.7099 10.0525C20.7974 10.3244 20.7255 10.6181 20.5255 10.8181L17.0537 14.2619L17.8724 19.1244C17.9193 19.4056 17.8037 19.69 17.5693 19.8588C17.3349 20.0275 17.0287 20.0463 16.7787 19.9119L12.4974 17.6244L8.2224 19.9088C7.96928 20.0431 7.66303 20.0244 7.43178 19.8556C7.20053 19.6869 7.08178 19.4025 7.12865 19.1213L7.9474 14.2588L4.47553 10.8181C4.2724 10.6181 4.20365 10.3213 4.29115 10.0525C4.37865 9.78376 4.61303 9.58689 4.89428 9.54314L9.68178 8.83689L11.8255 4.42126C11.9537 4.16189 12.213 3.99939 12.5005 3.99939ZM12.5005 6.46814L10.8599 9.84939C10.7505 10.0713 10.5412 10.2275 10.2943 10.265L6.5974 10.8088L9.28178 13.4681C9.45365 13.64 9.5349 13.8838 9.49428 14.1244L8.8599 17.865L12.1474 16.1088C12.3693 15.99 12.6349 15.99 12.8537 16.1088L16.1412 17.865L15.5099 14.1275C15.4693 13.8869 15.5474 13.6431 15.7224 13.4713L18.4068 10.8119L14.7099 10.265C14.4662 10.2275 14.2537 10.0744 14.1443 9.84939L12.5005 6.46814Z"
        fill="#6F40E2"
      />
    </svg>
  );
};
