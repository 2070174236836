export const AuthGoogleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_48_4910)">
        <path
          d="M8.36061 0.789371C5.96264 1.62125 3.89463 3.20018 2.46035 5.29425C1.02606 7.38832 0.301099 9.88714 0.391944 12.4237C0.48279 14.9602 1.38466 17.4008 2.96507 19.3869C4.54549 21.373 6.72115 22.7999 9.17249 23.4581C11.1598 23.9709 13.242 23.9934 15.24 23.5237C17.05 23.1172 18.7233 22.2476 20.0962 21C21.5251 19.6619 22.5623 17.9596 23.0962 16.0762C23.6766 14.0281 23.7799 11.8742 23.3981 9.78H12.2381V14.4094H18.7012C18.5721 15.1477 18.2953 15.8524 17.8874 16.4813C17.4795 17.1102 16.949 17.6503 16.3275 18.0694C15.5383 18.5914 14.6486 18.9427 13.7156 19.1006C12.7799 19.2746 11.8201 19.2746 10.8844 19.1006C9.93597 18.9045 9.0388 18.5131 8.24999 17.9512C6.98277 17.0542 6.03126 15.7799 5.53124 14.31C5.02277 12.8126 5.02277 11.1893 5.53124 9.69187C5.88716 8.64228 6.47555 7.68663 7.25249 6.89625C8.1416 5.97515 9.26724 5.31674 10.5059 4.99326C11.7446 4.66979 13.0484 4.69374 14.2744 5.0625C15.2321 5.35648 16.1078 5.87013 16.8319 6.5625C17.5606 5.8375 18.2881 5.11062 19.0144 4.38187C19.3894 3.99 19.7981 3.61687 20.1675 3.21562C19.0623 2.18714 17.765 1.38685 16.35 0.860622C13.7732 -0.0750226 10.9537 -0.100167 8.36061 0.789371Z"
          fill="white"
        />
        <path
          d="M8.36064 0.789367C10.9535 -0.100776 13.773 -0.0762934 16.35 0.858742C17.7653 1.38855 19.062 2.19269 20.1656 3.22499C19.7906 3.62624 19.395 4.00124 19.0125 4.39124C18.285 5.11749 17.5581 5.84124 16.8319 6.56249C16.1079 5.87012 15.2321 5.35648 14.2744 5.06249C13.0488 4.69244 11.7451 4.66711 10.5061 4.98926C9.26706 5.31141 8.14073 5.96861 7.25064 6.88874C6.4737 7.67912 5.88532 8.63477 5.52939 9.68437L1.64252 6.67499C3.03378 3.91604 5.44267 1.80566 8.36064 0.789367Z"
          fill="#E33629"
        />
        <path
          d="M0.611279 9.65624C0.820194 8.62085 1.16704 7.61816 1.64253 6.67499L5.5294 9.69186C5.02093 11.1892 5.02093 12.8126 5.5294 14.31C4.2344 15.31 2.93878 16.315 1.64253 17.325C0.452186 14.9556 0.0891526 12.2559 0.611279 9.65624Z"
          fill="#F8BD00"
        />
        <path
          d="M12.2381 9.77814H23.3981C23.7799 11.8724 23.6766 14.0263 23.0963 16.0744C22.5623 17.9578 21.5252 19.66 20.0963 20.9981C18.8419 20.0194 17.5819 19.0481 16.3275 18.0694C16.9494 17.6499 17.4802 17.1092 17.8881 16.4796C18.296 15.8501 18.5726 15.1447 18.7013 14.4056H12.2381C12.2363 12.8644 12.2381 11.3213 12.2381 9.77814Z"
          fill="#587DBD"
        />
        <path
          d="M1.64062 17.325C2.93687 16.325 4.2325 15.32 5.5275 14.31C6.02851 15.7804 6.98138 17.0548 8.25 17.9512C9.04127 18.5105 9.94037 18.8987 10.89 19.0912C11.8257 19.2652 12.7855 19.2652 13.7213 19.0912C14.6542 18.9333 15.5439 18.5821 16.3331 18.06C17.5875 19.0387 18.8475 20.01 20.1019 20.9887C18.7292 22.237 17.0558 23.1073 15.2456 23.5144C13.2476 23.9841 11.1655 23.9616 9.17813 23.4487C7.60632 23.0291 6.13814 22.2892 4.86563 21.2756C3.51874 20.2063 2.41867 18.8587 1.64062 17.325Z"
          fill="#319F43"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_4910">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
