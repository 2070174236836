import { Link } from "react-router-dom";
import { Anchor, Paper, Space, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { TermsLayout } from "../../layouts/TermsLayout";

export const TermsOfService = () => {
  const matches = useMediaQuery("(max-width: 485px)");

  return (
    <TermsLayout>
      <Title size={matches ? 36 : 48}>Terms of Service</Title>
      <Text>Effective date: 6th of July, 2024</Text>
      <Space h={matches ? 40 : 80} />
      <Paper bg="#000" p="xl" c="#fff" radius={16} px={16} py={16}>
        <Text ta="start">
          Welcome to Competenz Lab. Please read on to learn the rules and
          restrictions that govern your use of our website(s), products,
          services and applications (the "Services"). If you have any questions,
          comments, or concerns regarding these terms or the Services, please
          contact us at:
        </Text>
        <br />
        <Text ta="start">
          Email:{" "}
          <Anchor
            c="#DE57CC"
            to="mailto:ahello@competenzlab.com"
            component={Link}
          >
            ahello@competenzlab.com
          </Anchor>
        </Text>
        <Text ta="start">
          Address:{" "}
          <Anchor c="#6F40E2">Bräuhasgasse 40/10, 1050 Vienna, Austria</Anchor>
        </Text>
      </Paper>
      <Space h={matches ? 40 : 80} />
      <Text ta="start">
        These Terms of Use (the "Terms") are a binding contract between you and
        COMPETENZ LAB ORBIT, INC. ("Competenz Lab," "we" and "us"). Your use of
        the Services in any way means that you agree to all of these Terms, and
        these Terms will remain in effect while you use the Services. These
        Terms include the provisions in this document as well as those in the
        Privacy Policy and any other relevant policies. Your use of or
        participation in certain Services may also be subject to additional
        policies, rules and/or conditions ("Additional Terms"), which are
        incorporated herein by reference, and you understand and agree that by
        using or participating in any such Services, you agree to also comply
        with these Additional Terms. <br /> <br />
        Please read these Terms carefully. They cover important information
        about Services provided to you and any charges, taxes, and fees we bill
        you. These Terms include information about future changes to these
        Terms, automatic renewals, limitations of liability, a class action
        waiver and resolution of disputes by arbitration instead of in court.
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO
        THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU
        MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER. <br /> <br />
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF
        DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE
        THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
        ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
        LAWSUIT OR CLASS-WIDE ARBITRATION.
      </Text>
      <br />
      <Title size={36} ta="start">
        Will these terms ever change?
      </Title>
      <br />
      <Text ta="start">
        We are constantly trying to improve our Services, so these Terms may
        need to change along with our Services. We reserve the right to change
        the Terms at any time, but if we do, we will place a notice on our site
        located at https://competenzlab.com send you an email, and/or notify you
        by some other means. <br /> <br />
        If you don’t agree with the new Terms, you are free to reject them;
        unfortunately, that means you will no longer be able to use the
        Services. If you use the Services in any way after a change to the Terms
        is effective, that means you agree to all of the changes. Except for
        changes by us as described here, no other amendment or modification of
        these Terms will be effective unless in writing and signed by both you
        and us.
      </Text>
      <br />
      <Title size={36} ta="start">
        What about my privacy?
      </Title>
      <br />
      <Text ta="start">
        Competenz Lab takes the privacy of its users very seriously. For the
        current Competenz Lab Privacy Policy, please click here.
      </Text>
      <br />
      <Title size={24} ta="start">
        Children's Online Privacy Protection Act
      </Title>
      <br />
      <Text ta="start">
        The Children's Online Privacy Protection Act ("COPPA") requires that
        online service providers obtain parental consent before they knowingly
        collect personally identifiable information online from children who are
        under thirteen (13). We do not knowingly collect or solicit personally
        identifiable information from children under thirteen (13); if you are a
        child under thirteen (13), please do not attempt to register for or
        otherwise use the Services or send us any personal information. If we
        learn we have collected personal information from a child under thirteen
        (13), we will delete that information as quickly as possible. If you
        believe that a child under thirteen (13) may have provided us personal
        information, please contact us at{" "}
        <Anchor c="#DE57CC" to="mailto:hello@competenzlab.com" component={Link}>
          hello@competenzlab.com
        </Anchor>
        .
      </Text>
      <br />
      <Title size={36} ta="start">
        What are the basics of using Competenz Lab?
      </Title>
      <br />
      <Text ta="start">
        To use the Services, you may be required to sign up for an account. You
        must provide accurate and complete information for your account and keep
        all such account information updated. To the extent the Services enable
        you to create additional user accounts in connection with your account,
        you may do so for your employees, contractors and agents. You shall not
        permit more than the authorized number of authorized users to use your
        account in any manner, and shall not, nor shall you permit any of your
        authorized users, to share any access credentials for the Services with
        any other individuals or entities. You are solely responsible for the
        activity that occurs on your account, including on authorized user
        accounts, whether or not authorized, and for keeping all access
        credentials associated with your account, including authorized user
        accounts, secure. You agree to promptly notify Competenz Lab of any
        breach of security or unauthorized use of your account. <br /> <br />
        Additionally, you may be able to access certain parts or features of the
        Services by using your account credentials from other services (each, a
        "Third Party Account"), such as those offered by Google and/or GitHub.
        By using the Services through a Third Party Account, you permit us to
        access certain information from such account for use by the Services.
        You are ultimately in control of how much information is accessible to
        us and may exercise such control by adjusting your privacy settings on
        your Third Party Account. <br /> <br />
        You represent and warrant that you are an individual of legal age to
        form a binding contract (or if not, you've received your parent's or
        guardian's permission to use the Services and have gotten your parent or
        guardian to agree to these Terms on your behalf). You will only use the
        Services for your own internal use, and not on behalf of or for the
        benefit of any third party, and only in a manner that complies with all
        laws that apply to you. If your use of the Services is prohibited by
        applicable laws, then you aren't authorized to use the Services. We
        can't and won't be responsible for your using the Services in a way that
        breaks the law.
      </Text>
      <br />
      <Title size={36} ta="start">
        What about messaging?
      </Title>
      <br />
      <Text ta="start">
        As part of the Services, you may receive communications through the
        Services, including messages that Competenz Lab sends you (for example,
        via email). When signing up for the Services, you will receive a welcome
        message and instructions on how to stop receiving messages.
      </Text>
      <br />
      <Title size={36} ta="start">
        What are my rights in the services?
      </Title>
      <br />
      <Text ta="start">
        The materials displayed or performed or available on or through the
        Services, including, but not limited to, text, graphics, data, articles,
        photos, images, illustrations, User Submissions (as defined below) and
        so forth (all of the foregoing, the "Content") are protected by
        copyright and/or other intellectual property laws. You promise to abide
        by all copyright notices, trademark rules, information, and restrictions
        contained in any Content you access through the Services, and you won't
        use, copy, reproduce, modify, translate, publish, broadcast, transmit,
        distribute, perform, upload, display, license, sell, commercialize or
        otherwise exploit for any purpose any Content not owned by you, (i)
        without the prior consent of the owner of Gthat Content or (ii) in a way
        that violates someone else's (including Competenz Lab's) rights. Subject
        to these Terms, we grant each user of the Services a worldwide,
        non-exclusive, non-sublicensable and non-transferable license to use
        (i.e., to download and display locally) Content solely for purposes of
        using the Services. Use, reproduction, modification, distribution or
        storage of any Content for any purpose other than using the Services is
        expressly prohibited without prior written permission from us. You
        understand that Competenz Lab owns the Services. You won't modify,
        publish, transmit, participate in the transfer or sale of, reproduce
        (except as expressly provided in this Section), create derivative works
        based on, or otherwise exploit any of the Services. The Services may
        allow you to copy or download certain Content, but please remember that
        even where these functionalities exist, all the restrictions in this
        section still apply.
      </Text>
      <br />
      <Title size={36} ta="start">
        What about anything i contribute to the services – do i have to grant
        any licenses to Competenz Lab or to other users?
      </Title>
      <br />
      <Title size={24} ta="start">
        User submissions
      </Title>
      <br />
      <Text ta="start">
        Anything you post, upload, share, store, or otherwise provide through
        the Services is your “User Submission”. Some User Submissions may be
        viewable by other users. You are solely responsible for all User
        Submissions you contribute to the Services. You represent that all User
        Submissions submitted by you are in compliance with all applicable laws,
        rules and regulations.
      </Text>
      <br />
      <Title size={24} ta="start">
        Licenses
      </Title>
      <br />
      <Text ta="start">
        In order to display your User Submissions on the Services, and to allow
        other users to enjoy them (where applicable), you grant us certain
        rights in those User Submissions (see below for more information).
        Please note that all of the following licenses are subject to our
        Privacy Policy to the extent they relate to User Submissions that are
        also your personally-identifiable information. <br /> <br />
        By submitting User Submissions through the Services, you hereby do and
        shall grant Linear a worldwide, non-exclusive, perpetual, royalty-free,
        fully paid, sublicensable and transferable license to use, edit, modify,
        truncate, aggregate, reproduce, distribute, prepare derivative works of,
        display, perform, and otherwise fully exploit the User Submissions to
        operate the Services, including to display User Submissions to other
        users within your organization. We may also aggregate or de-identify
        information from your User Submissions in order to perform research and
        development and otherwise improve our products and services, but we will
        not publicly distribute such information in a way that identifies you or
        your organization. <br /> <br />
        Certain features of the Services allow you to share information with
        others, including through your social networks or other Third Party
        Accounts. When Content is authorized for sharing, we will clearly
        identify the Content you are authorized to redistribute and the ways you
        may redistribute it, usually by providing a “share” button on or near
        the Content. If you share information from the Services with others
        through your Third Party Accounts, such as your social networks, you
        authorize Linear to share that information with the applicable Third
        Party Account provider. Please review the policies of any Third Party
        Account providers you share information with or through for additional
        information about how they may use your information. If you redistribute
        Content, you must be able to edit or delete any Content you
        redistribute, and you must edit or delete it promptly upon our request.{" "}
        <br /> <br />
        Finally, you understand and agree that Linear, in performing the
        required technical steps to provide the Services to our users (including
        you), may need to make changes to your User Submissions to conform and
        adapt those User Submissions to the technical requirements of connection
        networks, devices, services, or media, and the foregoing licenses
        include the rights to do so. <br /> <br />
        You represent and warrant that you have all rights to grant the
        foregoing licenses to us without infringement or violation of any third
        party rights, including without limitation, any privacy rights,
        publicity rights, copyrights, trademarks, contract rights, or any other
        intellectual property or proprietary rights.
      </Text>
      <br />
      <Title size={24} ta="start">
        Who is responsible for what i see and do on the services?
      </Title>
      <br />
      <Text ta="start">
        Any information or Content publicly posted or privately transmitted
        through the Services is the sole responsibility of the person from whom
        such Content originated, and you access all such information and Content
        at your own risk, and we aren't liable for any errors or omissions in
        that information or Content or for any damages or loss you might suffer
        in connection with it. We cannot control and have no duty to take any
        action regarding how you may interpret and use the Content or what
        actions you may take as a result of having been exposed to the Content,
        and you hereby release us from all liability for you having acquired or
        not acquired Content through the Services. We can't guarantee the
        identity of any users with whom you interact in using the Services and
        are not responsible for which users gain access to the Services. <br />{" "}
        <br />
        You are responsible for all Content you contribute, in any manner, to
        the Services, and you represent and warrant you have all rights
        necessary to do so, in the manner in which you contribute it. The
        Services may contain links or connections to websites or services of
        third parties that are not owned or controlled by Competenz Lab. When
        you access third-party websites or use third-party services, you accept
        that there are risks in doing so, and that Competenz Lab is not
        responsible for such risks. <br /> <br />
        Competenz Lab has no control over, and assumes no responsibility for,
        the content, accuracy, privacy policies, or practices of or opinions
        expressed in any third-party websites or by any third party that you
        interact with through the Services. In addition, Competenz Lab will not
        and cannot monitor, verify, censor or edit the content of any
        third-party site or service. We encourage you to be aware when you leave
        the Services and to read the terms and conditions and privacy policy of
        each third-party website or service that you visit or utilize. By using
        the Services, you release and hold us harmless from any and all
        liability arising from your use of any third-party website or service.{" "}
        <br /> <br />
        Your interactions with organizations and/or individuals found on or
        through the Services, including payment and delivery of goods or
        services, and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between you and such
        organizations and/or individuals. You should make whatever investigation
        you feel necessary or appropriate before proceeding with any online or
        offline transaction with any other third parties. You agree that
        Competenz Lab shall not be responsible or liable for any loss or damage
        of any sort incurred as the result of any such dealings. <br /> <br />
        If there is a dispute between participants on this site or Services, or
        between users and any third party, you agree that Competenz Lab is under
        no obligation to become involved. In the event that you have a dispute
        with one or more other users, you release Competenz Lab, its directors,
        officers, employees, agents, and successors from claims, demands, and
        damages of every kind or nature, known or unknown, suspected or
        unsuspected, disclosed or undisclosed, arising out of or in any way
        related to such disputes and/or our Services. You shall and hereby do
        waive California Civil Code Section 1542 or any similar law of any
        jurisdiction, which says in substance: "A general release does not
        extend to claims that the creditor or releasing party does not know or
        suspect to exist in his or her favor at the time of executing the
        release and that, if known by him or her, would have materially affected
        his or her settlement with the debtor or released party."
      </Text>
    </TermsLayout>
  );
};
