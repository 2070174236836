import { Card, Badge, Text, Image, Flex } from "@mantine/core";
import { StarIcon } from "../../shared/svg/StarIcon";

interface CourseCardProps {
  image: string;
  platform: string;
  title: string;
  instructor: string;
  rating: number | "Unrated";
  pdf?: boolean;
}

export const CourseCard: React.FC<CourseCardProps> = ({
  image,
  platform,
  title,
  instructor,
  rating,
  pdf,
}) => {
  return (
    <Card
      padding={pdf ? "32px" : "16px"}
      radius={pdf ? "48px" : "24px"}
      withBorder
      pos="relative"
      h="100%"
    >
      <Card.Section>
        <Badge
          color="#FFFFFF"
          pos="absolute"
          p={pdf ? 36 : 18}
          right={pdf ? 32 : 16}
          top={pdf ? 32 : 16}
        >
          <Text c="#000" tt="none" size={pdf ? "32px" : "16px"}>
            {platform}
          </Text>
        </Badge>
        <Image src={image} height={pdf ? 278 : 180} alt="Norway" />
      </Card.Section>
      <Text
        fw="bold"
        size={pdf ? "28px" : "20px"}
        lh={pdf ? "38px" : undefined}
        mt={16}
      >
        {title}
      </Text>
      <Text mt={16} size={pdf ? "28px" : "16px"}>
        {instructor}
      </Text>
      <Flex gap={8} mt={pdf ? 32 : 16} align="center" justify="center4">
        <StarIcon />
        <Text size={pdf ? "28px" : "16px"}>{rating}</Text>
      </Flex>
    </Card>
  );
};
