export const DiverseIndustryExposureSvg = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="128" height="128" rx="64" fill="#DE57CC" />
      <g clip-path="url(#clip0_724_3849)">
        <path
          d="M40.875 33.5625L54.775 32.0125C55.9625 31.875 57 32.8125 57 34V35.7625C57 36.8625 56.1 37.7625 55 37.7625H41.1125C39.95 37.7625 39 36.825 39 35.65C39 34.575 39.8125 33.675 40.875 33.55V33.5625ZM80 36C82.1217 36 84.1566 36.8429 85.6569 38.3431C87.1571 39.8434 88 41.8783 88 44C88 46.1217 87.1571 48.1566 85.6569 49.6569C84.1566 51.1571 82.1217 52 80 52C77.8783 52 75.8434 51.1571 74.3431 49.6569C72.8429 48.1566 72 46.1217 72 44C72 41.8783 72.8429 39.8434 74.3431 38.3431C75.8434 36.8429 77.8783 36 80 36ZM74 64V66H86V64C86 62.9 85.1 62 84 62H76C74.9 62 74 62.9 74 64ZM68 64C68 59.5875 71.5875 56 76 56H84C88.4125 56 92 59.5875 92 64V68C92 70.2125 90.2125 72 88 72H72C69.7875 72 68 70.2125 68 68V64ZM40.25 42H55.75C55.9125 42.6375 56 43.3125 56 44C56 48.4125 52.4125 52 48 52C43.5875 52 40 48.4125 40 44C40 43.3125 40.0875 42.6375 40.25 42ZM60 86C60 86.8625 59.625 87.6875 58.975 88.2625L50.975 95.2625C49.725 96.35 47.8375 96.225 46.7375 94.975C45.6375 93.725 45.775 91.8375 47.025 90.7375L49.0125 89H35C33.3375 89 32 87.6625 32 86C32 84.3375 33.3375 83 35 83H49.0125L47.025 81.2625C45.775 80.175 45.65 78.275 46.7375 77.025C47.825 75.775 49.725 75.65 50.975 76.7375L58.975 83.7375C59.625 84.3125 60 85.125 60 86ZM69.025 88.2625C68.375 87.6875 68 86.875 68 86C68 85.125 68.375 84.3125 69.025 83.7375L77.025 76.7375C78.275 75.65 80.1625 75.775 81.2625 77.025C82.3625 78.275 82.225 80.1625 80.975 81.2625L78.9875 83H93C94.6625 83 96 84.3375 96 86C96 87.6625 94.6625 89 93 89H78.9875L80.975 90.7375C82.225 91.825 82.35 93.725 81.2625 94.975C80.175 96.225 78.275 96.35 77.025 95.2625L69.025 88.2625ZM36 64C36 61.65 37.0125 59.5375 38.625 58.075L56.65 71.95C56.4375 71.9875 56.225 72 56 72H40C37.7875 72 36 70.2125 36 68V64ZM60 68C60 68.4625 59.925 68.9 59.775 69.3125L42.6375 56.1125C43.0875 56.0375 43.5375 56 44 56H52C56.4125 56 60 59.5875 60 64V68Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3849">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(32 32)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
