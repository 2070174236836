import React from "react";
import { useNavigate } from "react-router";
import { Button, Grid, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CourseCard } from "../../../entities/components/CourseCard";
import Course1 from "../../../shared/images/Course-1.png";
import Course2 from "../../../shared/images/Course-2.png";
import Course3 from "../../../shared/images/Course-3.png";
import "../Report.scss";

const courses = [
  {
    image: Course1,
    platform: "Coursera",
    title: "Google Data Analytics",
    instructor: "Google",
    rating: 4.9,
  },
  {
    image: Course2,
    platform: "edX",
    title: "MIT SA+P: Data Science in Real Estate",
    instructor: "Massachusetts Institute of Technology",
    rating: "Unrated",
  },
  {
    image: Course3,
    platform: "Udemy",
    title: "Data Analysis | SQL, Tableau, Power BI & Excel | Real Projects",
    instructor: "Graeme Gordon",
    rating: 4.4,
  },
];

export const EssentialSkillCourses: React.FC = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");
  const mobile = useMediaQuery("(max-width: 820px)");
  const navigate = useNavigate();

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Relevant Courses to Master Your Skills
      </Title>
      <Text mb={24} c="#6F40E2">
        The list of educational courses tailored to enhance your expertise and
        advance your professional capabilities in key areas.
      </Text>
      <Grid>
        {courses.map((course, index) => (
          <Grid.Col span={mobile ? 12 : laptop ? 6 : 4} key={index}>
            <CourseCard
              image={course.image}
              platform={course.platform}
              title={course.title}
              instructor={course.instructor}
              rating={course.rating as number | "Unrated"}
            />
          </Grid.Col>
        ))}
      </Grid>
      <Button variant="outline" mt={24} onClick={() => navigate("/courses")}>
        View all recommended courses
      </Button>
    </Paper>
  );
};
