import { Button, Group } from "@mantine/core";
import {
  AuthFacebookIcon,
  AuthGoogleIcon,
  AuthLinkedInIcon,
} from "../../shared/svg";

export const SocialButtons = () => {
  return (
    <Group justify="center" grow>
      <Button variant="default" size="md">
        <AuthLinkedInIcon />
      </Button>
      <Button variant="default" size="md">
        <AuthGoogleIcon />
      </Button>
      <Button variant="default" size="md">
        <AuthFacebookIcon />
      </Button>
    </Group>
  );
};
