import { Box, Flex, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const Statistics = () => {
  const mobile = useMediaQuery("(max-width: 820px)");

  return (
    <Paper withBorder radius="24px" p={32} h="100%">
      <Title size={36}>Statistics</Title>
      <Flex 
        mt={40}
        wrap="wrap" 
        gap={mobile ? 24 : 32} 
        justify="space-between"
      >
        <Flex align="center" gap={15} style={{ minWidth: mobile ? '100%' : 'calc(50% - 16px)' }}>
          <Title size={36}>🔥</Title>
          <Box>
            <Title size={24} mb={8}>
              12
            </Title>
            <Text>Day streak</Text>
          </Box>
        </Flex>
        
        <Flex align="center" gap={15} style={{ minWidth: mobile ? '100%' : 'calc(50% - 16px)' }}>
          <Title size={36}>🧑‍🏫</Title>
          <Box>
            <Title size={24} mb={8}>
              110
            </Title>
            <Text>Minutes coaching</Text>
          </Box>
        </Flex>
        
        <Flex align="center" gap={15} style={{ minWidth: mobile ? '100%' : 'calc(50% - 16px)' }}>
          <Title size={36}>🧑</Title>
          <Box>
            <Title size={24} mb={8}>
              75%
            </Title>
            <Text>Profile completed</Text>
          </Box>
        </Flex>
        
        <Flex align="center" gap={15} style={{ minWidth: mobile ? '100%' : 'calc(50% - 16px)' }}>
          <Title size={36}>🏆</Title>
          <Box>
            <Title size={24} mb={8}>
              2
            </Title>
            <Text>Goals achieved</Text>
          </Box>
        </Flex>
      </Flex>
    </Paper>
  );
};
