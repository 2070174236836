import { FC } from "react";
import { Box, Button, Group } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Logo } from "../../../shared/svg";
import "./Header.scss";
import { MenuIcon } from "../../../shared/svg/MenuIcon";

interface Props {
  setOPen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainHeader: FC<Props> = ({ setOPen }) => {
  const matches = useMediaQuery("(max-width: 670px)");

  return (
    <Group
      justify="space-between"
      align="center"
      className="header"
      px="24px"
      py="md"
      pl="xs"
    >
      <Logo width={190} height={40} />
      {matches ? (
        <Box className="pointer" onClick={() => setOPen(prevOpen => !prevOpen)}>
          <MenuIcon />
        </Box>
      ) : (
        <Group>
          <Button variant="outline">Upgrade</Button>
          <Button>Book coaching</Button>
        </Group>
      )}
    </Group>
  );
};
