import { Paper, Text, Title } from "@mantine/core";
import { EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Certifications = () => {
  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Certifications
      </Title>
      <Title size={24} mt={40}>
        Advanced Digital Marketing Certification
      </Title>
      <Text mt={8}>DigitalPro Academy</Text>
      <Text mt={8} c="#737373">
        2021
      </Text>
      <Title size={24} mt={40}>
        Strategic Marketing Leadership Certificate
      </Title>
      <Text mt={8}>MarketingMasters Institute</Text>
      <Text mt={8} c="#737373">
        2019
      </Text>
      <Title size={24} mt={40}>
        Social Media Marketing Expert
      </Title>
      <Text mt={8}>Online Marketing University</Text>
      <Text mt={8} c="#737373">
        2018
      </Text>
    </Paper>
  );
};
