import { FC, ReactNode, useState } from "react";
import { Box, Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { MainHeader } from "../entities/components/header/MainHeader";
import { MainSidebar } from "../entities/components/main-sidebar/MainSidebar";

interface Props {
  children: ReactNode;
}

export const MainLayout: FC<Props> = ({ children }) => {
  const [open, setOPen] = useState(false);
  const matches = useMediaQuery("(max-width: 670px)");

  return (
    <>
      <MainHeader setOPen={setOPen} />
      <Flex>
        {!matches && <MainSidebar />}
        <Box w={matches ? "100vw" : "calc(100vw - 240px)"}>{children}</Box>
        {matches && open && (
          <Box pos="relative" top={0}>
            <Box
              bg="rgba(128, 128, 128, 0.5)"
              w="100vw"
              h="100vh"
              pos="fixed"
              className="pointer"
              top={0}
              left={0}
              onClick={() => setOPen(false)}
            />
            <Box pos="fixed" top={0} left={0}>
              <MainSidebar />
            </Box>
          </Box>
        )}
      </Flex>
    </>
  );
};
