import { FC } from "react"

interface Props {
  width?: number
  height?: number
}

export const Logo: FC<Props> = ({ width = 284, height = 58 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 284 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.836 38.8516C26.4965 38.8516 24.2507 35.2392 24.2507 30.6679C24.2507 26.0966 26.4965 22.4423 30.836 22.4423C33.912 22.4423 35.8436 24.1689 36.3182 26.9767H39.5513C38.8451 22.5647 35.6484 19.7585 30.836 19.7585C24.7617 19.7585 20.9746 24.65 20.9746 30.6679C20.9746 36.6858 24.7617 41.5722 30.836 41.5722C35.9246 41.5722 39.1197 38.6857 39.5513 33.7121H36.3959C36.1197 36.9239 34.1501 38.8516 30.836 38.8516Z" fill="black"/>
      <path d="M51.3078 19.7585C45.2319 19.7585 41.4448 24.65 41.4448 30.6679C41.4448 36.6858 45.2319 41.5722 51.3078 41.5722C57.4268 41.5722 61.1262 36.6808 61.1262 30.6629C61.1262 24.645 57.4201 19.7585 51.3078 19.7585ZM51.3078 38.8516C46.9667 38.8516 44.7209 35.2392 44.7209 30.6679C44.7209 26.0966 46.9667 22.4423 51.3078 22.4423C55.649 22.4423 57.8534 26.0933 57.8534 30.6679C57.8534 35.2425 55.644 38.8516 51.3078 38.8516Z" fill="black"/>
      <path d="M85.2281 19.7954C83.9595 19.7927 82.7131 20.133 81.6175 20.7813C80.5219 21.4295 79.6166 22.3623 78.9951 23.4833H78.9141C78.0475 21.0778 76.0746 19.7954 73.5906 19.7954C71.0273 19.7954 68.8592 21.2421 67.5958 23.2469H67.5147V20.2832H64.3312V41.0174H67.523V28.0998C67.523 24.8512 70.2848 22.5244 72.7704 22.5244C74.8607 22.5244 76.2813 24.0499 76.2813 26.6146V41.0174H79.4747V28.0998C79.4747 24.8512 81.8412 22.5244 84.4856 22.5244C86.5363 22.5244 88.233 24.0499 88.233 26.6146V41.0174H91.4198V26.4554C91.4198 22.1624 88.8548 19.7954 85.2281 19.7954Z" fill="black"/>
      <path d="M105.459 19.7954C102.5 19.7954 100.449 21.0812 98.9096 23.2067H98.8319V20.2832H95.6368V47.6773H98.8319V38.5683H98.9096C100.606 40.776 102.579 41.6175 105.341 41.6175C110.861 41.6175 114.215 37.3681 114.215 30.7082C114.215 23.8035 110.625 19.7954 105.459 19.7954ZM105.103 38.8902C100.408 38.8902 98.6335 35.2375 98.6335 30.745C98.6335 26.2526 100.684 22.563 105.143 22.563C109.009 22.563 110.941 26.0832 110.941 30.745C110.941 35.4404 109.009 38.8902 105.103 38.8902Z" fill="black"/>
      <path d="M126.126 38.8516C122.024 38.8516 119.775 35.6415 119.539 31.4289H135.316C135.316 24.5712 132.043 19.7585 125.927 19.7585C120.131 19.7585 116.259 24.65 116.259 30.6763C116.259 36.7026 119.898 41.5722 126.126 41.5722C130.74 41.5722 133.935 39.0477 134.881 34.5921H131.767C131.055 37.405 129.083 38.8516 126.126 38.8516ZM125.927 22.3233C129.597 22.3233 131.689 24.8511 131.881 28.9832H119.616C120.091 25.2132 122.142 22.3233 125.927 22.3233Z" fill="black"/>
      <path d="M146.832 22.9268V20.2832H142.885V13.7457H139.693V20.2832H136.733V22.9335H139.693V37.1317C139.693 40.0619 141.585 41.1431 144.188 41.1431C145.05 41.1407 145.909 41.0468 146.751 40.8632V38.052H146.634C146.085 38.226 145.514 38.3203 144.939 38.332C143.636 38.332 142.888 37.8509 142.888 36.4042V22.9268H146.832Z" fill="black"/>
      <path d="M158.665 38.8516C154.564 38.8516 152.314 35.6415 152.078 31.429H167.856C167.856 24.5712 164.584 19.7585 158.468 19.7585C152.67 19.7585 148.804 24.65 148.804 30.6679C148.804 36.6858 152.442 41.5773 158.667 41.5773C163.281 41.5773 166.476 39.0528 167.422 34.5972H164.306C163.595 37.405 161.622 38.8516 158.665 38.8516ZM158.466 22.3233C162.134 22.3233 164.225 24.8511 164.42 28.9832H152.156C152.63 25.2132 154.681 22.3233 158.466 22.3233Z" fill="black"/>
      <path d="M180.711 19.7954C177.754 19.7954 175.702 21.0409 174.243 23.0056H174.162V20.2832H170.969V41.0174H174.162V28.2591C174.162 24.8512 176.529 22.5245 179.765 22.5245C182.644 22.5245 184.379 24.0499 184.379 26.737V41.0174H187.574V26.4554C187.574 22.1624 184.971 19.7954 180.711 19.7954Z" fill="black"/>
      <path d="M195.067 38.052V37.9297L206.783 22.206V20.2832H191.636V23.1329H202.324V23.2553L190.966 38.4577V41.0258H207.216V38.052H195.067Z" fill="black"/>
      <path d="M216.76 12.3342V41.0174H219.95V12.3342H216.76Z" fill="black"/>
      <path d="M254.348 19.7954C251.391 19.7954 249.337 21.1197 247.798 23.2469H247.722V12.3342H244.525V41.0174H247.722V38.2884H247.798C249.451 40.6151 251.469 41.6175 254.229 41.6175C259.753 41.6175 263.025 37.3681 263.025 30.7082C263.025 23.8035 259.514 19.7954 254.348 19.7954ZM253.991 38.8902C249.653 38.8902 247.525 35.3984 247.525 30.745C247.525 26.2526 249.575 22.563 254.031 22.563C257.897 22.563 259.753 26.0832 259.753 30.745C259.753 35.4404 257.897 38.8902 253.991 38.8902Z" fill="black"/>
      <path d="M241.479 23.5017V33.5126H238.669L238.601 24.6885L226.424 41.014H222.715L231.222 29.6303L235.995 23.2452V23.1246H227.572V20.2765H238.31L238.303 20.2832L241.479 23.5017Z" fill="black"/>
    </svg>
  )
}