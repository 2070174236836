export const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9216 16.5977L3.02317 19.6484L2.53879 21.2969C2.44114 21.6289 2.53098 21.9844 2.77317 22.2266C3.01535 22.4688 3.37082 22.5586 3.69895 22.4648L5.35129 21.9766L8.40207 21.0781C8.88645 20.9375 9.32785 20.6914 9.70676 20.3594C9.76145 20.3125 9.81223 20.2617 9.86301 20.2109L21.7458 8.33203C22.6013 7.47656 22.7068 6.16016 22.0661 5.1875C21.9763 5.05078 21.8669 4.91797 21.7458 4.79688L20.2068 3.25391C19.2302 2.27734 17.6482 2.27734 16.6716 3.25391L4.78879 15.1367C4.38254 15.543 4.08567 16.0469 3.9216 16.5977ZM5.71848 17.125C5.78489 16.9062 5.89426 16.7031 6.0466 16.5313C6.07004 16.5078 6.08957 16.4844 6.11301 16.4609L15.0388 7.53906L17.4607 9.96094L8.53879 18.8867C8.3552 19.0703 8.12473 19.207 7.87473 19.2812L6.96067 19.5508L4.82004 20.1797L5.44895 18.0391L5.71848 17.125Z"
        fill="black"
      />
    </svg>
  );
};
