import { FC } from "react";
import { Stack, Text, Title } from "@mantine/core";
import { NewPasswordForm } from "../../features/new-password/NewPasswordForm";

interface Props {
  onContinue: () => void;
}

export const NewPasswordWiget: FC<Props> = ({ onContinue }) => {
  return (
    <Stack align="stretch" justify="center" gap="24px">
      <Title size={36}>New Password</Title>
      <Text c="dimmed" size="md">
        Enter your new password
      </Text>
      <NewPasswordForm onContinue={onContinue} />
    </Stack>
  );
};
