export const ShortTermEntrepreneurshipSvg = () => {
  return (
    <svg
      width="129"
      height="128"
      viewBox="0 0 129 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="128" height="128" rx="64" fill="#A64DD7" />
      <path
        d="M38.5 43C38.5 42.45 38.95 42 39.5 42H45.5C46.05 42 46.5 42.45 46.5 43V58.8375C46.5 59.9125 47.075 60.9 48 61.4375C48.925 61.975 50.075 61.9625 51 61.425L68.5 51.225V60.0875C68.5 61.1875 69.1 62.1875 70.0625 62.725C71.025 63.2625 72.2 63.2125 73.125 62.625L90.5125 51.5L90.5 70V72V83C90.5 84.6625 89.1625 86 87.5 86H41.5C39.8375 86 38.5 84.6625 38.5 83V76V70V43ZM39.5 36C35.6375 36 32.5 39.1375 32.5 43V70V76V83C32.5 87.975 36.525 92 41.5 92H87.5C92.475 92 96.5 87.975 96.5 83V72V70V51.4875C96.5 46.75 91.2625 43.875 87.2625 46.4375L74.5 54.6V51.225C74.5 46.6 69.475 43.7125 65.475 46.0375L52.5 53.6125V43C52.5 39.1375 49.3625 36 45.5 36H39.5ZM46.5 69C45.4 69 44.5 69.9 44.5 71V77C44.5 78.1 45.4 79 46.5 79H52.5C53.6 79 54.5 78.1 54.5 77V71C54.5 69.9 53.6 69 52.5 69H46.5ZM59.5 71V77C59.5 78.1 60.4 79 61.5 79H67.5C68.6 79 69.5 78.1 69.5 77V71C69.5 69.9 68.6 69 67.5 69H61.5C60.4 69 59.5 69.9 59.5 71ZM76.5 69C75.4 69 74.5 69.9 74.5 71V77C74.5 78.1 75.4 79 76.5 79H82.5C83.6 79 84.5 78.1 84.5 77V71C84.5 69.9 83.6 69 82.5 69H76.5Z"
        fill="white"
      />
    </svg>
  );
};
