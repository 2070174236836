import {
  Box,
  Center,
  Flex,
  Grid,
  Group,
  Input,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { ToLeftIcon, ToRightIcon } from "../../shared/svg";
import "./Calendar.scss";
import { useSetState } from "@mantine/hooks";
import { useState } from "react";
import dayjs from "dayjs";

const days = Array.from({ length: 31 }, (_, i) => i + 1);

const goals = [
  {
    name: "Data Analysis & Visualization Tools",
    timestamps: [
      {
        dayFrom: 2,
        dayTo: 12,
        goalText: "Google Data Analytics Professional Certificate",
      },
    ],
  },
  {
    name: "Data Analysis & Visualization Tools",
  },
  {
    name: "Data Analysis & Visualization Tools",
  },
  {
    name: "Data Analysis & Visualization Tools",
    timestamps: [
      {
        dayFrom: 6,
        dayTo: 15,
        goalText: "Coaching sessions",
      },
    ],
  },
];

export const Calendar = () => {
  return (
    <Box>
      <Group gap={24} align="center">
        <ToLeftIcon />
        <Title size={24}>July</Title>
        <ToRightIcon />
      </Group>
      <Paper className="calendar" withBorder radius={16} mt={32}>
        <Box w="max-content">
          <Text px={11} py={16} c="#737373" h={58} className="goal-item">
            Skill
          </Text>
          {goals.map((goal) => (
            <Text
              px={11}
              py={16}
              className="goal-item"
              miw="max-content"
              h={58}
            >
              {goal.name}
            </Text>
          ))}
          <Box px={11}>
            <Flex
              className="goal-input"
              miw="max-content"
              align="center"
              gap={8}
            >
              <label htmlFor="goal" className="goal-icon">
                +
              </label>
              <Input id="goal" variant="unstyled" placeholder="Add a goal" />
            </Flex>
          </Box>
        </Box>
        <Box className="days">
          <Flex w="max-content">
            {days.map((date) => {
              const day = dayjs().date(date).format("ddd");
              const number = dayjs().date(date).format("D");
              return (
                <Flex
                  w={64}
                  h={58}
                  align="center"
                  justify="center"
                  className="day"
                  direction="column"
                  c="#737373"
                >
                  <Text>{day}</Text>
                  <Text>{number}</Text>
                </Flex>
              );
            })}
          </Flex>
          {goals.map((goal) => (
            <Box
              w="max-content"
              style={{ gridTemplateColumns: `repeat(${days.length}, 1fr)` }}
              className="row"
            >
              {days.map((date) => {
                const currentTimestamp = goal.timestamps?.find(
                  (timestamp) =>
                    date >= timestamp.dayFrom && date <= timestamp.dayTo
                );

                if (!currentTimestamp) {
                  return (
                    <Center w={64} h={58} className="day">
                      <Text c="#00000034">+</Text>
                    </Center>
                  );
                }

                const dayRange =
                  currentTimestamp.dayTo - currentTimestamp.dayFrom + 1;

                if (date === currentTimestamp.dayFrom) {
                  return (
                    <Flex
                      w={64 * dayRange}
                      h={58}
                      p={12}
                      className="day"
                      align="center"
                      bg="#6F40E21A"
                      style={{
                        gridColumn: `span ${dayRange}`,
                      }}
                    >
                      <Text>{currentTimestamp.goalText}</Text>
                    </Flex>
                  );
                }

                if (
                  date > currentTimestamp.dayFrom &&
                  date <= currentTimestamp.dayTo
                ) {
                  return null;
                }

                throw new Error("Should not be here");
              })}
            </Box>
          ))}
          <Flex w="max-content">
            {days.map(() => (
              <Box w={64} h={58} className="day" />
            ))}
          </Flex>
        </Box>
      </Paper>
    </Box>
  );
};
