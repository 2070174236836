import React from "react";
import { Avatar, Box } from "@mantine/core";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface AvatarWithProgressProps {
  imageUrl: string;
  progress: number;
  size?: number;
}

export const ProgressAvatar: React.FC<AvatarWithProgressProps> = ({
  imageUrl,
  progress,
  size = 125,
}) => {
  const progressSize = size + 25;
  return (
    <Box pos="relative" w={progressSize} h={progressSize}>
      <CircularProgressbarWithChildren
        value={progress}
        strokeWidth={4}
        styles={buildStyles({
          pathColor: "#6F40E2",
          trailColor: "#D6D6D6",
        })}
      >
        <Box m={15}>
          <Avatar src={imageUrl} radius="xl" size={size} />
        </Box>
      </CircularProgressbarWithChildren>
    </Box>
  );
};
