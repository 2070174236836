import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Stack, Text, Title } from "@mantine/core";
import { LinkSentIcon } from "../../shared/svg";

export const LinkSentPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate('/login/new-password'), 5000)
  }, [])

  return (
    <Stack align="stretch" justify="center" gap="24px">
      <center>
        <LinkSentIcon />
      </center>
      <Title size={36}>Link Sent</Title>
      <Text c="dimmed" size="md">
        Please check your email. If you can’t find anything from us, check the
        spam folderd
      </Text>
    </Stack>
  );
};
