export const CheckIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4008 1.76908L5.37137 9.65761L0.599121 4.96908L2.00076 3.54241L5.37137 6.85388L11.9991 0.342407L13.4008 1.76908Z"
        fill="#D6D6D6"
      />
    </svg>
  );
};
