import { useNavigate } from "react-router";
import { Button, Stack, Text, Title } from "@mantine/core";
import { LinkSentIcon } from "../../shared/svg";

export const PasswordUpdatedPage = () => {
  const navigate = useNavigate();

  return (
    <Stack align="stretch" justify="center" gap="24px">
      <center>
        <LinkSentIcon />
      </center>
      <Title size={36}>Password Updated</Title>
      <Text c="dimmed" size="md">
        You can now log in using your new password
      </Text>
      <Button fullWidth onClick={() => navigate("/login/sing-in")}>
        Back to Log In
      </Button>
    </Stack>
  );
};
