import { createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: 'Manrope, sans-serif',
  headings: { fontFamily: 'Manrope, sans-serif' },
  colors: {
    purple: ['#F3E8FF', '#E1CFFF', '#CEB7FF', '#BA9EFF', '#A685FF', '#926DFF', '#6F40E2', '#A082EA', '#4A29FF', '#2109B2']
  },
  primaryColor: 'purple',
  components: {
    Button: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
    },
    Input: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
    },
    TextInput: {
      defaultProps: {
        size: 'md',
        radius: 8,
      },
    },
    Radio: {
      styles: {
        body: {
          alignItems: 'center',
        },
      },
    },
    Checkbox: {
      styles: {
        inner: {
          marginTop: '2px'
        },
      },
    },
  },
});
