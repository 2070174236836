import { Box, Flex, Group, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ToTopArrowIcon } from "../../shared/svg/ToTopArrowIcon";
import "./Dashboard.scss";

export const CurrentGoals = () => {
  const mobile = useMediaQuery("(max-width: 820px)");

  return (
    <Paper withBorder radius="24px" p={32} h="100%">
      <Title size={36} mb={40}>
        Current goals
      </Title>
      {mobile && (
        <Paper mb={40} p={16} radius={16} bg="#A64DD7" c="#fff">
          <Text fw="bold">Average Duration Target (1-5 Years)</Text>
          <Group justify="space-between" align="end" mt={8}>
            <Box>
              <Text mb={4}>Income:</Text>
              <Text fw="bold">300 000 ₴</Text>
            </Box>
            <Box>
              <Text size="20px" fw="bold">x3 <ToTopArrowIcon /></Text>
            </Box>
          </Group>
        </Paper>
      )}
      <Flex wrap="wrap" gap={24}>
        <Box className="goal">
          Learn Data Analysis and Statistical Techniques
        </Box>
        <Box className="goal">Learn Data Visualization</Box>
        <Box className="goal">Learn Python</Box>
        <Box className="goal">Learn Data Visualization</Box>
      </Flex>
    </Paper>
  );
};
