import { Anchor, Text, Group, Space, Box, Flex, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  FooterFacebookIcon,
  FooterInstagramIcon,
  FooterLinkedInIcon,
  FooterLogo,
} from "../../shared/svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  const matches = useMediaQuery("(max-width: 970px)");
  const logoMatches = useMediaQuery("(max-width: 1125px)");

  return (
    <Stack bg="#000" c="#fff" p="lg" align={matches ? "center" : "start"}>
      <Flex
        justify="space-between"
        gap={40}
        wrap="wrap"
        align={matches ? "center" : "start"}
        direction={matches ? "column" : undefined}
      >
        <FooterLogo mobile={logoMatches} />
        <Flex
          align="center"
          gap={matches ? "24px" : "40px"}
          direction={matches ? "column" : undefined}
        >
          <Anchor c="#fff" to="/" component={Link}>
            Home
          </Anchor>
          <Anchor c="#fff" to="/for-coaches" component={Link}>
            For coaches
          </Anchor>
          <Anchor c="#fff" to="/terms-of-service" component={Link}>
            Terms of Service
          </Anchor>
          <Anchor c="#fff" to="/privacy-policy" component={Link}>
            Privacy Policy
          </Anchor>
        </Flex>
        <Stack gap={matches ? "24px" : "12px"}>
          <Text>Connect with us</Text>
          <Group>
            <FooterFacebookIcon />
            <FooterInstagramIcon />
            <FooterLinkedInIcon />
          </Group>
        </Stack>
      </Flex>
      <Stack mt={matches ? 16 : 0} align={matches ? "center" : "start"} gap={0}>
        <Text size={matches ? "10px" : "16px"}>© 2021 CompetenzLab.</Text>
        <Text size={matches ? "10px" : "16px"}>All rights reserved.</Text>
        <Text size={matches ? "10px" : "16px"}>Bräuhasgasse 40/10, 1050 Vienna, Austria</Text>
      </Stack>
    </Stack>
  );
};
