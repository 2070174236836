import { Flex, Paper, Text, Title } from "@mantine/core";
import {
  ScatterChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import { useMediaQuery } from "@mantine/hooks";

const data = [
  {
    x: 20,
    y: 15,
    r: 41,
    label: "Content Strategy Manager",
    value: "41k",
    color: "#DE57CC",
  },
  {
    x: 24,
    y: 30,
    r: 52,
    label: "Lead Generation Manager",
    value: "85k",
    color: "#A64DD7",
  },
  {
    x: 40,
    y: 44,
    r: 65,
    label: "Digital Marketing Manager",
    value: "90k",
    color: "#6F40E2",
  },
  {
    x: 55,
    y: 65,
    r: 67,
    label: "Marketing Automation Manager",
    value: "95k",
    color: "#05D8D2",
  },
  {
    x: 70,
    y: 45,
    r: 50,
    label: "Senior Email Marketing Specialist",
    value: "80k",
    color: "#3A8BD9",
  },
];


export const OptimalPositions = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  const CustomBubble = (props: any) => {
    const { cx, cy, payload } = props;
    const radius = payload.r;

    return (
      <foreignObject
        x={cx - radius}
        y={cy - radius}
        width={radius * (matches ? 1.5 : 2)}
        height={radius * (matches ? 1.5 : 2)}
        color="#fff"
        style={{ borderRadius: "50%", fontSize: "10px" }}
      >
        <Paper radius="50%" bg={payload.color}>
          <Flex
            w={radius * (matches ? 1.5 : 2)}
            h={radius * (matches ? 1.5 : 2)}
            align="center"
            justify="center"
            direction="column"
            gap={10}
          >
            <Text size="10px" c="#fff" ta="center" w={radius * 1.5}>
              {payload.label}
            </Text>
            <Text c="#fff" ta="center" fw="bold" size="16px">
              {payload.value}
            </Text>
          </Flex>
        </Paper>
      </foreignObject>
    );
  };

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Optimal Positions in Data Analysis & Science
      </Title>
      <Text mb={24} c="dimmed">
        Tailored recommendations for positions where you can fully leverage your
        strengths, make significant contributions, and achieve professional
        fulfillment.
      </Text>
      <Text fw="bold" mb={10}>
        Job Stability %
      </Text>
      <ResponsiveContainer height={matches ? 538 : 680} width="100%">
        <ScatterChart data={data} margin={{ left: -25 }}>
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="X-axis" domain={[0, 100]} />
          <YAxis type="number" dataKey="y" name="Y-axis" domain={[0, 100]} />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Scatter name="Positions" data={data} shape={CustomBubble} />
        </ScatterChart>
      </ResponsiveContainer>
      <Text fw="bold" ta="right">
        Match %
      </Text>
      <Text fw="bold" size="20px" mt={24}>
        Senior Email Marketing Specialist
      </Text>
      <Text>
        <b>Average Salary</b>: $80,000
      </Text>
      <Text>
        With your extensive experience in email marketing and strong analytical
        skills, this role suits you well. It involves strategic planning and
        creative content development, areas where AI is less likely to replace
        your insight and creativity.
      </Text>
      <Text fw="bold" size="20px" mt={24}>
        Marketing Automation Manager
      </Text>
      <Text>
        <b>Average Salary</b>: $95,000
      </Text>
      <Text>
        Your proficiency with marketing automation tools and CRM systems makes
        you a strong candidate for this role. The job requires overseeing
        complex campaigns and making strategic decisions, ensuring high job
        stability and substantial career growth opportunities.
      </Text>
      <Text fw="bold" size="20px" mt={24}>
        Digital Marketing Manager
      </Text>
      <Text>
        <b>Average Salary</b>: $90,000
      </Text>
      <Text>
        This role requires a blend of creative and analytical skills, including
        content management and SEO/SEM knowledge. While AI can assist with
        certain tasks, your creativity and strategic planning are essential,
        making this a suitable position for you.
      </Text>
      <Text fw="bold" size="20px" mt={24}>
        Lead Generation Manager
      </Text>
      <Text>
        <b>Average Salary</b>: $85,000
      </Text>
      <Text>
        With your strong CRM proficiency and data analysis skills, you are
        well-equipped for this role. It involves relationship building and
        strategic planning, areas where AI tools provide support but cannot
        fully replace your expertise.
      </Text>
      <Text fw="bold" size="20px" mt={24}>
        Content Strategy Manager
      </Text>
      <Text>
        <b>Average Salary</b>: $82,000
      </Text>
      <Text>
        Your background in content management and marketing makes you a good fit
        for this role. It requires creativity and strategic oversight in content
        creation, areas where your input remains critical despite advances in
        AI.
      </Text>
      <LikeResponse />
    </Paper>
  );
};
