import {
  Box,
  ColorSwatch,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PieChart, Pie, Cell, PieLabel } from "recharts";
import { useMediaQuery } from "@mantine/hooks";
import {
  DocumentControlIcon,
  ManagingContentIcon,
  MarketingIcon,
  ProjectManagmentIcon,
} from "../../../shared/svg";
import { LikeResponse } from "../../../entities/components/LikeResponse";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 200 },
  { name: "Group C", value: 200 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#05D8D2", "#A64DD7", "#6F40E2", "#3A8BD9"];
const RADIAN = Math.PI / 180;

export const CareerInNumbers = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  const renderCustomizedLabel: PieLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const renderIcon = () => {
      switch (index) {
        case 0:
          return <MarketingIcon />;
        case 1:
          return <DocumentControlIcon />;
        case 2:
          return <ProjectManagmentIcon />;
        case 3:
          return <ManagingContentIcon />;
      }
    };

    return (
      <g className="career-icons">
        <foreignObject
          x={x - (matches ? 22 : 38)}
          y={y - (matches ? 20 : 33.5)}
          width="76"
          height="67"
        >
          {renderIcon()}
        </foreignObject>
      </g>
    );
  };

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Career in Numbers
      </Title>
      <Text mb={24} c="dimmed">
        A numerical perspective on your career’s impact, highlighting your
        experience and achievements.
      </Text>
      <Paper radius="15px" p={matches ? 0 : 36} mb={40} withBorder={!matches}>
        <Flex
          justify="space-between"
          align="center"
          gap={32}
          direction={matches ? "column-reverse" : undefined}
        >
          <Group className="experience-text">
            <Stack gap={16}>
              <Text fw="bold" mb={8} size={matches ? "16px" : "24px"}>
                8+ years in the workforce:
              </Text>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#05D8D2" size={16} />
                <Group gap={4}>
                  <Text size={matches ? "16px" : "24px"}>
                    Over <span className="highlight marketing">4 years</span> in
                    marketing
                  </Text>
                </Group>
              </Flex>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#3A8BD9" size={16} />
                <Group gap={4}>
                  <Text size={matches ? "16px" : "24px"}>
                    Over <span className="highlight content">2 years</span>{" "}
                    managing content
                  </Text>
                </Group>
              </Flex>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#6F40E2" size={16} />
                <Group gap={4}>
                  <Text size={matches ? "16px" : "24px"}>
                    Almost <span className="highlight project">2 years</span> of
                    project management
                  </Text>
                </Group>
              </Flex>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#A64DD7" size={16} />
                <Group gap={4}>
                  <Text size={matches ? "16px" : "24px"}>
                    Almost <span className="highlight document">2 years</span>{" "}
                    in document control
                  </Text>
                </Group>
              </Flex>
            </Stack>
          </Group>
          <Box>
            <PieChart width={matches ? 279 : 416} height={matches ? 279 : 416}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={matches ? 140 : 208}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </Box>
        </Flex>
      </Paper>
      <Paper radius="15px" p={matches ? 0 : 36} withBorder={!matches}>
        <Flex
          justify="space-between"
          align={matches ? "center" : "start"}
          ta="center"
          gap={16}
          direction={matches ? "column" : undefined}
        >
          <Text maw={216} size={matches ? "16px" : "24px"} fw={matches ? "bold" : undefined}>
            <Title size={matches ? 36 : 64} c="#05D8D2">
              7
            </Title>
            different roles within marketing and content domain in the last 3
            years
          </Text>
          <Text maw={216} size={matches ? "16px" : "24px"} fw={matches ? "bold" : undefined}>
            <Title size={matches ? 36 : 64} c="#3A8BD9">
              1
            </Title>
            year of education overlap with work experience
          </Text>
          <Text maw={216} size={matches ? "16px" : "24px"} fw={matches ? "bold" : undefined}>
            <Title size={matches ? 36 : 64} c="#6F40E2">
              4
            </Title>
            months in a short-term entrepreneurial venture
          </Text>
          <Text maw={216} size={matches ? "16px" : "24px"} fw={matches ? "bold" : undefined}>
            <Title size={matches ? 36 : 64} c="#A64DD7">
              4
            </Title>
            months in an internship
          </Text>
        </Flex>
      </Paper>
      <LikeResponse />
    </Paper>
  );
};
