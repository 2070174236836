import { Paper, Table, Text, Title } from "@mantine/core";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import "../Report.scss";

const elements = [
  {
    title: {
      text: "Email Marketing Specialist",
      color: "#05D8D2",
    },
    percentage: 90,
    skills: "Email Marketing, Cold Email, Content Management",
    master: "Advanced Data Analysis",
  },
  {
    title: {
      text: "Digital Marketing Manager",
      color: "#3A8BD9",
    },
    percentage: 85,
    skills: "Content and Marketing, Retention Marketing",
    master: "SEO, SEM, Advanced Analytics",
  },
  {
    title: {
      text: "Technology Outreach Coordinator",
      color: "#6F40E2",
    },
    percentage: 80,
    skills: "Apollo, Snov.io, Cold Email, Technology Industry Knowhow",
    master: "Public Speaking, Event Planning",
  },
  {
    title: {
      text: "Communications Officer",
      color: "#A64DD7",
    },
    percentage: 75,
    skills: "Ukrainian and Polish (Limited Working), Content Creation",
    master: "Strategic Communication Planning",
  },
  {
    title: {
      text: "Public Relations Specialist",
      color: "#DE57CC",
    },
    percentage: 70,
    skills: "Marketing Management, Retention Marketing",
    master: "Media Relations, Crisis Management",
  },
];

const rows = elements.map((element) => (
  <Table.Tr key={element.title.text}>
    <Table.Td p={16}>
      <Paper
        bg={element.title.color}
        px={16}
        py={8}
        radius={16}
        c="#fff"
        w="max-content"
      >
        <Text fw="bold">{element.title.text}</Text>
      </Paper>
    </Table.Td>
    <Table.Td p={16}>
      <Text>{element.percentage}%</Text>
    </Table.Td>
    <Table.Td p={16}>
      <Text>{element.skills}</Text>
    </Table.Td>
    <Table.Td p={16}>
      <Text>{element.master}</Text>
    </Table.Td>
  </Table.Tr>
));

export const SkillsAssessment = () => {
  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Skills Assessment
      </Title>
      <Text mb={24} c="dimmed">
        An insightful evaluation of your current skills and areas for
        improvement, empowering you to strategically focus your career
        development efforts.
      </Text>
      <Paper radius="16px" withBorder className="x-auto">
        <Table highlightOnHover withColumnBorders miw={1056}>
          <Table.Thead>
            <Table.Tr p={16}>
              <Table.Th>
                <Text fw="bold">Job Title</Text>
              </Table.Th>
              <Table.Th>
                <Text fw="bold">Fit percentage</Text>
              </Table.Th>
              <Table.Th>
                <Text fw="bold">Skills you have</Text>
              </Table.Th>
              <Table.Th>
                <Text fw="bold">Skills to master</Text>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Paper>
      <LikeResponse />
    </Paper>
  );
};
