import { Paper, Table, Text, Title } from "@mantine/core";
import { PDFPage } from "./PDFPage";

const elements = [
  {
    title: {
      text: "Email Marketing Specialist",
      color: "#05D8D2",
    },
    percentage: 90,
    skills: "Email Marketing, Cold Email, Content Management",
    master: "Advanced Data Analysis",
  },
  {
    title: {
      text: "Digital Marketing Manager",
      color: "#3A8BD9",
    },
    percentage: 85,
    skills: "Content and Marketing, Retention Marketing",
    master: "SEO, SEM, Advanced Analytics",
  },
  {
    title: {
      text: "Technology Outreach Coordinator",
      color: "#6F40E2",
    },
    percentage: 80,
    skills: "Apollo, Snov.io, Cold Email, Technology Industry Knowhow",
    master: "Public Speaking, Event Planning",
  },
  {
    title: {
      text: "Communications Officer",
      color: "#A64DD7",
    },
    percentage: 75,
    skills: "Ukrainian and Polish (Limited Working), Content Creation",
    master: "Strategic Communication Planning",
  },
  {
    title: {
      text: "Public Relations Specialist",
      color: "#DE57CC",
    },
    percentage: 70,
    skills: "Marketing Management, Retention Marketing",
    master: "Media Relations, Crisis Management",
  },
];

const rows = elements.map((element) => (
  <Table.Tr key={element.title.text}>
    <Table.Td px={32} py={16}>
      <Paper bg={element.title.color} px={32} py={16} radius={16} c="#fff">
        <Text fw="bold" size="28px" lh="42px">
          {element.title.text}
        </Text>
      </Paper>
    </Table.Td>
    <Table.Td p={16}>
      <Text size="28px" lh="42px">
        {element.percentage}%
      </Text>
    </Table.Td>
    <Table.Td p={16} px={32} py={16}>
      <Text size="28px" lh="42px">
        {element.skills}
      </Text>
    </Table.Td>
    <Table.Td p={16}>
      <Text size="28px" lh="42px">
        {element.master}
      </Text>
    </Table.Td>
  </Table.Tr>
));

export const SkillsAssessmentPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={16}>
        Skills to Master for New Role in Data Analysis & Science
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        Discover key skills to master for climbing the career ladder in your
        field, focusing on strategic, technical, and interpersonal competencies
        essential for higher-level positions.
      </Text>
      <Paper radius="16px" withBorder>
        <Table highlightOnHover withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th px={32} py={16}>
                <Text fw="bold" size="28px" lh="42px">
                  Job Title
                </Text>
              </Table.Th>
              <Table.Th px={32} py={16}>
                <Text fw="bold" size="28px" lh="42px">
                  Fit %
                </Text>
              </Table.Th>
              <Table.Th px={32} py={16}>
                <Text fw="bold" size="28px" lh="42px">
                  Skills you have
                </Text>
              </Table.Th>
              <Table.Th px={32} py={16}>
                <Text fw="bold" size="28px" lh="42px">
                  Skills to master
                </Text>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Paper>
    </PDFPage>
  );
};
