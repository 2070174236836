import { Text, Title } from "@mantine/core";
import { PDFPage } from "./PDFPage";
import { PieChart, Pie, Cell, ResponsiveContainer, PieLabel } from "recharts";

const data = [
  {
    name: "Data Analysis",
    value: 1,
    color: "#de57cc",
  },
  {
    name: "Programming (Python/R)",
    value: 1,
    color: "#a855f7",
  },
  {
    name: "Advanced Excel/SQL",
    value: 1,
    color: "#6f40e2",
  },
  {
    name: "Machine Learning/AI Fundamentals",
    value: 1,
    color: "#3a8bd9",
  },
];

const RADIAN = Math.PI / 180;
const renderLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <foreignObject
      x={x - 140}
      y={y - 50}
      width="280"
      height="100%"
      color="#fff"
    >
      <Title size={27} ta="center" c="#FFF" maw={205} lh="37px">
        {name}
      </Title>
    </foreignObject>
  );
};

export const FutureSkillsPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Skills to Improve to Stay Competitive in the next 5 years
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        Explore vital counteractive skills and abilities essential for career
        competitiveness, ensuring continued professional growth and success.
      </Text>
      <ResponsiveContainer width="100%" height={872}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={436}
            innerRadius={190}
            label={renderLabel}
            labelLine={false}
            dataKey="value"
            startAngle={45}
            endAngle={450}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Text mt={48} size="24px" lh="36px">
        <b>Data Analysis</b>: Master data analytics to meet high demand for
        data-driven decision-making.
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Machine Learning/AI Fundamentals</b>: Understand automation
        technologies to collaborate with, rather than be replaced by, emerging
        tech.
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Advanced Excel/SQL</b>: Essential for efficiently handling, querying,
        and analyzing data in a data analysis role.
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Programming (Python/R)</b>: Proficiency in these languages is key for
        data science, analytics, and task automation.
      </Text>
    </PDFPage>
  );
};
