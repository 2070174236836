import { Box, Divider, Flex, Text, Title } from "@mantine/core";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { PDFPage } from "./PDFPage";

const data = [
  { subject: "Adaptability", A: 90, fullMark: 100 },
  { subject: "Communication", A: 85, fullMark: 100 },
  { subject: "Leadership", A: 75, fullMark: 100 },
  { subject: "Analytical Thinking", A: 80, fullMark: 100 },
  { subject: "Creativity", A: 90, fullMark: 100 },
  { subject: "Problem-Solving", A: 85, fullMark: 100 },
  { subject: "Collaboration", A: 75, fullMark: 100 },
];

export const StrengthsDerivedFromResumePDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Your Strengths Derived from Your Resume
      </Title>
      <Text mb={80} size="28px" lh="42px" c="dimmed">
        A focused insight into your inherent capabilities, highlighting how they
        can be effectively utilized in various professional settings.
      </Text>
      <Flex justify="center">
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius="80%"
          width={700}
          height={422}
          data={data}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#05D8D2" stopOpacity={1} />
              <stop offset="50%" stopColor="#6F40E2" stopOpacity={1} />
              <stop offset="100%" stopColor="#DE57CC" stopOpacity={1} />
            </linearGradient>
          </defs>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="subject"
            tick={{ fontSize: 22, fontWeight: "bold", fill: "#000" }}
          />
          <PolarRadiusAxis angle={30} domain={[0, 100]} tick={false} />
          <Radar
            name="Mike"
            dataKey="A"
            stroke="#8884d8"
            fill="url(#colorUv)"
            fillOpacity={0.6}
            dot={{ stroke: "#6F40E2", fill: "#6F40E2", strokeWidth: 2, r: 3 }}
          />
        </RadarChart>
      </Flex>
      <Box mt={80}>
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Adaptability
          </Text>
          <Text fw="bold" size="20px" c="#05D8D2">
            90%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          You have demonstrated ability to quickly adapt to various roles and
          industries, successfully transitioning from content management to
          email marketing.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Communication
          </Text>
          <Text fw="bold" size="20px" c="#3A8BD9">
            85%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Strong verbal and written communication skills evident from crafting
          compelling marketing messages and effective presentations to
          stakeholders.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Leadership
          </Text>
          <Text fw="bold" size="20px" c="#6F40E2">
            75%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Proven experience in leading projects and mentoring junior team
          members, with leadership roles in previous positions.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Analytical Thinking
          </Text>
          <Text fw="bold" size="20px" c="#A64DD7">
            80%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Proficient in analyzing market trends and campaign performance
          metrics, making data-driven decisions to optimize strategies.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Creativity
          </Text>
          <Text fw="bold" size="20px" c="#DE57CC">
            90%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Innovative in developing unique marketing campaigns and content, with
          a knack for thinking outside the box to solve challenges.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Problem-Solving
          </Text>
          <Text fw="bold" size="20px" c="#05D8D2">
            85%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Proficient in identifying issues and implementing effective solutions,
          with experience in troubleshooting campaign performance problems.
        </Text>
        <Divider size="2px" mb={8} />
        <Flex justify="space-between" mb={8}>
          <Text fw="bold" size="20px">
            Collaboration
          </Text>
          <Text fw="bold" size="20px" c="#3A8BD9">
            75%
          </Text>
        </Flex>
        <Text size="20px" lh="30px" mb={8}>
          Strong team player, effectively coordinating with cross-functional
          teams and departments, such as sales and product development.
        </Text>
      </Box>
    </PDFPage>
  );
};
