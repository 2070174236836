import { FC } from "react";
import { NewPasswordWiget } from "../../widgets/new-password/NewPassword";

interface Props {
  onContinue: () => void;
}

export const NewPasswordPage: FC<Props> = ({ onContinue }) => {
  return <NewPasswordWiget onContinue={onContinue} />;
};
