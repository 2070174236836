export const AuthFacebookIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_48_4917)">
        <path
          d="M24.6666 12C24.6666 5.37262 19.294 0 12.6666 0C6.03925 0 0.666626 5.37262 0.666626 12C0.666626 17.9895 5.05488 22.954 10.7916 23.8542V15.4688H7.74475V12H10.7916V9.35625C10.7916 6.34875 12.5832 4.6875 15.3243 4.6875C16.6371 4.6875 18.0104 4.92188 18.0104 4.92188V7.875H16.4973C15.0065 7.875 14.5416 8.80003 14.5416 9.74906V12H17.8698L17.3377 15.4688H14.5416V23.8542C20.2784 22.954 24.6666 17.9896 24.6666 12Z"
          fill="#1877F2"
        />
        <path
          d="M17.3377 15.4688L17.8698 12H14.5416V9.74906C14.5416 8.79994 15.0065 7.875 16.4973 7.875H18.0104V4.92188C18.0104 4.92188 16.6371 4.6875 15.3242 4.6875C12.5832 4.6875 10.7916 6.34875 10.7916 9.35625V12H7.74475V15.4688H10.7916V23.8542C11.4119 23.9514 12.0388 24.0002 12.6666 24C13.2945 24.0002 13.9214 23.9514 14.5416 23.8542V15.4688H17.3377Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_48_4917">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.666626)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
