import { Flex, Grid, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CheckedIcon, EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Preferences = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");

  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Preferences
      </Title>
      <Grid mt={40} gutter={40}>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">What are the key features you're looking for?</Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Remote work</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Short-term projects</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Business trips</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Team collaboration</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Being your own manager</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Yes</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            What aspects or conditions of work would you prefer to avoid?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Long commutes</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Overly competitive environments</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Rigid schedules</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Limited growth opportunities</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            What motivates you the most in your professional life?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Achieving challenging goals</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Personal growth and autonomy</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Competitive salary</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">What is your preferred work environment?</Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Corporate</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Startup</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Non-profit</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Government</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">What are your work preferences?</Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Flexible hours</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Full-time</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            Which industry aligns best with your career preferences?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Manufacturing</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Retail</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Hospitality and Tourism</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Entertainment and Media</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Real Estate</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Government and Public Sector</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Non-Profit and Social Services</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Energy and Utilities</Text>
          </Flex>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Transportation and Logistics</Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
