export const ManagingContentIcon = () => {
  return (
    <svg
      width="51"
      height="67"
      viewBox="0 0 51 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75945 60.6008C7.61545 60.6008 6.67945 59.6648 6.67945 58.5208V8.60076C6.67945 7.45676 7.61545 6.52076 8.75945 6.52076H29.5595V16.9208C29.5595 19.2218 31.4185 21.0808 33.7195 21.0808H44.1195V58.5208C44.1195 59.6648 43.1835 60.6008 42.0395 60.6008H8.75945ZM8.75945 0.280762C4.17045 0.280762 0.439453 4.01176 0.439453 8.60076V58.5208C0.439453 63.1098 4.17045 66.8408 8.75945 66.8408H42.0395C46.6285 66.8408 50.3595 63.1098 50.3595 58.5208V20.3658C50.3595 18.1558 49.4884 16.0368 47.9285 14.4768L36.1505 2.71176C34.5905 1.15176 32.4845 0.280762 30.2745 0.280762H8.75945ZM21.2395 33.5608C21.2395 32.4575 20.8012 31.3993 20.021 30.6192C19.2409 29.839 18.1828 29.4008 17.0795 29.4008C15.9762 29.4008 14.918 29.839 14.1379 30.6192C13.3577 31.3993 12.9195 32.4575 12.9195 33.5608C12.9195 34.6641 13.3577 35.7222 14.1379 36.5023C14.918 37.2825 15.9762 37.7208 17.0795 37.7208C18.1828 37.7208 19.2409 37.2825 20.021 36.5023C20.8012 35.7222 21.2395 34.6641 21.2395 33.5608ZM30.2355 39.6578C29.8455 39.0988 29.2085 38.7608 28.5195 38.7608C27.8305 38.7608 27.1935 39.0988 26.8035 39.6578L21.4345 47.4188L19.8875 44.9358C19.5105 44.3248 18.8345 43.9608 18.1195 43.9608C17.4045 43.9608 16.7415 44.3248 16.3515 44.9358L11.1515 53.2558C10.7485 53.8928 10.7355 54.6988 11.0995 55.3618C11.4635 56.0248 12.1655 56.4278 12.9195 56.4278H19.1595H23.3195H28.5195H37.8795C38.6595 56.4278 39.3615 55.9988 39.7255 55.3098C40.0895 54.6208 40.0375 53.8018 39.5955 53.1648L30.2355 39.6448V39.6578Z"
        fill="white"
      />
    </svg>
  );
};
