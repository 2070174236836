export const SpecialistToManagerSvg = () => {
  return (
    <svg
      width="129"
      height="128"
      viewBox="0 0 129 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="128" height="128" rx="64" fill="#3A8BD9" />
      <g clip-path="url(#clip0_724_3837)">
        <path
          d="M54.5 39V44H74.5V39C74.5 38.45 74.05 38 73.5 38H55.5C54.95 38 54.5 38.45 54.5 39ZM48.5 44V39C48.5 35.1375 51.6375 32 55.5 32H73.5C77.3625 32 80.5 35.1375 80.5 39V44H88.5C92.9125 44 96.5 47.5875 96.5 52V84C96.5 88.4125 92.9125 92 88.5 92H40.5C36.0875 92 32.5 88.4125 32.5 84V52C32.5 47.5875 36.0875 44 40.5 44H48.5ZM77.5 50H51.5H40.5C39.4 50 38.5 50.9 38.5 52V84C38.5 85.1 39.4 86 40.5 86H88.5C89.6 86 90.5 85.1 90.5 84V52C90.5 50.9 89.6 50 88.5 50H77.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3837">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(32.5 32)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
