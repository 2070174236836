export const TwitterIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8071 -6.10352e-05H12.8377L8.40201 5.08257L13.6208 12H9.53505L6.33263 7.80548L2.67246 12H0.639973L5.38401 6.56177L0.379883 0.000884735H4.5697L7.46001 3.83414L10.8071 -6.10352e-05ZM10.0931 10.7819H11.2185L3.95494 1.15474H2.74812L10.0931 10.7819Z"
        fill="black"
      />
    </svg>
  );
};
