import {
  Box,
  ColorSwatch,
  Flex,
  Grid,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { PieChart, Pie, Cell, PieLabel } from "recharts";
import {
  DocumentControlIcon,
  ManagingContentIcon,
  MarketingIcon,
  ProjectManagmentIcon,
} from "../../../shared/svg";
import { PDFPage } from "./PDFPage";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 200 },
  { name: "Group C", value: 200 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#05D8D2", "#A64DD7", "#6F40E2", "#3A8BD9"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const renderIcon = () => {
    switch (index) {
      case 0:
        return <MarketingIcon />;
      case 1:
        return <DocumentControlIcon />;
      case 2:
        return <ProjectManagmentIcon />;
      case 3:
        return <ManagingContentIcon />;
    }
  };

  return (
    <g>
      <foreignObject x={x - 38} y={y - 33.5} width="76" height="67">
        {renderIcon()}
      </foreignObject>
    </g>
  );
};

export const CareerInNumbersPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Your Strengths Derived from Your Resume

      </Title>
      <Text mb={80} size="28px" lh="42px" c="dimmed">
        A numerical perspective on your career’s impact, highlighting your
        experience and achievements.
      </Text>
      <Paper radius="15px" py="72px" px={34} mb={80} withBorder>
        <Flex justify="space-between" align="center">
          <Flex>
            <Flex direction="column" gap={32}>
              <Text fw="bold" mb={8} size="28px">
                8+ years in the workforce:
              </Text>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#05D8D2" size={16} />
                <Flex gap={8}>
                  <Text size="30px">Over</Text>
                  <Text c="#05D8D2" fw="bold" size="30px">
                    4 years
                  </Text>
                  <Text size="30px">in marketing</Text>
                </Flex>
              </Flex>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#3A8BD9" size={16} />
                <Flex gap={8}>
                  <Text size="30px">Over</Text>
                  <Text c="#3A8BD9" fw="bold" size="30px">
                    2 years
                  </Text>
                  <Text size="30px">managing content</Text>
                </Flex>
              </Flex>
              <Flex gap={8} direction="column">
                <Flex align="center" gap={8}>
                  <ColorSwatch color="#6F40E2" size={16} />
                  <Flex gap={8}>
                    <Text size="30px">Almost</Text>
                    <Text c="#6F40E2" fw="bold" size="30px">
                      2 years
                    </Text>
                    <Text size="30px">of project</Text>
                  </Flex>
                </Flex>
                <Text size="30px" ml={22}>
                  management
                </Text>
              </Flex>
              <Flex align="center" gap={8}>
                <ColorSwatch color="#A64DD7" size={16} />
                <Flex gap={8}>
                  <Text size="30px">Almost</Text>
                  <Text c="#A64DD7" fw="bold" size="30px">
                    2 years
                  </Text>
                  <Text size="30px">in document control</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Box>
            <PieChart width={416} height={416}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={208}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </Box>
        </Flex>
      </Paper>
      <Paper radius="15px" p="72px" withBorder>
        <Grid gutter={72}>
          <Grid.Col span={6}>
            <Text size="30px" ta="center">
              <Title size={64} c="#05D8D2" ta="center">
                7
              </Title>
              different roles within marketing and content domain in the last 3
              years
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="30px" ta="center">
              <Title size={64} c="#3A8BD9" ta="center">
                1
              </Title>
              year of education overlap with work experience
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="30px" ta="center">
              <Title size={64} c="#6F40E2" ta="center">
                4
              </Title>
              months in a short-term entrepreneurial venture
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="30px" ta="center">
              <Title size={64} c="#A64DD7" ta="center">
                4
              </Title>
              months in an internship
            </Text>
          </Grid.Col>
        </Grid>
      </Paper>
    </PDFPage>
  );
};
