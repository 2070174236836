// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-icon {
  position: absolute;
  top: 16px;
  right: 16px; }

.contact-info .contact-item {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 6px;
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: 120px auto; }
  .contact-info .contact-item:last-child {
    border: none; }

.contact-info a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block; }

.icon {
  border-radius: 50%;
  background: #6f40e21a;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/widgets/profile/Profile.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW,EAAA;;AAGb;EAEI,gCAAgC;EAChC,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,iCAAiC,EAAA;EANrC;IASM,YAAY,EAAA;;AATlB;EAcI,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc,EAAA;;AAIlB;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA","sourcesContent":[".edit-icon {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n}\n\n.contact-info {\n  .contact-item {\n    border-bottom: 1px solid #d6d6d6;\n    padding-bottom: 6px;\n    margin-bottom: 6px;\n    display: grid;\n    grid-template-columns: 120px auto;\n\n    &:last-child {\n      border: none;\n    }\n  }\n\n  a {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    display: block;\n  }\n}\n\n.icon {\n  border-radius: 50%;\n  background: #6f40e21a;\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
