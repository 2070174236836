import { FC } from "react";
import { UploadCVWiget } from "../../widgets/upload-cv/UploadCV";

interface Props {
  onUpload: () => void;
}

export const UploadCVPage: FC<Props> = ({ onUpload }) => {
  return <UploadCVWiget onUpload={onUpload} />;
};
