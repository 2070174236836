import { Box, Button, Flex, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Resolution, usePDF } from "react-to-pdf";
import { MainLayout } from "../../layouts/MainLayout";
import { CareerMilestones } from "../../widgets/report/browser/CareerMilestones";
import { CareerInNumbers } from "../../widgets/report/browser/CareerInNumbers";
import { StrengthsDerivedFromResume } from "../../widgets/report/browser/StrengthsDerivedFromResume";
import { useState } from "react";
import { TopTalents } from "../../widgets/report/browser/TopTalents";
import { TrendingSkills } from "../../widgets/report/browser/TrendingSkills";
import { SkillsPhasingOut } from "../../widgets/report/browser/SkillsPhasingOut";
import { OptimalPositions } from "../../widgets/report/browser/OptimalPositions";
import { HigherPositions } from "../../widgets/report/browser/HigherPositions";
import { RolesToConsider } from "../../widgets/report/browser/RolesToConsider";
import { FutureSkills } from "../../widgets/report/browser/FutureSkills";
import { DataAnalysisSkills } from "../../widgets/report/browser/DataAnalysisSkills";
import { SkillsAssessment } from "../../widgets/report/browser/SkillsAssessment";
import { EssentialSkillCourses } from "../../widgets/report/browser/EssentialSkillCourses";
import { PDFTitle } from "../../widgets/report/pdf/PDFTitle";
import { TitlePage } from "../../widgets/report/pdf/TitlePage";
import { CareerMilestonesPDF } from "../../widgets/report/pdf/CareerMilestonesPDF";
import { CareerInNumbersPDF } from "../../widgets/report/pdf/CareerInNumbersPDF";
import { StrengthsDerivedFromResumePDF } from "../../widgets/report/pdf/StrengthsDerivedFromResumePDF";
import { TopTalentsPDF } from "../../widgets/report/pdf/TopTalentsPDF";
import { TrendingSkillsPDF } from "../../widgets/report/pdf/TrendingSkillsPDF";
import { SkillsPhasingOutPDF } from "../../widgets/report/pdf/SkillsPhasingOutPDF";
import { OptimalPositionsPDF } from "../../widgets/report/pdf/OptimalPositionsPDF";
import { HigherPositionsPDF } from "../../widgets/report/pdf/HigherPositionsPDF";
import { RolesToConsiderPDF } from "../../widgets/report/pdf/RolesToConsiderPDF";
import { FutureSkillsPDF } from "../../widgets/report/pdf/FutureSkillsPDF";
import { DataAnalysisSkillsPDF } from "../../widgets/report/pdf/DataAnalysisSkillsPDF";
import { SkillsAssessmentPDF } from "../../widgets/report/pdf/SkillsAssessmentPDF";
import { EssentialSkillCoursesPDF } from "../../widgets/report/pdf/EssentialSkillCoursesPDF";

export const Report = () => {
  const matches = useMediaQuery("(max-width: 1100px)");
  const { toPDF, targetRef } = usePDF({
    filename: "report.pdf",
    resolution: Resolution.NORMAL,
  });
  const [isPDF, setIsPDF] = useState(false);

  const handleGeneratePDF = () => {
    setIsPDF(true);
    setTimeout(() => {
      toPDF();
      setIsPDF(false);
    }, 3000);
  };

  return (
    <MainLayout>
      {isPDF ? (
        <Box w="1191px" m="auto" ref={targetRef}>
          <PDFTitle />
          <TitlePage
            title={
              <>
                Part 1. Snapshot <br /> of Your Career Journey
              </>
            }
          />
          <CareerMilestonesPDF />
          <CareerInNumbersPDF />
          <StrengthsDerivedFromResumePDF />
          <TitlePage title="Part 2. Analyzing Your Skills" />
          <TopTalentsPDF />
          <TrendingSkillsPDF />
          <SkillsPhasingOutPDF />
          <TitlePage title="Part 3. Your Ideal Roles" />
          <OptimalPositionsPDF />
          <HigherPositionsPDF />
          <RolesToConsiderPDF />
          <TitlePage title="Part 4. Your Personalized Roadmap" />
          <FutureSkillsPDF />
          <DataAnalysisSkillsPDF />
          <SkillsAssessmentPDF />
          <EssentialSkillCoursesPDF />
        </Box>
      ) : (
        <Box px={matches ? 16 : 40} py={matches ? 24 : 32}>
          <Flex
            align={matches ? "start" : "center"}
            gap={16}
            justify="space-between"
            direction={matches ? "column" : undefined}
          >
            <Title size={48}>Snapshot of Your Career Journey</Title>
            <Button
              miw="max-content"
              variant="outline"
              onClick={handleGeneratePDF}
              className="download-button"
            >
              Download PDF
            </Button>
          </Flex>
          <CareerMilestones />
          <CareerInNumbers />
          <StrengthsDerivedFromResume />
          <Title size={matches ? 36 : 48} mt={32}>
            Snapshot of Your Career Journey
          </Title>
          <TopTalents />
          <TrendingSkills />
          <SkillsPhasingOut />
          <Title size={matches ? 36 : 48} mt={32}>
            Your Ideal Roles
          </Title>
          <OptimalPositions />
          <HigherPositions />
          <RolesToConsider />
          <Title size={matches ? 36 : 48} mt={32}>
            Your Personalized Roadmap
          </Title>
          <FutureSkills />
          <DataAnalysisSkills />
          <SkillsAssessment />
          <EssentialSkillCourses />
        </Box>
      )}
      <Button ml={40} mb={32} onClick={handleGeneratePDF}>
        Download Career Report PDF
      </Button>
    </MainLayout>
  );
};
