import { Flex, Paper, Rating, Text, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { FullRatingIcon, FullRatingMobileIcon, RatingIcon, RatingMobileIcon } from "../../shared/svg";

export const LikeResponse = () => {
  const matches = useMediaQuery("(max-width: 950px)");

  return (
    <Paper mt={40} p={16} radius={8} bg="#6F40E21A" className="like-response">
      <Flex
        gap={matches ? 16 : 24}
        align="center"
        direction={matches ? "column" : undefined}
      >
        <Text className="nowrap">Do you like the response?</Text>
        <Rating
          emptySymbol={matches ? <RatingMobileIcon /> : <RatingIcon />}
          fullSymbol={matches ? <FullRatingMobileIcon /> : <FullRatingIcon />}
        />
        <TextInput label="Share your thoughts" w="100%" />
      </Flex>
    </Paper>
  );
};
