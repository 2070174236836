import { Grid, Paper, Text, Title } from "@mantine/core";
import { PDFPage } from "./PDFPage";

export const DataAnalysisSkillsPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={16}>
        Skills to Master for New Role in Data Analysis & Science
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        Discover key skills to master for climbing the career ladder in your
        field, focusing on strategic, technical, and interpersonal competencies
        essential for higher-level positions.
      </Text>
      <Grid gutter={32}>
        <Grid.Col span={6}>
          <Paper radius="36px" p="48px" bg="#3A8BD9" maw={578}>
            <Title size={40} mb={32} c="#fff" lh="55px">
              Data Analysis and Statistical Techniques
            </Title>
            <Text c="#fff" size="28px" lh="42px">
              Learning statistical analysis methods is critical as they are the
              foundation for analyzing data sets and deriving actionable
              insights, which is at the heart of data science roles.
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Paper radius="36px" p="48px" bg="#6F40E2" maw={578}>
            <Title size={40} mb={32} c="#fff" lh="55px">
              Programming Languages (e.g., Python/R)
            </Title>
            <Text c="#fff" size="28px" lh="42px">
              Proficiency in programming languages like Python or R is necessary
              for handling complex data analysis tasks and creating reproducible
              analyses and visualizations.
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Paper radius="36px" p="48px" bg="#A64DD7" maw={578}>
            <Title size={40} mb={32} c="#fff" lh="55px">
              Data Visualization and Communication
            </Title>
            <Text c="#fff" size="28px" lh="42px">
              The ability to create clear, impactful visual representations of
              data and effectively communicate findings to stakeholders can make
              the difference in conveying complex results to non-technical
              audiences.
            </Text>
          </Paper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Paper radius="36px" p="48px" bg="#DE57CC" maw={578}>
            <Title size={40} mb={32} c="#fff" lh="55px">
              Machine Learning Fundamentals
            </Title>
            <Text c="#fff" size="28px" lh="42px">
              Understanding machine learning concepts is increasingly important
              in data science to automate data analysis processes and develop
              predictive models that can provide forward-looking insights.
            </Text>
          </Paper>
        </Grid.Col>
      </Grid>
    </PDFPage>
  );
};
