import { useNavigate, useParams } from "react-router";
import { Center, Container, Space } from "@mantine/core";
import { Logo } from "../../shared/svg";
import { SingInPage } from "../../pages/sing-in/SingIn";
import { ForgotPasswordPage } from "../../pages/forgot-password/ForgotPassword";
import { LinkSentPage } from "../../pages/link-sent/LinkSent";
import { NewPasswordPage } from "../../pages/new-password/NewPassword";
import { PasswordUpdatedPage } from "../../pages/password-updated/PasswordUpdated";

export const Login = () => {
  const { page } = useParams();
  const navigate = useNavigate();

  const renderPage = () => {
    switch (page) {
      case "sing-in":
        return <SingInPage />;
      case "forgot-password":
        return (
          <ForgotPasswordPage onContinue={() => navigate("/login/link-sent")} />
        );
      case "link-sent":
        return <LinkSentPage />;
      case "new-password":
        return (
          <NewPasswordPage
            onContinue={() => navigate("/login/password-updated")}
          />
        );
      case "password-updated":
        return <PasswordUpdatedPage />;
    }
  };

  return (
    <Center mt={50} mb={50}>
      <Container size="sm" w={656}>
        <center>
          <Logo />
          <Space h="24px" />
          {renderPage()}
        </center>
      </Container>
    </Center>
  );
};
