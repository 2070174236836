import { Text, Title } from "@mantine/core";
import { PDFPage } from "./PDFPage";

export const HigherPositionsPDF = () => {
  return (
    <PDFPage padding>
      <Title size={44} mb={8}>
        Higher Positions You Can Aspire to in Your Field
      </Title>
      <Text mb={48} size="24px" lh="42px" c="dimmed">
        Discover higher-level positions that align with your expertise and
        experience, revealing opportunities for advancement and leadership in
        your role within Email Marketing and the IT industry.
      </Text>
      <div className="pdf-piramid">
        <div className="triangle triangle-1">
          <Text size="20px">Senior Email Marketing Manager</Text>
        </div>
        <div className="triangle triangle-2">
          <Text size="20px">Head of Customer Retention</Text>
        </div>
        <div className="triangle triangle-3">
          <Text size="20px">Email Marketing Director</Text>
        </div>
        <div className="triangle triangle-4">
          <Text size="20px">Digital Marketing Director</Text>
        </div>
        <div className="triangle triangle-5">
          <Text size="20px">Chief Marketing Officer</Text>
        </div>
      </div>
      <Text mt={48} size="24px" lh="36px">
        <b>Senior Email Marketing Manager</b>: Advance from Email Marketing
        Manager to oversee larger strategic responsibilities and manage bigger
        campaigns
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Head of Customer Retention</b>: Leverage your retention marketing
        experience to develop strategies enhancing customer loyalty and lifetime
        value
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Email Marketing Director</b>: Oversee the entire email marketing
        department, integrating marketing knowledge with high-level
        decision-making and leadership.
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Digital Marketing Director</b>: Expand your scope to oversee all
        digital marketing efforts, including social media, content marketing,
        and paid advertising.
      </Text>
      <Text mt={24} size="24px" lh="36px">
        <b>Chief Marketing Officer (CMO)</b>: Aim for a C-suite position,
        responsible for overall marketing operations and strategy development.
      </Text>
    </PDFPage>
  );
};
