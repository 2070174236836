export const FullRatingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6292 3.20312C13.4221 2.77344 12.9846 2.5 12.5042 2.5C12.0237 2.5 11.5901 2.77344 11.3792 3.20312L8.86744 8.37109L3.25806 9.19922C2.78931 9.26953 2.39869 9.59766 2.25416 10.0469C2.10963 10.4961 2.22681 10.9922 2.56275 11.3242L6.63306 15.3516L5.67213 21.043C5.594 21.5117 5.78931 21.9883 6.17603 22.2656C6.56275 22.543 7.07447 22.5781 7.49635 22.3555L12.5081 19.6797L17.5198 22.3555C17.9417 22.5781 18.4534 22.5469 18.8401 22.2656C19.2268 21.9844 19.4221 21.5117 19.344 21.043L18.3792 15.3516L22.4495 11.3242C22.7854 10.9922 22.9065 10.4961 22.7581 10.0469C22.6096 9.59766 22.2229 9.26953 21.7542 9.19922L16.1409 8.37109L13.6292 3.20312Z"
        fill="#6F40E2"
      />
    </svg>
  );
};
