import { Box, Grid } from "@mantine/core";

import { MainLayout } from "../../layouts/MainLayout";
import { Statistics } from "../../widgets/dashboard/Statistics";
import { CurrentGoals } from "../../widgets/dashboard/CurrentGoals";
import { RecomendedCourses } from "../../widgets/dashboard/RecomendedCourses";
import { useMediaQuery } from "@mantine/hooks";
export const Dashboard = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");
  const mobile = useMediaQuery("(max-width: 820px)");

  return (
    <MainLayout>
      <Box m={mobile ? 16 : 40}>
        <Grid mb={laptop ? 24 : 32} gutter={laptop ? 24 : 32}>
          <Grid.Col span={laptop ? 12 : 6}>
            <Statistics />
          </Grid.Col>
          <Grid.Col span={laptop ? 12 : 6}>
            <CurrentGoals />
          </Grid.Col>
        </Grid>
        <RecomendedCourses />
      </Box>
    </MainLayout>
  );
};
