import { Flex, Paper, Text, Title } from "@mantine/core";
import { CVIcon, EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const CVBlock = () => {
  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Flex direction="column" justify="center" align="center" h="100%">
        <CVIcon />
        <Title size={24} mt={16}>
          Your CV
        </Title>
        <Text mt={8} ta="center">
          Robbie Winterbottom CV.pdf
        </Text>
      </Flex>
    </Paper>
  );
};
