import { Flex, Paper, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  CivilSectorExperienceSvg,
  DiverseIndustryExposureSvg,
  EmailManagerSvg,
  ShortTermEntrepreneurshipSvg,
  SpecialistToManagerSvg,
} from "../../../shared/svg";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import "../Report.scss";

export const CareerMilestones = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  const icons = (
    <Flex
      mx={matches ? 0 : 40}
      my={matches ? 0 : 80}
      mb={matches ? 130 : 0}
      justify="space-between"
      pos="relative"
      direction={matches ? "column" : undefined}
    >
      <div className="timeline" />
      <EmailManagerSvg />
      <SpecialistToManagerSvg />
      <CivilSectorExperienceSvg />
      <ShortTermEntrepreneurshipSvg />
      <DiverseIndustryExposureSvg />
    </Flex>
  );

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Career Milestones
      </Title>
      <Text mb={24} c="dimmed">
        Uncover the pivotal moments and transitions in your career that have
        shaped your unique skill set and professional worth.
      </Text>
      <Flex gap={16} className="career-milestones">
        {matches && icons}
        <Stack
          gap={matches ? 40 : 0}
          ta={matches ? undefined : "center"}
          w="100%"
        >
          <Flex
            justify="space-between"
            align="center"
            gap={matches ? 40 : 0}
            direction={matches ? "column" : undefined}
          >
            <Text maw={matches ? 199 : 208} mih={200}>
              <b>Transition to Email Marketing Manager</b> <br /> Secured a role
              at GigaCloud as an Email Marketing Manager within a year of
              specializing in email marketing.
            </Text>
            <Text maw={matches ? 199 : 208}>
              <b>Specialist to Manager in a year</b> <br /> Progressed from a
              Content Management Specialist at Intertop Ukraine to a Retention
              Marketing Manager at Squro in approximately a year, showing rapid
              career advancement.
            </Text>
            <Text maw={matches ? 199 : 208}>
              <b>Leadership Experience in Civil Sector</b> <br /> Led an
              initiative group in Civil Corps, which demonstrates leadership
              skills and community involvement that can translate well into any
              sector.
            </Text>
          </Flex>
          {!matches && icons}
          <Flex
            justify="space-around"
            align="center"
            mt={matches ? 0 : 40}
            gap={matches ? 40 : 0}
            direction={matches ? "column" : undefined}
          >
            <Text maw={matches ? 199 : 208}>
              <b>Short-term Entrepreneurship</b> <br /> Ventured into
              entrepreneurship as a Content and Marketing Specialist for a brief
              period, indicating versatility and initiative in your career.
            </Text>
            <Text maw={matches ? 199 : 208}>
              <b>Diverse Industry Exposure</b> <br /> Worked in government, IT
              products, and B2B cloud service sectors, reflecting your ability
              to adapt to varying work environments.
            </Text>
          </Flex>
        </Stack>
      </Flex>
      <LikeResponse />
    </Paper>
  );
};
