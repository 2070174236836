import { Paper, Text, Title } from "@mantine/core";
import { EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Education = () => {
  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Education
      </Title>
      <Title size={24} mt={40}>
        Master of Business Administration in Marketing
      </Title>
      <Text mt={8}>Prestige University, Boston, MA</Text>
      <Text mt={8} c="#737373">
        2010 - 2012
      </Text>
      <Title size={24} mt={40}>
        Bachelor of Science in Marketing
      </Title>
      <Text mt={8}>Northfield College, Chicago, IL</Text>
      <Text mt={8} c="#737373">
        2006 - 2010
      </Text>
    </Paper>
  );
};
