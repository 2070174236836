import { Button, PasswordInput, Stack } from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";

interface Props {
  onContinue: () => void;
}

export const NewPasswordForm: FC<Props> = ({ onContinue }) => {
  const form = useForm({
    initialValues: {
      password: "",
    },
    validate: {
      password: (value: string) => {
        if (value.length < 6) {
          return "Password must be at least 6 characters long";
        }
        if (!/[0-9]/.test(value)) {
          return "Password must contain at least one number";
        }
        return null;
      },
    },
  });

  return (
    <form onSubmit={form.onSubmit(onContinue)}>
      <Stack align="stretch" justify="center" gap="24px">
        <PasswordInput
          placeholder="Password"
          label="Password"
          {...form.getInputProps("password")}
        />
        <Button fullWidth type="submit">
          Continue
        </Button>
      </Stack>
    </form>
  );
};
