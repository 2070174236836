import { Accordion, Flex, Paper, Text, Title } from "@mantine/core";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { useMediaQuery } from "@mantine/hooks";
import { LikeResponse } from "../../../entities/components/LikeResponse";

const data = [
  { subjectShort: "1", subject: "Adaptability", A: 90, fullMark: 100 },
  { subjectShort: "2", subject: "Communication", A: 85, fullMark: 100 },
  { subjectShort: "3", subject: "Leadership", A: 75, fullMark: 100 },
  { subjectShort: "4", subject: "Analytical Thinking", A: 80, fullMark: 100 },
  { subjectShort: "5", subject: "Creativity", A: 90, fullMark: 100 },
  { subjectShort: "6", subject: "Problem-Solving", A: 85, fullMark: 100 },
  { subjectShort: "7", subject: "Collaboration", A: 75, fullMark: 100 },
];

export const StrengthsDerivedFromResume = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Strengths Derived from Your Resume
      </Title>
      <Text mb={24} c="dimmed">
        A focused insight into your inherent capabilities, highlighting how they
        can be effectively utilized in various professional settings.
      </Text>
      <Flex
        justify="space-between"
        align="center"
        direction={matches ? "column" : undefined}
      >
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius="80%"
          width={matches ? 279 : 596}
          height={matches ? 296 : 422}
          data={data}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#05D8D2" stopOpacity={1} />
              <stop offset="50%" stopColor="#6F40E2" stopOpacity={1} />
              <stop offset="100%" stopColor="#DE57CC" stopOpacity={1} />
            </linearGradient>
          </defs>
          <PolarGrid />
          <PolarAngleAxis
            dataKey={matches ? "subjectShort" : "subject"}
            tick={{
              fontSize: 16,
              fontWeight: "bold",
              fill: "#000",
            }}
          />
          <PolarRadiusAxis angle={30} domain={[0, 100]} tick={false} />
          <Radar
            name="Mike"
            dataKey="A"
            stroke="#8884d8"
            fill="url(#colorUv)"
            fillOpacity={0.6}
            dot={{ stroke: "#6F40E2", fill: "#6F40E2", strokeWidth: 2, r: 3 }}
          />
        </RadarChart>
        <Accordion defaultValue="Apples" w={matches ? "100%" : "50%"}>
          <Accordion.Item value="Adaptability">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">1. Adaptability</Text>
                <Text fw="bold" c="#05D8D2" mr={20}>
                  90%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Communication">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">2. Communication</Text>
                <Text fw="bold" c="#3A8BD9" mr={20}>
                  85%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Leadership">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">3. Leadership</Text>
                <Text fw="bold" c="#6F40E2" mr={20}>
                  75%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Analytical Thinking">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">4. Analytical Thinking</Text>
                <Text fw="bold" c="#A64DD7" mr={20}>
                  80%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Creativity">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">5. Creativity</Text>
                <Text fw="bold" c="#DE57CC" mr={20}>
                  90%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Problem-Solving">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">6. Problem-Solving</Text>
                <Text fw="bold" c="#05D8D2" mr={20}>
                  85%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Collaboration">
            <Accordion.Control>
              <Flex justify="space-between">
                <Text fw="bold">7. Collaboration</Text>
                <Text fw="bold" c="#3A8BD9" mr={20}>
                  75%
                </Text>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>
                You have demonstrated ability to quickly adapt to various roles
                and industries, successfully transitioning from content
                management to email marketing.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
      <LikeResponse />
    </Paper>
  );
};
