import { FC } from "react";
import { Link } from "react-router-dom";
import { Anchor, Divider, Stack, Text, Title } from "@mantine/core";
import { SocialButtons } from "../../entities/components/SocialButtons.tsx";
import { SingUpForm } from "../../features/sing-up/SingUpForm";

interface Props {
  onSingUp: () => void;
}

export const SingUpWiget: FC<Props> = ({ onSingUp }) => {
  return (
    <Stack align="stretch" justify="center" gap="24px">
      <Title size={36}>Sign up</Title>
      <Text c="dimmed" size="md">
        Sign up with a social media account
      </Text>
      <SocialButtons />
      <Divider
        label={
          <Text c="dimmed" size="md">
            or
          </Text>
        }
        labelPosition="center"
      />
      <Text c="dimmed" size="md">
        Sign up with email
      </Text>
      <SingUpForm onSingUp={onSingUp} />
      <Text c="dimmed" size="md">
        Already have an account?{" "}
        <Anchor
          c="dimmed"
          to="/login/sing-in"
          underline="always"
          component={Link}
        >
          Sign in
        </Anchor>
      </Text>
    </Stack>
  );
};
