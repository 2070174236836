import { Button, Stack, TextInput } from "@mantine/core";
import { FC } from "react";
import { isEmail, useForm } from "@mantine/form";

interface Props {
  onContinue: () => void;
}

export const ForgotPasswordForm: FC<Props> = ({ onContinue }) => {
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: isEmail("Invalid email address"),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onContinue)}>
      <Stack align="stretch" justify="center" gap="24px">
        <TextInput
          placeholder="Email"
          label="Email"
          {...form.getInputProps("email")}
        />
        <Button fullWidth type="submit">
          Continue
        </Button>
      </Stack>
    </form>
  );
};
