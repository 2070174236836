import { FC } from "react";
import { Link } from "react-router-dom";
import { Anchor, Stack, Text, Title } from "@mantine/core";
import { ForgotPasswordForm } from "../../features/forgot-password/ForgotPasswordForm";

interface Props {
  onContinue: () => void;
}

export const ForgotPasswordWiget: FC<Props> = ({ onContinue }) => {
  return (
    <Stack align="stretch" justify="center" gap="24px">
      <Title size={36}>Reset Password</Title>
      <Text c="dimmed" size="md">
        Enter your email address and we will send you the link to reset your
        password
      </Text>
      <ForgotPasswordForm onContinue={onContinue} />
      <Anchor
        c="dimmed"
        to="/login/sing-in"
        underline="always"
        component={Link}
      >
        Back to Sign In
      </Anchor>
    </Stack>
  );
};
