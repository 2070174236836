import { Box, Grid, Tabs } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { MainLayout } from "../../layouts/MainLayout";
import { CourseCard } from "../../entities/components/CourseCard";
import Course1 from "../../shared/images/Course-1.png";
import Course2 from "../../shared/images/Course-2.png";
import Course3 from "../../shared/images/Course-3.png";
import Course4 from "../../shared/images/Course-4.png";
import Course5 from "../../shared/images/Course-5.png";
import Course6 from "../../shared/images/Course-6.png";
import Course7 from "../../shared/images/Course-7.png";
import Course8 from "../../shared/images/Course-8.png";
import Course9 from "../../shared/images/Course-9.png";

const courses = [
  {
    image: Course1,
    platform: "Coursera",
    title: "Google Data Analytics",
    instructor: "Google",
    rating: 4.9,
  },
  {
    image: Course2,
    platform: "edX",
    title: "MIT SA+P: Data Science in Real Estate",
    instructor: "Massachusetts Institute of Technology",
    rating: "Unrated",
  },
  {
    image: Course3,
    platform: "Udemy",
    title: "Data Analysis | SQL, Tableau, Power BI & Excel | Real Projects",
    instructor: "Graeme Gordon",
    rating: 4.4,
  },
  {
    image: Course4,
    platform: "Udemy",
    title: "Complete Introduction to Business Data Analysis",
    instructor: "Ian Littlejohn",
    rating: 4.6,
  },
  {
    image: Course5,
    platform: "Coursera",
    title: "Business Analytics with Excel: Elementary to Advanced",
    instructor: "John Hopkins University",
    rating: 4.8,
  },
  {
    image: Course6,
    platform: "edX",
    title: "HarvardX: Introduction to Data Science with Python",
    instructor: "Harvard",
    rating: 4.5,
  },
  {
    image: Course7,
    platform: "Udemy",
    title: "Data Analysis with Pandas and Python",
    instructor: "Boris Paskhaver",
    rating: 4.6,
  },
  {
    image: Course8,
    platform: "edX",
    title: "LSE: Data Analysis for Management",
    instructor: "London School of Economics",
    rating: "Unrated",
  },
  {
    image: Course9,
    platform: "Coursera",
    title: "Introduction to Data Analytics",
    instructor: "IBM",
    rating: 4.7,
  },
];

export const Courses = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");
  const mobile = useMediaQuery("(max-width: 820px)");

  return (
    <MainLayout>
      <Box m={mobile ? 16 : 40}>
        <Tabs defaultValue="Recommended">
          <Tabs.List>
            <Tabs.Tab value="Recommended" p={mobile ? 16 : undefined}>
              Recommended
            </Tabs.Tab>
            <Tabs.Tab value="My learning" p={mobile ? 16 : undefined}>
              My learning
            </Tabs.Tab>
            <Tabs.Tab value="All Courses" p={mobile ? 16 : undefined}>
              All Courses
            </Tabs.Tab>
          </Tabs.List>
          <Grid mt={33}>
            {courses.map((course, index) => (
              <Grid.Col span={mobile ? 12 : laptop ? 6 : 4} key={index}>
                <CourseCard
                  image={course.image}
                  platform={course.platform}
                  title={course.title}
                  instructor={course.instructor}
                  rating={course.rating as number | "Unrated"}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Tabs>
      </Box>
    </MainLayout>
  );
};
