import { FC } from "react";
import { QuestionnaireWiget } from "../../widgets/questionnaire/Questionnaire";

interface Props {
  onSubmit: () => void;
}

export const QuestionnairePage: FC<Props> = ({ onSubmit }) => {
  return <QuestionnaireWiget onSubmit={onSubmit} />;
};
