import { FC } from "react";
import {
  Checkbox,
  Fieldset,
  Radio,
  RadioGroup,
  Text,
  TextInput,
} from "@mantine/core";

interface Props {
  questionnaire: {
    [key: string]: string | string[];
  };
  updateQuestionnaire: (key: string, value: string, checked?: boolean) => void;
}

const renderTextInput = (
  condition: boolean,
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void
) =>
  condition && (
    <TextInput
      mt="md"
      placeholder="Please specify"
      label="Please specify"
      onChange={onChange}
    />
  );

export const Questionnaire2: FC<Props> = ({
  questionnaire,
  updateQuestionnaire,
}) => {
  const isOther = (key: string) => {
    return ((questionnaire[key] as string[]) || []).some((item) =>
      item.startsWith("Other")
    );
  };

  return (
    <>
      <RadioGroup
        w="100%"
        size="md"
        ta="left"
        label={
          <Text fw="bold" ta="left">
            What best describes your current career goals?
          </Text>
        }
        onChange={(value) =>
          updateQuestionnaire(
            "What best describes your current career goals?",
            value
          )
        }
      >
        {[
          "I am facing challenges in securing a promotion",
          "I am seeking my first job in a new country",
          "I am looking for my first job after a career break",
          "I am searching for my first job after graduation",
          "Other",
        ].map((label) => (
          <Radio key={label} mt="md" label={label} value={label} />
        ))}
        {renderTextInput(
          questionnaire[
            "What best describes your current career goals?"
          ]?.includes("Other"),
          (input) =>
            updateQuestionnaire(
              "What best describes your current career goals?",
              `Other: ${input.target.value}`
            )
        )}
      </RadioGroup>

      <RadioGroup
        w="100%"
        size="md"
        ta="left"
        label={
          <Text fw="bold" ta="left">
            What is your timeline for achieving these goals?
          </Text>
        }
        onChange={(value) =>
          updateQuestionnaire(
            "What is your timeline for achieving these goals?",
            value
          )
        }
      >
        {["0-3 months", "6-12 months", "Up to 3 years"].map((label) => (
          <Radio key={label} mt="md" label={label} value={label} />
        ))}
      </RadioGroup>

      <RadioGroup
        w="100%"
        size="md"
        ta="left"
        label={
          <Text fw="bold" ta="left">
            How much are you willing to invest in your professional development?
          </Text>
        }
        onChange={(value) =>
          updateQuestionnaire(
            "How much are you willing to invest in your professional development?",
            value
          )
        }
      >
        {[
          "Not ready to invest time in professional development",
          "Only open to free educational resources",
          "Willing to invest minimally (e.g., low-cost online courses)",
          "Prepared to make a significant investment (e.g., advanced training, degrees)",
        ].map((label) => (
          <Radio key={label} mt="md" label={label} value={label} />
        ))}
      </RadioGroup>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            What are the key features you're looking for? (Select all that
            apply)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "What are the key features you're looking for? (Select all that apply)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {[
          "Remote work",
          "Short-term projects",
          "Business trips",
          "Team collaboration",
          "Being your own manager",
          "Other",
        ].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
        {renderTextInput(
          isOther(
            "What are the key features you're looking for? (Select all that apply)"
          ),
          (input) =>
            updateQuestionnaire(
              "What are the key features you're looking for? (Select all that apply)",
              `Other: ${input.target.value}`,
              true
            )
        )}
      </Fieldset>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            What aspects or conditions of work would you prefer to avoid?
            (Select all that apply)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "What aspects or conditions of work would you prefer to avoid? (Select all that apply)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {[
          "Long commutes",
          "Overly competitive environments",
          "Rigid schedules",
          "Limited growth opportunities",
          "Other",
        ].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
        {renderTextInput(
          isOther(
            "What aspects or conditions of work would you prefer to avoid? (Select all that apply)"
          ),
          (input) =>
            updateQuestionnaire(
              "What aspects or conditions of work would you prefer to avoid? (Select all that apply)",
              `Other: ${input.target.value}`,
              true
            )
        )}
      </Fieldset>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            What motivates you the most in your professional life? (Select up to
            3)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "What motivates you the most in your professional life? (Select up to 3)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {[
          "Achieving challenging goals",
          "Working in a team",
          "Learning new skills",
          "Recognition and rewards",
          "Personal growth and autonomy",
          "Competitive salary",
          "Making a difference",
          "Other",
        ].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
        {renderTextInput(
          isOther(
            "What motivates you the most in your professional life? (Select up to 3)"
          ),
          (input) =>
            updateQuestionnaire(
              "What motivates you the most in your professional life? (Select up to 3)",
              `Other: ${input.target.value}`,
              true
            )
        )}
      </Fieldset>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            What is your preferred work environment? (Select all that apply)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "What is your preferred work environment? (Select all that apply)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {["Corporate", "Startup", "Non-profit", "Government"].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
      </Fieldset>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            What are your work preferences? (Select all that apply)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "What are your work preferences? (Select all that apply)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {["Flexible hours", "Full-time", "Part-time"].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
      </Fieldset>

      <Fieldset
        w="100%"
        legend={
          <Text fw="bold" ta="left">
            Which industry aligns best with your career preferences? (Select all
            that apply)
          </Text>
        }
        variant="unstyled"
        onChange={(event) => {
          updateQuestionnaire(
            "Which industry aligns best with your career preferences? (Select all that apply)",
            // @ts-ignore
            event.target.value,
            // @ts-ignore
            event.target.checked
          );
        }}
      >
        {[
          "Manufacturing",
          "Retail",
          "Hospitality and Tourism",
          "Entertainment and Media",
          "Real Estate",
          "Government and Public Sector",
          "Non-Profit and Social Services",
          "Energy and Utilities",
          "Transportation and Logistics",
          "Other",
        ].map((label) => (
          <Checkbox key={label} mt="md" label={label} value={label} />
        ))}
        {renderTextInput(
          isOther(
            "Which industry aligns best with your career preferences? (Select all that apply)"
          ),
          (input) =>
            updateQuestionnaire(
              "Which industry aligns best with your career preferences? (Select all that apply)",
              `Other: ${input.target.value}`,
              true
            )
        )}
      </Fieldset>
    </>
  );
};
