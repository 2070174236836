import { FC } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Center,
  Checkbox,
  Group,
  PasswordInput,
  Stack,
  TextInput,
  Text,
  Anchor,
} from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";

interface Props {
  onSingUp: () => void;
}

export const SingUpForm: FC<Props> = ({ onSingUp }) => {
  const matches = useMediaQuery("(max-width: 520px)");
  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      terms: false,
    },
    validate: {
      firstName: isNotEmpty("First name is required"),
      lastName: isNotEmpty("Last name is required"),
      email: isEmail("Invalid email address"),
      password: (value: string) => {
        if (value.length < 6) {
          return "Password must be at least 6 characters long";
        }
        if (!/[0-9]/.test(value)) {
          return "Password must contain at least one number";
        }
        return null;
      },
      terms: (value: boolean) => (value ? null : true),
    },
  });

  const names = (
    <>
      <TextInput
        placeholder="First Name"
        label="First Name"
        {...form.getInputProps("firstName")}
      />
      <TextInput
        placeholder="Last Name"
        label="Last Name"
        {...form.getInputProps("lastName")}
      />
    </>
  );

  return (
    <form onSubmit={form.onSubmit(onSingUp)}>
      <Stack align="stretch" justify="center" gap="24px">
        {matches ? (
          names
        ) : (
          <Group justify="center" align="start" grow>
            {names}
          </Group>
        )}
        <TextInput
          placeholder="Email"
          label="Email"
          {...form.getInputProps("email")}
        />
        <PasswordInput
          placeholder="Password"
          label="Password"
          {...form.getInputProps("password")}
        />
        <Center>
          <Checkbox
            label={
              <Text>
                I agree to the{" "}
                <Anchor
                  c="dark"
                  to="/terms-of-service"
                  underline="always"
                  component={Link}
                >
                  Terms of Service
                </Anchor>{" "}
                and the{" "}
                <Anchor
                  c="dark"
                  to="/privacy-policy"
                  underline="always"
                  component={Link}
                >
                  Privacy Policy
                </Anchor>
              </Text>
            }
            {...form.getInputProps("terms", { type: "checkbox" })}
          />
        </Center>
        <Button fullWidth type="submit">
          Sign up
        </Button>
      </Stack>
    </form>
  );
};
