export const ToRightIcon = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.294922 11.09L4.87492 6.5L0.294922 1.91L1.70492 0.5L7.70492 6.5L1.70492 12.5L0.294922 11.09Z"
        fill="black"
      />
    </svg>
  );
};
