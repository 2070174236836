export const CivilSectorExperienceSvg = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="128" height="128" rx="64" fill="#6F40E2" />
      <g clip-path="url(#clip0_724_3841)">
        <path
          d="M64 58C66.6522 58 69.1957 56.9464 71.0711 55.0711C72.9464 53.1957 74 50.6522 74 48C74 45.3478 72.9464 42.8043 71.0711 40.9289C69.1957 39.0536 66.6522 38 64 38C61.3478 38 58.8043 39.0536 56.9289 40.9289C55.0536 42.8043 54 45.3478 54 48C54 50.6522 55.0536 53.1957 56.9289 55.0711C58.8043 56.9464 61.3478 58 64 58ZM80 48C80 52.2435 78.3143 56.3131 75.3137 59.3137C72.3131 62.3143 68.2435 64 64 64C59.7565 64 55.6869 62.3143 52.6863 59.3137C49.6857 56.3131 48 52.2435 48 48C48 43.7565 49.6857 39.6869 52.6863 36.6863C55.6869 33.6857 59.7565 32 64 32C68.2435 32 72.3131 33.6857 75.3137 36.6863C78.3143 39.6869 80 43.7565 80 48ZM62.1375 76.9L59.8125 73.025C59.0125 71.6875 59.975 70 61.525 70H64H66.4625C68.0125 70 68.975 71.7 68.175 73.025L65.85 76.9L67.8375 84.3L73.3125 73.35C73.6875 72.6 74.4875 72.1625 75.3 72.3C84.8 73.95 92 82.2125 92 92.1625C92 94.2875 90.275 96 88.1625 96H39.8375C37.725 96 36 94.275 36 92.1625C36 82.2125 43.2 73.95 52.6875 72.3C53.5125 72.1625 54.3 72.6 54.675 73.35L60.15 84.3L62.1375 76.9ZM50.8125 79.0375C46.2875 80.8875 42.925 85.025 42.1625 90H56.2875L50.8125 79.0375ZM71.7125 90H85.8375C85.075 85.025 81.7125 80.8875 77.1875 79.0375L71.7125 90Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3841">
          <rect
            width="56"
            height="64"
            fill="white"
            transform="translate(36 32)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
