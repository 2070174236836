import React from "react";
import { Box, ColorSwatch, Flex, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import { PieChart, Pie, Cell, ResponsiveContainer, PieLabel } from "recharts";

const data = [
  {
    name: "Data Analysis",
    value: 1,
    color: "#de57cc",
    text: "With your interest in transitioning to a role in Data Analysis & Science, it’s paramount to master the use and interpretation of data. Skills in data analytics are significantly in demand and will remain so as industries continue to be driven by data-driven decision-making.",
  },
  {
    name: "Programming (Python/R)",
    value: 1,
    color: "#a855f7",
    text: "Given the technological advancements in data analysis, proficiency in programming languages such as Python or R is important. These languages are prominent in data science, analytics, and automating tasks.",
  },
  {
    name: "Advanced Excel/SQL",
    value: 1,
    color: "#6f40e2",
    text: "For a Data Analysis role, advanced knowledge of Excel and SQL is crucial. These tools are fundamental for handling, querying, and analyzing data efficiently and effectively.",
  },
  {
    name: "Machine Learning/AI Basics",
    value: 1,
    color: "#3a8bd9",
    text: "Concerns about automation suggest a need to understand the technologies that drive it. By gaining a basic understanding of Machine Learning and Artificial Intelligence, you can work alongside emerging technologies, rather than be replaced by them.",
  },
];

const RADIAN = Math.PI / 180;

export const FutureSkills: React.FC = () => {
  const laptop = useMediaQuery("(max-width: 1450px)");
  const matches = useMediaQuery("(max-width: 1100px)");

  const renderLabel: PieLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    text,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <foreignObject
        x={x - (matches ? 50 : 140)}
        y={y - (laptop ? 25 : 120)}
        width="280"
        height="100%"
        color="#fff"
      >
        <Title
          size={matches ? 16 : 24}
          ta="center"
          c="#FFF"
          mb={24}
          w={matches ? 100 : undefined}
          fw={matches ? 200 : undefined}
        >
          {name}
        </Title>
        <Text ta="center" c="#FFF">
          {!laptop && text}
        </Text>
      </foreignObject>
    );
  };

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Skills to Improve to Stay Competitive in the next 5 years
      </Title>
      <Text mb={24} c="dimmed">
        Explore vital counteractive skills and abilities essential for career
        competitiveness, ensuring continued professional growth and success.
      </Text>
      <ResponsiveContainer
        width="100%"
        height={matches ? 314 : laptop ? 800 : 1056}
      >
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={matches ? 139 : laptop ? 360 : 528}
            innerRadius={matches ? 25 : laptop ? 100 : 200}
            label={renderLabel}
            labelLine={false}
            dataKey="value"
            startAngle={45}
            endAngle={450}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {laptop &&
        data.map((item) => (
          <Flex gap={5} mt={16}>
            <ColorSwatch color={item.color} size={14} radius={2} mt={5} />
            <Text>
              <b>{item.name}</b>: {item.text}
            </Text>
          </Flex>
        ))}
      <LikeResponse />
    </Paper>
  );
};
