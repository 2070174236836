export const StarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6292 3.20215C13.4221 2.77246 12.9846 2.49902 12.5042 2.49902C12.0237 2.49902 11.5901 2.77246 11.3792 3.20215L8.86744 8.37012L3.25806 9.19824C2.78931 9.26855 2.39869 9.59668 2.25416 10.0459C2.10963 10.4951 2.22681 10.9912 2.56275 11.3232L6.63306 15.3506L5.67213 21.042C5.594 21.5107 5.78931 21.9873 6.17603 22.2646C6.56275 22.542 7.07447 22.5771 7.49635 22.3545L12.5081 19.6787L17.5198 22.3545C17.9417 22.5771 18.4534 22.5459 18.8401 22.2646C19.2268 21.9834 19.4221 21.5107 19.344 21.042L18.3792 15.3506L22.4495 11.3232C22.7854 10.9912 22.9065 10.4951 22.7581 10.0459C22.6096 9.59668 22.2229 9.26855 21.7542 9.19824L16.1409 8.37012L13.6292 3.20215Z"
        fill="black"
      />
    </svg>
  );
};
