export const ProfileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 10.625C13.3288 10.625 14.1237 10.2958 14.7097 9.70971C15.2958 9.12366 15.625 8.3288 15.625 7.5C15.625 6.6712 15.2958 5.87634 14.7097 5.29029C14.1237 4.70424 13.3288 4.375 12.5 4.375C11.6712 4.375 10.8763 4.70424 10.2903 5.29029C9.70424 5.87634 9.375 6.6712 9.375 7.5C9.375 8.3288 9.70424 9.12366 10.2903 9.70971C10.8763 10.2958 11.6712 10.625 12.5 10.625ZM17.5 7.5C17.5 8.82608 16.9732 10.0979 16.0355 11.0355C15.0979 11.9732 13.8261 12.5 12.5 12.5C11.1739 12.5 9.90215 11.9732 8.96447 11.0355C8.02678 10.0979 7.5 8.82608 7.5 7.5C7.5 6.17392 8.02678 4.90215 8.96447 3.96447C9.90215 3.02678 11.1739 2.5 12.5 2.5C13.8261 2.5 15.0979 3.02678 16.0355 3.96447C16.9732 4.90215 17.5 6.17392 17.5 7.5ZM5.63672 20.625H19.3633C19.2422 18.7773 18.0469 17.2148 16.3945 16.5703L14.4531 19C13.4531 20.25 11.5508 20.25 10.5469 19L8.60547 16.5703C6.95313 17.2148 5.75781 18.7773 5.63672 20.625ZM8.95703 14.5156C9.17578 14.4688 9.39844 14.5586 9.53906 14.7344L12.0156 17.8281C12.2656 18.1406 12.7422 18.1406 12.9922 17.8281L15.4688 14.7344C15.6094 14.5586 15.832 14.4727 16.0508 14.5156C19.0195 15.1406 21.25 17.7773 21.25 20.9375V21.25C21.25 21.9414 20.6914 22.5 20 22.5H5C4.30859 22.5 3.75 21.9414 3.75 21.25V20.9375C3.75 17.7773 5.98047 15.1406 8.95703 14.5156Z"
        fill="black"
      />
    </svg>
  );
};
