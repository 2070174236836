// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  border-right: 1px solid #d6d6d6; }
  .sidebar .mantine-NavLink-label {
    font-size: 16px;
    z-index: 1; }
  .sidebar .mantine-NavLink-root {
    padding: 16px 24px; }
    .sidebar .mantine-NavLink-root[data-active="true"] {
      background: #6f40e21a;
      border-left: 3px solid var(--Purple, #6f40e2);
      font-weight: bold;
      color: #000; }
`, "",{"version":3,"sources":["webpack://./src/entities/components/main-sidebar/MainSidebar.scss"],"names":[],"mappings":"AAAA;EACI,+BAA+B,EAAA;EADnC;IAII,eAAe;IACf,UAAU,EAAA;EALd;IASI,kBAAkB,EAAA;IATtB;MAYM,qBAAqB;MACrB,6CAA6C;MAC7C,iBAAiB;MACjB,WAAW,EAAA","sourcesContent":[".sidebar {\n    border-right: 1px solid #d6d6d6;\n\n  .mantine-NavLink-label {\n    font-size: 16px;\n    z-index: 1;\n  }\n\n  .mantine-NavLink-root {\n    padding: 16px 24px;\n\n    &[data-active=\"true\"] {\n      background: #6f40e21a;\n      border-left: 3px solid var(--Purple, #6f40e2);\n      font-weight: bold;\n      color: #000;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
