import { FC } from "react";
import { Button, Center, Stack, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";

interface Props {
  onUpload: () => void;
}

export const UploadCVForm: FC<Props> = ({ onUpload }) => {
  return (
    <Stack align="stretch" justify="center" gap="34px">
      <Dropzone onDrop={() => {}}>
        <Center h={50}>
          <Text c="dimmed" size="md" fw={700}>
            Click here or drag file to upload
          </Text>
        </Center>
      </Dropzone>
      <Button fullWidth onClick={onUpload}>
        Submit
      </Button>
    </Stack>
  );
};
