import { Box, Center, Flex, Grid, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  EditIcon,
  EmailIcon,
  FacebookIcon,
  LinkedInIcon,
  LocationIcon,
  PhoneIcon,
  TwitterIcon,
} from "../../shared/svg";
import { ProgressAvatar } from "../../shared/components/ProgressAvatar";
import avatar from "../../shared/images/Avatar.png";
import "./Profile.scss";
import { Link } from "react-router-dom";

const contactItems = [
  {
    icon: EmailIcon,
    label: "Email",
    value: "robbie@gmail.com",
    href: "mailto:robbie@gmail.com",
  },
  {
    icon: PhoneIcon,
    label: "Phone",
    value: "(123) 456-78900",
    href: "tel:+112345678900",
  },
  { icon: LocationIcon, label: "Location", value: "Kyiv, Ukraine" },
  {
    icon: LinkedInIcon,
    label: "LinkedIn",
    value: "https://www.linkedin.com/in/robbie-winterbottom/",
    href: "https://www.linkedin.com/in/robbie-winterbottom/",
  },
  {
    icon: TwitterIcon,
    label: "Twitter",
    value: "https://twitter.com/robbiewinterbottom/",
    href: "https://twitter.com/robbiewinterbottom/",
  },
  {
    icon: FacebookIcon,
    label: "Facebook",
    value: "https://www.facebook.com/robbie.winterbottom",
    href: "https://www.facebook.com/robbie.winterbottom",
  },
];

export const ContactInfo = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");

  const profile = (
    <Flex align="end" justify="center" direction="column">
      <ProgressAvatar imageUrl={avatar} progress={75} />
      <Text mt={16}>Your profile strength</Text>
      <Title mt={8} size={36} fw={900} ta="center" mr="35px">
        75%
      </Title>
    </Flex>
  );

  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Grid>
        {laptop && <Center m="auto" mb={40}>{profile}</Center>}
        <Grid.Col span={laptop ? 12 : 8}>
          <Title size={36} lh="50px">
            Robbie Winterbottom
          </Title>
          <Text size="24px" lh="33px">
            Marketing manager
          </Text>
          <Box mt={40} className="contact-info">
            {contactItems.map(({ icon: Icon, label, value, href }, index) => (
              <Box key={index} className="contact-item">
                <Flex gap={16}>
                  <div className="icon">
                    <Icon />
                  </div>
                  <Text>{label}</Text>
                </Flex>
                {href ? <Link to={href}>{value}</Link> : <Text>{value}</Text>}
              </Box>
            ))}
          </Box>
        </Grid.Col>
        <Grid.Col span={4}>{!laptop && profile}</Grid.Col>
      </Grid>
    </Paper>
  );
};
