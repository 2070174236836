export const ProjectManagmentIcon = () => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_724_3896)">
        <path
          d="M20.1528 9.57367C21.3098 8.28667 21.2058 6.32367 19.9188 5.16667C18.6318 4.00967 16.6688 4.11367 15.5118 5.40067L8.36184 13.3437L5.48884 10.4707C4.26684 9.26167 2.29084 9.26167 1.06884 10.4707C-0.153156 11.6797 -0.153156 13.6687 1.06884 14.8777L6.26884 20.0777C6.87984 20.6887 7.69884 21.0137 8.55684 20.9877C9.41484 20.9617 10.2208 20.5977 10.7928 19.9607L20.1528 9.56067V9.57367ZM20.1528 30.3737C21.3098 29.0867 21.2058 27.1237 19.9188 25.9667C18.6318 24.8097 16.6688 24.9137 15.5118 26.2007L8.36184 34.1437L5.48884 31.2707C4.26684 30.0487 2.29084 30.0487 1.08184 31.2707C-0.127156 32.4927 -0.140156 34.4687 1.08184 35.6777L6.28184 40.8777C6.89284 41.4887 7.71184 41.8137 8.56984 41.7877C9.42784 41.7617 10.2338 41.3977 10.8058 40.7607L20.1658 30.3607L20.1528 30.3737ZM28.2388 15.8007H63.5988C65.3278 15.8007 66.7188 14.4097 66.7188 12.6807C66.7188 10.9517 65.3278 9.56067 63.5988 9.56067H28.2388C26.5098 9.56067 25.1188 10.9517 25.1188 12.6807C25.1188 14.4097 26.5098 15.8007 28.2388 15.8007ZM25.1188 33.4807C25.1188 35.2097 26.5098 36.6007 28.2388 36.6007H63.5988C65.3278 36.6007 66.7188 35.2097 66.7188 33.4807C66.7188 31.7517 65.3278 30.3607 63.5988 30.3607H28.2388C26.5098 30.3607 25.1188 31.7517 25.1188 33.4807ZM20.9588 54.2807C20.9588 56.0097 22.3498 57.4007 24.0788 57.4007H63.5988C65.3278 57.4007 66.7188 56.0097 66.7188 54.2807C66.7188 52.5517 65.3278 51.1607 63.5988 51.1607H24.0788C22.3498 51.1607 20.9588 52.5517 20.9588 54.2807ZM12.6388 54.2807C12.6388 53.1774 12.2006 52.1193 11.4204 51.3391C10.6403 50.559 9.58214 50.1207 8.47884 50.1207C7.37554 50.1207 6.31743 50.559 5.53728 51.3391C4.75713 52.1193 4.31884 53.1774 4.31884 54.2807C4.31884 55.384 4.75713 56.4421 5.53728 57.2222C6.31743 58.0024 7.37554 58.4407 8.47884 58.4407C9.58214 58.4407 10.6403 58.0024 11.4204 57.2222C12.2006 56.4421 12.6388 55.384 12.6388 54.2807Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3896">
          <rect
            width="66.56"
            height="66.56"
            fill="white"
            transform="translate(0.160156 0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
