import { FC, ReactNode } from "react";
import { Center, Container } from "@mantine/core";
import { Footer } from "../entities/components/Footer";
import { TermsHeader } from "../entities/components/header/TermsHeader";

interface Props {
  children: ReactNode;
}

export const TermsLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <TermsHeader />
      <Center mt={50} mb={50}>
        <Container size="sm" w={656}>
          <center>{children}</center>
        </Container>
      </Center>
      <Footer />
    </>
  );
};
