import React from "react";
import { ColorSwatch, Flex, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import "../Report.scss";

const data = [
  {
    name: "Data Analysis and Statistical Techniques",
    text: "Learning statistical analysis methods is critical as they are the foundation for analyzing data sets and deriving actionable insights, which is at the heart of data science roles.",
    color: "#3A8BD9",
  },
  {
    name: "Programming Languages (e.g., Python/R)",
    text: "Proficiency in programming languages like Python or R is necessary for handling complex data analysis tasks and creating reproducible analyses and visualizations.",
    color: "#6F40E2",
  },
  {
    name: "Data Visualization and Communication",
    text: "As businesses increasingly adopt automated data entry and processing systems, the need for manual data entry is decreasing. Advanced software solutions and AI-driven tools are streamlining these processes, making manual data entry less relevant.",
    color: "#A64DD7",
  },
  {
    name: "Machine Learning Fundamentals",
    text: "Understanding machine learning concepts is increasingly important in data science to automate data analysis processes and develop predictive models that can provide forward-looking insights.",
    color: "#DE57CC",
  },
];

export const DataAnalysisSkills: React.FC = () => {
  const matches = useMediaQuery("(max-width: 1100px)");
  const mobile = useMediaQuery("(max-width: 850px)");

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Skills to Master for New Role in Data Analysis & Science
      </Title>
      <Text mb={24} c="dimmed">
        Discover key skills to master for climbing the career ladder in your
        field, focusing on strategic, technical, and interpersonal competencies
        essential for higher-level positions.
      </Text>
      <Flex c="#fff" gap={matches ? 22 : 64} pos="relative">
        {!matches && (
          <>
            <div className="v-line" />
            <div className="h-line h-line-1" />
            <div className="h-line h-line-2" />
            <div className="h-line h-line-3" />
            <div className="h-line h-line-4" />
          </>
        )}
        <Flex direction="column" gap={matches ? 22 : 40} w="50%">
          <Paper
            radius="16px"
            p={matches ? 12 : 24}
            bg="#3A8BD9"
            mih={mobile ? 128 : 226}
            style={{ zIndex: 3 }}
          >
            <Title size={mobile ? 16 : 24} mb={16}>
              Data Analysis and Statistical Techniques
            </Title>
            {!mobile && (
              <Text>
                Learning statistical analysis methods is critical as they are
                the foundation for analyzing data sets and deriving actionable
                insights, which is at the heart of data science roles.
              </Text>
            )}
          </Paper>
          <Paper
            radius="16px"
            p={matches ? 12 : 24}
            bg="#A64DD7"
            mih={mobile ? 128 : 226}
            style={{ zIndex: 2 }}
          >
            <Title size={mobile ? 16 : 24} mb={16}>
              Data Visualization and Communication
            </Title>
            {!mobile && (
              <Text>
                The ability to create clear, impactful visual representations of
                data and effectively communicate findings to stakeholders can
                make the difference in conveying complex results to
                non-technical audiences.
              </Text>
            )}
          </Paper>
        </Flex>
        <Flex
          direction="column"
          gap={matches ? 22 : 40}
          mt={mobile ? 0 : "12.5%"}
          mb={mobile ? 24 : undefined}
          w="50%"
        >
          <Paper
            radius="16px"
            p={matches ? 12 : 24}
            bg="#6F40E2"
            mih={mobile ? 128 : 226}
            style={{ zIndex: 1 }}
          >
            <Title size={mobile ? 16 : 24} mb={16}>
              Programming Languages (e.g., Python/R)
            </Title>
            {!mobile && (
              <Text>
                Proficiency in programming languages like Python or R is
                necessary for handling complex data analysis tasks and creating
                reproducible analyses and visualizations.
              </Text>
            )}
          </Paper>
          <Paper
            radius="16px"
            p={matches ? 12 : 24}
            bg="#DE57CC"
            mih={mobile ? 128 : 226}
          >
            <Title size={mobile ? 16 : 24} mb={16}>
              Data Analysis and Statistical Techniques
            </Title>
            {!mobile && (
              <Text>
                Learning statistical analysis methods is critical as they are
                the foundation for analyzing data sets and deriving actionable
                insights, which is at the heart of data science roles.
              </Text>
            )}
          </Paper>
        </Flex>
      </Flex>
      {mobile &&
        data.map((item) => (
          <Flex gap={5} mt={16}>
            <ColorSwatch color={item.color} size={14} radius={2} mt={5} />
            <Text>
              <b>{item.name}</b>: {item.text}
            </Text>
          </Flex>
        ))}
      <LikeResponse />
    </Paper>
  );
};
