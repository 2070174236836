// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-Stepper-step .mantine-Stepper-stepLabel {
  font-weight: bold;
  color: #d6d6d6; }
  @media (max-width: 520px) {
    .mantine-Stepper-step .mantine-Stepper-stepLabel {
      font-size: 10px; } }

.mantine-Stepper-step[data-progress="true"] .mantine-Stepper-stepLabel {
  color: #000; }

.mantine-Stepper-step .mantine-Stepper-stepIcon {
  background: none;
  border-color: #d6d6d6; }
  .mantine-Stepper-step .mantine-Stepper-stepIcon[data-progress="true"] {
    border-color: #6f40e2; }
    .mantine-Stepper-step .mantine-Stepper-stepIcon[data-progress="true"] path {
      fill: #6f40e2; }
  .mantine-Stepper-step .mantine-Stepper-stepIcon[data-completed="true"] {
    background: #6f40e2;
    border-color: #6f40e2; }
    .mantine-Stepper-step .mantine-Stepper-stepIcon[data-completed="true"] path {
      fill: #fff; }

@media (max-width: 520px) {
  .mantine-Stepper-step .mantine-Stepper-stepBody {
    margin-inline-start: 4px; } }

.mantine-Stepper-separator {
  background-color: #d6d6d6 !important; }
  @media (max-width: 520px) {
    .mantine-Stepper-separator {
      margin-inline: 4px !important; } }
`, "",{"version":3,"sources":["webpack://./src/entities/components/auth-steps/AuthSteps.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB;EACjB,cAAc,EAAA;EAEd;IALJ;MAMM,eAAe,EAAA,EAElB;;AARH;EAYM,WAAW,EAAA;;AAZjB;EAiBI,gBAAgB;EAChB,qBAAqB,EAAA;EAlBzB;IAqBM,qBAAqB,EAAA;IArB3B;MAwBQ,aAAa,EAAA;EAxBrB;IA6BM,mBAAmB;IACnB,qBAAqB,EAAA;IA9B3B;MAiCQ,UAAU,EAAA;;AAMd;EAvCJ;IAwCM,wBAAwB,EAAA,EAE3B;;AAGH;EACE,oCAAoC,EAAA;EAEpC;IAHF;MAII,6BAA6B,EAAA,EAEhC","sourcesContent":[".mantine-Stepper-step {\n  .mantine-Stepper-stepLabel {\n    font-weight: bold;\n    color: #d6d6d6;\n\n    @media (max-width: 520px) {\n      font-size: 10px;\n    }\n  }\n\n  &[data-progress=\"true\"] {\n    .mantine-Stepper-stepLabel {\n      color: #000;\n    }\n  }\n\n  .mantine-Stepper-stepIcon {\n    background: none;\n    border-color: #d6d6d6;\n\n    &[data-progress=\"true\"] {\n      border-color: #6f40e2;\n\n      path {\n        fill: #6f40e2;\n      }\n    }\n\n    &[data-completed=\"true\"] {\n      background: #6f40e2;\n      border-color: #6f40e2;\n\n      path {\n        fill: #fff;\n      }\n    }\n  }\n\n  .mantine-Stepper-stepBody {\n    @media (max-width: 520px) {\n      margin-inline-start: 4px;\n    }\n  }\n}\n\n.mantine-Stepper-separator {\n  background-color: #d6d6d6 !important;\n\n  @media (max-width: 520px) {\n    margin-inline: 4px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
