// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  border-bottom: 1px solid #d6d6d6;
  background: #fff;
  z-index: 10; }
`, "",{"version":3,"sources":["webpack://./src/entities/components/header/Header.scss"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,WAAW,EAAA","sourcesContent":[".header {\n  border-bottom: 1px solid #d6d6d6;\n  background: #fff;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
