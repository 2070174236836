import { useEffect } from 'react';

const useGlobalInputFocus = () => {
  useEffect(() => {
    let firstFocus = true;

    const handleBodyFocus = () => {
      if (firstFocus) {
        document.body.classList.add('has-focused');
        firstFocus = false;
      }
    };

    const addClassIfNotEmpty = (input: HTMLInputElement, className: string) => {
      const wrapper = input.closest('.mantine-InputWrapper-root');
      if (wrapper) {
        wrapper.classList.toggle(className, input.value.trim() !== '');
      }
    };

    const handleFocus = (event: FocusEvent) => {
      handleBodyFocus();

      const input = event.target as HTMLInputElement;
      const wrapper = input.closest('.mantine-InputWrapper-root');
      if (wrapper) {
        wrapper.classList.add('focused');
        addClassIfNotEmpty(input, 'has-text');
      }
    };

    const handleBlur = (event: FocusEvent) => {
      const input = event.target as HTMLInputElement;
      const wrapper = input.closest('.mantine-InputWrapper-root');
      if (wrapper) {
        wrapper.classList.remove('focused');
      }
    };

    const handleInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      addClassIfNotEmpty(input, 'has-text');
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);
    document.addEventListener('input', handleInput);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
      document.removeEventListener('input', handleInput);
    };
  }, []);

  return null;
};

export default useGlobalInputFocus;
