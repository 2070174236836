import { FC, ReactNode } from "react";
import { Title, BackgroundImage, Flex } from "@mantine/core";
import ReportBackground from "../../../shared/images/Report-bacground.png";
import { PDFPage } from "./PDFPage";

interface Props {
  title: string | ReactNode;
}

export const TitlePage: FC<Props> = ({ title }) => (
  <PDFPage>
    <BackgroundImage src={ReportBackground} h="100%">
      <Flex align="center" justify="center" h="100%">
        <Title size={72} ta="center" c="#fff">
          {title}
        </Title>
      </Flex>
    </BackgroundImage>
  </PDFPage>
);
