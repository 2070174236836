export const LocationIcon = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99967 0.333313C2.41967 0.333313 0.333008 2.41998 0.333008 4.99998C0.333008 8.49998 4.99967 13.6666 4.99967 13.6666C4.99967 13.6666 9.66634 8.49998 9.66634 4.99998C9.66634 2.41998 7.57967 0.333313 4.99967 0.333313ZM1.66634 4.99998C1.66634 3.15998 3.15967 1.66665 4.99967 1.66665C6.83967 1.66665 8.33301 3.15998 8.33301 4.99998C8.33301 6.91998 6.41301 9.79331 4.99967 11.5866C3.61301 9.80665 1.66634 6.89998 1.66634 4.99998Z"
        fill="black"
      />
      <path
        d="M4.99967 6.66665C5.92015 6.66665 6.66634 5.92045 6.66634 4.99998C6.66634 4.07951 5.92015 3.33331 4.99967 3.33331C4.0792 3.33331 3.33301 4.07951 3.33301 4.99998C3.33301 5.92045 4.0792 6.66665 4.99967 6.66665Z"
        fill="black"
      />
    </svg>
  );
};
