import { useNavigate } from "react-router";
import { Button, Group } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Logo } from "../../../shared/svg";
import "./Header.scss";

export const TermsHeader = () => {
  const matches = useMediaQuery("(max-width: 485px)");
  const navigate = useNavigate();

  return (
    <Group
      justify={matches ? "center" : "space-between"}
      align="center"
      className="header"
      px="24px"
      py="16px"
    >
      <Logo width={190} height={40} />
      {!matches && (
        <Group>
          <Button variant="outline" onClick={() => navigate("/login/sing-in")}>
            Log In
          </Button>
          <Button onClick={() => navigate("/auth/sing-up")}>Sing Up</Button>
        </Group>
      )}
    </Group>
  );
};
