import { FC } from "react";
import { Stack, Title } from "@mantine/core";
import { UploadCVForm } from "../../features/upload-cv/UploadCVForm";

interface Props {
  onUpload: () => void;
}

export const UploadCVWiget: FC<Props> = ({ onUpload }) => {
  return (
    <Stack align="stretch" justify="center" gap="34px">
      <Title size={36}>Upload your CV</Title>
      <UploadCVForm onUpload={onUpload} />
    </Stack>
  );
};
