export const MarketingIcon = () => {
  return (
    <svg
      width="76"
      height="67"
      viewBox="0 0 76 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_724_3892)">
        <path
          d="M72.4807 4.20093C70.7517 4.20093 69.3607 5.59193 69.3607 7.32093V8.06193L6.9607 25.0789V23.9609C6.9607 22.2319 5.5697 20.8409 3.8407 20.8409C2.1117 20.8409 0.720703 22.2319 0.720703 23.9609V26.7819V29.1609V37.4809V39.8599V42.6809C0.720703 44.4099 2.1117 45.8009 3.8407 45.8009C5.5697 45.8009 6.9607 44.4099 6.9607 42.6809V41.5629L22.2487 45.7359C21.7807 47.0619 21.5207 48.4789 21.5207 49.9609C21.5207 56.8509 27.1107 62.4409 34.0007 62.4409C40.0977 62.4409 45.1807 58.0729 46.2597 52.2879L69.3607 58.5799V59.3209C69.3607 61.0499 70.7517 62.4409 72.4807 62.4409C74.2097 62.4409 75.6007 61.0499 75.6007 59.3209V56.2009V10.4409V7.32093C75.6007 5.59193 74.2097 4.20093 72.4807 4.20093ZM69.3607 52.1189L6.9607 35.1019V31.5399L69.3607 14.5229V52.1189ZM27.7607 49.9609C27.7607 49.0379 27.9557 48.1669 28.3197 47.3869L40.2147 50.6239C39.8767 53.7569 37.2247 56.1879 34.0137 56.1879C30.5687 56.1879 27.7737 53.3929 27.7737 49.9479L27.7607 49.9609Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3892">
          <rect
            width="74.88"
            height="66.56"
            fill="white"
            transform="translate(0.719727 0.0400391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
