import { Button, Flex, Grid, Text, Title } from "@mantine/core";
import { PDFPage } from "./PDFPage";
import { CourseCard } from "../../../entities/components/CourseCard";
import Course1 from "../../../shared/images/Course-1.png";
import Course2 from "../../../shared/images/Course-2.png";
import Course3 from "../../../shared/images/Course-3.png";
import Course4 from "../../../shared/images/Course-4.png";

export const EssentialSkillCoursesPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={16}>
        Relevant Courses to Master Your Skills
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        The list of educational courses tailored to enhance your expertise and
        advance your professional capabilities in key areas.
      </Text>
      <Grid gutter={32}>
        <Grid.Col span={6}>
          <CourseCard
            image={Course1}
            platform="Coursera"
            title="Google Data Analytics"
            instructor="Google"
            rating={4.9}
            pdf
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CourseCard
            image={Course2}
            platform="edX"
            title="MIT SA+P: Data Science in Real Estate"
            instructor="MIT"
            rating="Unrated"
            pdf
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CourseCard
            image={Course3}
            platform="Udemy"
            title="Data Analysis | SQL, Tableau, Power BI & Excel | Real Projects"
            instructor="Graeme Gordon"
            rating={4.4}
            pdf
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CourseCard
            image={Course4}
            platform="Udemy"
            title="Complete Introduction to Business Data Analysis"
            instructor="Ian Littlejohn"
            rating={4.6}
            pdf
          />
        </Grid.Col>
      </Grid>
      <Flex justify="center" mt={48}>
        <Button
          color="#4F2AA9"
          variant="outline"
          size="96px"
          radius={16}
          py={29}
          px={64}
          style={{ borderWidth: "4px" }}
        >
          <Text fw="bold" size="28px">
            View all recommended courses
          </Text>
        </Button>
      </Flex>
    </PDFPage>
  );
};
