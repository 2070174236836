// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
  display: flex !important; }
  @media (max-width: 1100px) {
    .calendar {
      overflow-x: auto; } }
  .calendar .goal-item {
    border-bottom: 1px solid #d6d6d6; }
  .calendar .goal-input {
    border-bottom: 1px solid #d6d6d6; }
    .calendar .goal-input .goal-icon {
      font-size: 32px;
      color: #737373; }
    .calendar .goal-input input {
      border: none;
      outline: none;
      background: none;
      padding: 0;
      margin: 0;
      box-shadow: none;
      font: inherit; }
  @media (min-width: 1100px) {
    .calendar .days {
      overflow-x: auto; } }
  .calendar .days .row {
    display: grid; }
  .calendar .day {
    border-left: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6; }
`, "",{"version":3,"sources":["webpack://./src/widgets/development-plan/Calendar.scss"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAA;EAExB;IAHF;MAII,gBAAgB,EAAA,EAwCnB;EA5CD;IAQI,gCAAgC,EAAA;EARpC;IAYI,gCAAgC,EAAA;IAZpC;MAeM,eAAe;MACf,cAAc,EAAA;IAhBpB;MAoBM,YAAY;MACZ,aAAa;MACb,gBAAgB;MAChB,UAAU;MACV,SAAS;MACT,gBAAgB;MAChB,aAAa,EAAA;EAKf;IA/BJ;MAgCM,gBAAgB,EAAA,EAMnB;EAtCH;IAoCM,aAAa,EAAA;EApCnB;IAyCI,8BAA8B;IAC9B,gCAAgC,EAAA","sourcesContent":[".calendar {\n  display: flex !important;\n\n  @media (max-width: 1100px) {\n    overflow-x: auto;\n  }\n\n  .goal-item {\n    border-bottom: 1px solid #d6d6d6;\n  }\n\n  .goal-input {\n    border-bottom: 1px solid #d6d6d6;\n\n    .goal-icon {\n      font-size: 32px;\n      color: #737373;\n    }\n\n    input {\n      border: none;\n      outline: none;\n      background: none;\n      padding: 0;\n      margin: 0;\n      box-shadow: none;\n      font: inherit;\n    }\n  }\n\n  .days {\n    @media (min-width: 1100px) {\n      overflow-x: auto;\n    }\n\n    .row {\n      display: grid;\n    }\n  }\n\n  .day {\n    border-left: 1px solid #d6d6d6;\n    border-bottom: 1px solid #d6d6d6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
