export const EmailManagerSvg = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="128" height="128" rx="64" fill="#05D8D2" />
      <g clip-path="url(#clip0_724_3833)">
        <path
          d="M38 38H78V44H84V38C84 34.6875 81.3125 32 78 32H38C34.6875 32 32 34.6875 32 38V56H38V38ZM30 66H66V66.8625L48.0125 80.175H48H47.9875L30 66.8625V66ZM30 74.325L44.4125 85C45.45 85.775 46.7125 86.1875 48 86.1875C49.2875 86.1875 50.55 85.775 51.5875 85L66 74.325V90H30V74.325ZM24 66V90C24 93.3125 26.6875 96 30 96H66C69.3125 96 72 93.3125 72 90V66C72 62.6875 69.3125 60 66 60H30C26.6875 60 24 62.6875 24 66ZM58 56V54H98V78H76V84H98C101.312 84 104 81.3125 104 78V54C104 50.6875 101.312 48 98 48H58C54.6875 48 52 50.6875 52 54V56H58ZM88 58C86.9 58 86 58.9 86 60V64C86 65.1 86.9 66 88 66H92C93.1 66 94 65.1 94 64V60C94 58.9 93.1 58 92 58H88Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3833">
          <rect
            width="80"
            height="64"
            fill="white"
            transform="translate(24 32)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
