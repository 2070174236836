export const FacebookIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6663 7.01664C13.6663 3.33664 10.6797 0.349976 6.99967 0.349976C3.31967 0.349976 0.333008 3.33664 0.333008 7.01664C0.333008 10.2433 2.62634 12.93 5.66634 13.55V9.01664H4.33301V7.01664H5.66634V5.34998C5.66634 4.06331 6.71301 3.01664 7.99967 3.01664H9.66634V5.01664H8.33301C7.96634 5.01664 7.66634 5.31664 7.66634 5.68331V7.01664H9.66634V9.01664H7.66634V13.65C11.033 13.3166 13.6663 10.4766 13.6663 7.01664Z"
        fill="black"
      />
    </svg>
  );
};
