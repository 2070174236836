import { ColorSwatch, Flex, Paper, Text, Title } from "@mantine/core";
import {
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useMediaQuery } from "@mantine/hooks";
import { LikeResponse } from "../../../entities/components/LikeResponse";

const getRandomValue = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const data = [
  {
    year: 2018,
    value1: getRandomValue(0, 500),
    value2: getRandomValue(0, 500),
    value3: getRandomValue(0, 500),
    value4: getRandomValue(0, 500),
    value5: getRandomValue(0, 500),
  },
  {
    year: 2019,
    value1: getRandomValue(0, 600),
    value2: getRandomValue(0, 600),
    value3: getRandomValue(0, 600),
    value4: getRandomValue(0, 600),
    value5: getRandomValue(0, 600),
  },
  {
    year: 2020,
    value1: getRandomValue(0, 700),
    value2: getRandomValue(0, 700),
    value3: getRandomValue(0, 700),
    value4: getRandomValue(0, 700),
    value5: getRandomValue(0, 700),
  },
  {
    year: 2021,
    value1: getRandomValue(0, 1000),
    value2: getRandomValue(0, 1000),
    value3: getRandomValue(0, 1000),
    value4: getRandomValue(0, 1000),
    value5: getRandomValue(0, 1000),
  },
  {
    year: 2022,
    value1: getRandomValue(0, 2000),
    value2: getRandomValue(0, 2000),
    value3: getRandomValue(0, 2000),
    value4: getRandomValue(0, 2000),
    value5: getRandomValue(0, 2000),
  },
  {
    year: 2023,
    value1: getRandomValue(1000, 3000),
    value2: getRandomValue(1000, 3000),
    value3: getRandomValue(1000, 3000),
    value4: getRandomValue(1000, 3000),
    value5: getRandomValue(1000, 3000),
  },
  {
    year: 2024,
    value1: getRandomValue(1000, 5000),
    value2: getRandomValue(1000, 5000),
    value3: getRandomValue(1000, 5000),
    value4: getRandomValue(1000, 5000),
    value5: getRandomValue(1000, 5000),
  },
];

export const TrendingSkills = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Trending Skills
      </Title>
      <Text mb={24} c="dimmed">
        Skills relevant to your experience by their demand measured with the
        number of mentions in job postings.
      </Text>
      <ResponsiveContainer width="100%" height={matches ? 488 : 1000}>
        <AreaChart
          data={data}
          margin={{ left: -15 }}
        >
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3B8CD9" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3B8CD933" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#DE56CC" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#DE56CC33" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#05D8D2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#05D8D233" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6E3FE3" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#6E3FE333" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#A64DD6" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#A64DD633" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis domain={[0, 5000]} ticks={[1000, 2000, 3000, 4000, 5000]} />
          <Tooltip />
          <Area
            type="linear"
            dataKey="value1"
            stroke="#3A8BD9"
            strokeWidth={3}
            fill="url(#color1)"
            fillOpacity={1}
          />
          <Area
            type="linear"
            dataKey="value2"
            stroke="#DE57CC"
            strokeWidth={3}
            fill="url(#color2)"
            fillOpacity={1}
          />
          <Area
            type="linear"
            dataKey="value3"
            stroke="#05D8D2"
            strokeWidth={3}
            fill="url(#color3)"
            fillOpacity={1}
          />
          <Area
            type="linear"
            dataKey="value4"
            stroke="#6F40E2"
            strokeWidth={3}
            fill="url(#color4)"
            fillOpacity={1}
          />
          <Area
            type="linear"
            dataKey="value5"
            stroke="#A64DD7"
            strokeWidth={3}
            fill="url(#color5)"
            fillOpacity={1}
          />
          <Line type="linear" dataKey="value1" stroke="#3A8BD9" />
          <Line type="linear" dataKey="value2" stroke="#DE57CC" />
          <Line type="linear" dataKey="value3" stroke="#05D8D2" />
          <Line type="linear" dataKey="value4" stroke="#6F40E2" />
          <Line type="linear" dataKey="value5" stroke="#A64DD7" />
        </AreaChart>
      </ResponsiveContainer>
      <Flex gap={5} mt={16}>
        <ColorSwatch color="#05D8D2" size={14} radius={2} mt={5} />
        <Text>
          <b>Data Manipulation and Analysis</b>: The ability to process and
          analyze data is crucial as businesses rely on data-driven insights for
          decision making.
        </Text>
      </Flex>
      <Flex gap={5} mt={16}>
        <ColorSwatch color="#3A8BD9" size={14} radius={2} mt={5} />
        <Text>
          <b>Programming with Python or R</b>: These programming languages are
          the backbone of data analysis and are highly sought after for their
          versatility and statistical capabilities.
        </Text>
      </Flex>
      <Flex gap={5} mt={16}>
        <ColorSwatch color="#6F40E2" size={14} radius={2} mt={5} />
        <Text>
          <b>Machine Learning</b>: With the advent of big data, machine learning
          skills are in high demand for building predictive models and
          conducting complex analyses.
        </Text>
      </Flex>
      <Flex gap={5} mt={16}>
        <ColorSwatch color="#A64DD7" size={14} radius={2} mt={5} />
        <Text>
          <b>Data Visualization</b>: Data Visualization: The capacity to convert
          complex data into understandable and compelling visuals is essential
          for communicating insights effectively.
        </Text>
      </Flex>
      <Flex gap={5} mt={16}>
        <ColorSwatch color="#DE57CC" size={14} radius={2} mt={5} />
        <Text>
          <b>SQL Databases</b>: Proficiency with SQL is a fundamental
          requirement for managing databases, performing queries, and handling
          large datasets efficiently.
        </Text>
      </Flex>
      <LikeResponse />
    </Paper>
  );
};
