export const CheckedIcon = () => {
  return (
    <svg
      width="13"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8016 8.76908L4.77225 16.6576L0 11.9691L1.40164 10.5424L4.77225 13.8539L11.4 7.34241L12.8016 8.76908Z"
        fill="#6F40E2"
      />
    </svg>
  );
};
