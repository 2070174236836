export const ToTopArrowIcon = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88397 1.14701C7.88397 0.842806 7.99816 0.551059 8.20143 0.335952C8.40469 0.120846 8.68037 0 8.96783 0H16.9161C17.2036 0 17.4793 0.120846 17.6825 0.335952C17.8858 0.551059 18 0.842806 18 1.14701V9.55844C18 9.86265 17.8858 10.1544 17.6825 10.3695C17.4793 10.5846 17.2036 10.7055 16.9161 10.7055C16.6287 10.7055 16.353 10.5846 16.1497 10.3695C15.9465 10.1544 15.8323 9.86265 15.8323 9.55844V3.91667L7.56893 12.6646C7.36571 12.8793 7.09023 13 6.803 13C6.51578 13 6.2403 12.8793 6.03707 12.6646L0.331253 6.84166C0.227688 6.73591 0.145061 6.60938 0.0881958 6.46947C0.0313301 6.32956 0.00136375 6.17906 4.57764e-05 6.02676C-0.0012722 5.87446 0.026083 5.72341 0.0805187 5.58242C0.134953 5.44143 0.215376 5.31333 0.317095 5.20558C0.418814 5.09784 0.539789 5.01261 0.672966 4.95487C0.806143 4.89713 0.948851 4.86803 1.09277 4.86929C1.23668 4.87054 1.37892 4.90211 1.51118 4.96215C1.64344 5.0222 1.76308 5.10952 1.86311 5.21902L6.803 10.2314L14.3004 2.29403H8.96783C8.68037 2.29403 8.40469 2.17318 8.20143 1.95807C7.99816 1.74297 7.88397 1.45122 7.88397 1.14701Z"
        fill="white"
      />
    </svg>
  );
};
