export const FullRatingMobileIcon = () => {
  return (
    <svg
      width="37"
      height="32"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1146_5827)">
        <path
          d="M20.5561 1.125C20.2248 0.4375 19.5248 0 18.7561 0C17.9873 0 17.2936 0.4375 16.9561 1.125L12.9373 9.39375L3.96232 10.7188C3.21232 10.8313 2.58732 11.3562 2.35607 12.075C2.12482 12.7937 2.31232 13.5875 2.84982 14.1187L9.36232 20.5625L7.82482 29.6688C7.69982 30.4188 8.01232 31.1812 8.63107 31.625C9.24982 32.0687 10.0686 32.125 10.7436 31.7687L18.7623 27.4875L26.7811 31.7687C27.4561 32.125 28.2748 32.075 28.8936 31.625C29.5123 31.175 29.8248 30.4188 29.6998 29.6688L28.1561 20.5625L34.6686 14.1187C35.2061 13.5875 35.3998 12.7937 35.1623 12.075C34.9248 11.3562 34.3061 10.8313 33.5561 10.7188L24.5748 9.39375L20.5561 1.125Z"
          fill="#6F40E2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1146_5827">
          <rect
            width="36"
            height="32"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
