import { FC } from "react";
import { ForgotPasswordWiget } from "../../widgets/forgot-password/ForgotPassword";

interface Props {
  onContinue: () => void;
}

export const ForgotPasswordPage: FC<Props> = ({ onContinue }) => {
  return <ForgotPasswordWiget onContinue={onContinue} />;
};
