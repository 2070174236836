export const EmailIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 3.99999C14.6663 3.26666 14.0663 2.66666 13.333 2.66666H2.66634C1.93301 2.66666 1.33301 3.26666 1.33301 3.99999V12C1.33301 12.7333 1.93301 13.3333 2.66634 13.3333H13.333C14.0663 13.3333 14.6663 12.7333 14.6663 12V3.99999ZM13.333 3.99999L7.99967 7.32666L2.66634 3.99999H13.333ZM13.333 12H2.66634V5.33332L7.99967 8.66666L13.333 5.33332V12Z"
        fill="black"
      />
    </svg>
  );
};
