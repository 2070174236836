import { Grid, Text, Title } from "@mantine/core";
import {
  ScatterChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { PDFPage } from "./PDFPage";

const data = [
  {
    x: 20,
    y: 15,
    r: 41,
    label: "Content Strategy Manager",
    value: "41k",
    color: "#DE57CC",
  },
  {
    x: 24,
    y: 30,
    r: 52,
    label: "Lead Generation Manager",
    value: "85k",
    color: "#A64DD7",
  },
  {
    x: 40,
    y: 44,
    r: 65,
    label: "Digital Marketing Manager",
    value: "90k",
    color: "#6F40E2",
  },
  {
    x: 55,
    y: 65,
    r: 67,
    label: "Marketing Automation Manager",
    value: "95k",
    color: "#05D8D2",
  },
  {
    x: 70,
    y: 45,
    r: 40,
    label: "Senior Email Marketing Specialist",
    value: "80k",
    color: "#3A8BD9",
  },
];

const CustomBubble = (props: any) => {
  const { cx, cy, payload } = props;
  const radius = payload.r;
  const lines = payload.label.split(" ");

  return (
    <>
      <circle cx={cx} cy={cy} r={radius} fill={payload.color} />
      <text
        x={cx}
        y={cy}
        fill="#fff"
        fontSize="28px"
        fontWeight="bold"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {payload.value}
      </text>

      <text
        x={cx + radius + 5}
        y={cy - 10}
        fill="#000"
        fontSize="20px"
        fontWeight="bold"
        textAnchor="start"
      >
        <tspan x={cx + radius + 5} dy="0">
          {lines[0]}
        </tspan>
        <tspan x={cx + radius + 5} dy="27">
          {lines[1]}
        </tspan>
        <tspan x={cx + radius + 5} dy="27">
          {lines[2]}
        </tspan>
      </text>
    </>
  );
};

export const OptimalPositionsPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Your Optimal Positions in Data Analysis & Science
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        Tailored recommendations for positions where you can fully leverage your
        strengths, make significant contributions, and achieve professional
        fulfillment.
      </Text>
      <Text fw="bold" size="20px" mb={10}>
        Job Stability %
      </Text>
      <ResponsiveContainer height={680} width="100%">
        <ScatterChart>
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="X-axis" domain={[0, 100]} />
          <YAxis type="number" dataKey="y" name="Y-axis" domain={[0, 100]} />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Scatter name="Positions" data={data} shape={CustomBubble} />
        </ScatterChart>
      </ResponsiveContainer>
      <Text fw="bold" size="20px" ta="right">
        Match %
      </Text>
      <Grid>
        <Grid.Col span={6}>
          <Text fw="bold" size="28px" lh="42px" mt={24}>
            Senior Email Marketing Specialist
          </Text>
          <Text size="20px" lh="30px">
            <b>Average Salary</b>: $80,000
          </Text>
          <Text size="20px" lh="30px">
            It involves strategic planning and creative content development,
            areas where your insight is essential
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw="bold" size="28px" lh="42px" mt={24}>
            Lead Generation Manager
          </Text>
          <Text size="20px" lh="30px">
            <b>Average Salary</b>: $85,000
          </Text>
          <Text size="20px" lh="30px">
            Strong CRM and data analysis skills equip you well for this role.
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw="bold" size="28px" lh="42px" mt={24}>
            Marketing Automation Manager
          </Text>
          <Text size="20px" lh="30px">
            <b>Average Salary</b>: $95,000
          </Text>
          <Text size="20px" lh="30px">
            Proficiency with automation tools and CRM systems makes you a top
            candidate.
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw="bold" size="28px" lh="42px" mt={24}>
            Content Strategy Manager
          </Text>
          <Text size="20px" lh="30px">
            <b>Average Salary</b>: $82,000
          </Text>
          <Text size="20px" lh="30px">
            Background in content management and marketing makes you a good fit.
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw="bold" size="28px" lh="42px" mt={24}>
            Digital Marketing Manager
          </Text>
          <Text size="20px" lh="30px">
            <b>Average Salary</b>: $90,000
          </Text>
          <Text size="20px" lh="30px">
            Requires creative and analytical skills, including content
            management and SEO/SEM.
          </Text>
        </Grid.Col>
      </Grid>
    </PDFPage>
  );
};
