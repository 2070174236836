import { Flex, Text, Title } from "@mantine/core";
import {
  CivilSectorExperienceSvg,
  DiverseIndustryExposureSvg,
  EmailManagerSvg,
  ShortTermEntrepreneurshipSvg,
  SpecialistToManagerSvg,
} from "../../../shared/svg";
import { PDFPage } from "./PDFPage";
import "../Report.scss";

export const CareerMilestonesPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Your Career Milestones
      </Title>
      <Text mb={80} size="28px" lh="42px" c="dimmed">
        Uncover the pivotal moments and transitions in your career that have
        shaped your unique skill set and professional worth.
      </Text>
      <Flex justify="space-between" align="center" ta="center" className="test">
        <Text fw="bold" size="22px" maw={201}>
          Transition to Email Marketing Manager
        </Text>
        <Text fw="bold" size="22px" maw={201}>
          Specialist to <br /> Manager <br /> in a year
        </Text>
        <Text fw="bold" size="22px" maw={201}>
          Leadership Experience in Civil Sector
        </Text>
      </Flex>
      <Flex
        my={39}
        justify="space-between"
        pos="relative"
        className="career-milestones"
      >
        <div className="timeline" />
        <EmailManagerSvg />
        <SpecialistToManagerSvg />
        <CivilSectorExperienceSvg />
        <ShortTermEntrepreneurshipSvg />
        <DiverseIndustryExposureSvg />
      </Flex>
      <Flex justify="space-around" align="center" ta="center">
        <Text fw="bold" size="22px" maw={201}>
          Short-term Entrepreneurship
        </Text>
        <Text fw="bold" size="22px" maw={201}>
          Diverse Industry Exposure
        </Text>
      </Flex>
      <Text mt={80} size="28px">
        <b>Transition to Email Marketing Manager</b>: Secured a role at
        GigaCloud as an Email Marketing Manager within a year of specializing in
        email marketing.
      </Text>
      <Text mt={80} size="28px">
        <b>Short-term Entrepreneurship</b>: Ventured into entrepreneurship as a
        Content and Marketing Specialist for a brief period, indicating
        versatility and initiative in your career.
      </Text>
      <Text mt={80} size="28px">
        <b>Specialist to Manager in a year</b>: Progressed from a Content
        Management Specialist at Intertop Ukraine to a Retention Marketing
        Manager at Squro in approximately a year, showing rapid career
        advancement.
      </Text>
      <Text mt={80} size="28px">
        <b>Diverse Industry Exposure</b>: Worked in government, IT products, and
        B2B cloud service sectors, reflecting your ability to adapt to varying
        work environments.
      </Text>
      <Text mt={80} size="28px">
        <b>Leadership Experience in Civil Sector</b>: Led an initiative group in Civil Corps, which demonstrates leadership skills and community involvement that can translate well into any sector.
      </Text>
    </PDFPage>
  );
};
