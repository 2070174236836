import { Box, Title, Text } from "@mantine/core";
import { PDFTitleSvg } from "../../../shared/svg";
import { PDFPage } from "./PDFPage";

export const PDFTitle = () => (
  <PDFPage>
    <Box bg="#000" h="100%">
      <Box ml="112px">
        <PDFTitleSvg />
      </Box>
      <Title c="#fff" size={96} ta="center">
        Your Career Opportunities Report
      </Title>
      <Text size="72px" fw="400" c="#fff" ta="center" mt={34}>
        John Doe
      </Text>
    </Box>
  </PDFPage>
);
