import { ColorSwatch, Flex, Paper, Text, Title } from "@mantine/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { PDFPage } from "./PDFPage";

const data = [
  { year: "2019", value1: 123, value2: 148, value3: 176 },
  { year: "2020", value1: 98, value2: 136, value3: 152 },
  { year: "2021", value1: 76, value2: 114, value3: 129 },
  { year: "2022", value1: 61, value2: 91, value3: 105 },
  { year: "2023", value1: 43, value2: 73, value3: 82 },
];

const COLORS = ["#05D8D2", "#3A8BD9", "#6F40E2"];

export const SkillsPhasingOutPDF = () => {
  return (
    <PDFPage padding>
      <Title size={48} mb={8}>
        Skills That Are Phasing Out
      </Title>
      <Text mb={48} size="28px" lh="42px" c="dimmed">
        Skills you have that are lowering in demand based on the number of
        mentions in job postings.
      </Text>
      <ResponsiveContainer height={631} width="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis domain={[0, 200]} ticks={[0, 50, 100, 150, 200]} />
          <Tooltip />
          <Bar dataKey="value1" fill={COLORS[0]} radius={[4, 4, 0, 0]}>
            <LabelList dataKey="value1" position="top" />
          </Bar>
          <Bar dataKey="value2" fill={COLORS[1]} radius={[4, 4, 0, 0]}>
            <LabelList dataKey="value2" position="top" />
          </Bar>
          <Bar dataKey="value3" fill={COLORS[2]} radius={[4, 4, 0, 0]}>
            <LabelList dataKey="value3" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Flex gap={16} mt={48}>
        <ColorSwatch color="#05D8D2" size={28} radius={2} mt={10} />
        <Text size="28px" lh="42px">
          <b>Cold Email</b>: With the increase in automation and smart filters,
          cold emailing is becoming less effective as a marketing tool.
          Companies are now focusing more on inbound strategies, content
          marketing, and personalized customer engagement methods.
        </Text>
      </Flex>
      <Flex gap={16} mt={16}>
        <ColorSwatch color="#3A8BD9" size={28} radius={2} mt={10} />
        <Text size="28px" lh="42px">
          <b>Document Control</b>: Digitization makes manual document control
          less necessary, and it is often being replaced by document management
          software and systems that automate many of the traditional tasks
          involved in managing documents.
        </Text>
      </Flex>
      <Flex gap={16} mt={16}>
        <ColorSwatch color="#6F40E2" size={28} radius={2} mt={10} />
        <Text size="28px" lh="42px">
          <b>Manual Data Entry</b>: As businesses increasingly adopt automated
          data entry and processing systems, the need for manual data entry is
          decreasing. Advanced software solutions and AI-driven tools are
          streamlining these processes, making manual data entry less relevant.
        </Text>
      </Flex>
    </PDFPage>
  );
};
