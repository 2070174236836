import { FC, useState } from "react";
import { Button, Radio, RadioGroup, Stack, Text } from "@mantine/core";
import { Questionnaire1 } from "./Questionnaire1";
import { Questionnaire2 } from "./Questionnaire2";

interface Props {
  onSubmit: () => void;
}

export const QuestionnaireForm: FC<Props> = ({ onSubmit }) => {
  const [questionnaire, setQuestionnaire] = useState<{
    [key: string]: string | string[];
  }>({});

  const updateQuestionnaire = (
    key: string,
    value: string,
    checked?: boolean
  ) => {
    setQuestionnaire((prevQuestionnaire) => {
      if (!value) {
        return prevQuestionnaire;
      }

      if (checked === undefined) {
        return {
          ...prevQuestionnaire,
          [key]: value,
        };
      }

      const values = (prevQuestionnaire[key] as string[]) || [];

      if (checked) {
        if (value.startsWith("Other")) {
          const index = values.findIndex((item: string) =>
            item.startsWith("Other")
          );

          const updatedValues = [...values];

          if (index !== -1) {
            updatedValues.splice(index, 1, value);
          } else {
            updatedValues.push(value);
          }

          return {
            ...prevQuestionnaire,
            [key]: updatedValues,
          };
        }

        return {
          ...prevQuestionnaire,
          [key]: [...values, value],
        };
      }

      return {
        ...prevQuestionnaire,
        [key]: values.filter((element: string) => !element.startsWith(value)),
      };
    });
  };

  console.log(questionnaire);

  const renderForm = () => {
    switch (questionnaire["Are you planning to switch career soon?"]) {
      case "Yes":
        return (
          <Questionnaire1
            questionnaire={questionnaire}
            updateQuestionnaire={updateQuestionnaire}
          />
        );
      case "No":
        return (
          <Questionnaire2
            questionnaire={questionnaire}
            updateQuestionnaire={updateQuestionnaire}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack align="flex-start" gap="36px">
      <RadioGroup
        w="100%"
        size="md"
        ta="left"
        label={
          <Text fw="bold" ta="left">
            Are you planning to switch career soon?
          </Text>
        }
        onChange={(value) =>
          updateQuestionnaire("Are you planning to switch career soon?", value)
        }
      >
        <Radio
          mt="md"
          label="Yes"
          value="Yes"
          style={{ alignItems: "center" }}
        />
        <Radio mt="md" label="No" value="No" />
      </RadioGroup>
      {renderForm()}
      <Button fullWidth onClick={onSubmit} disabled>
        Submit
      </Button>
    </Stack>
  );
};
