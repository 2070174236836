import { List, Paper, Text, Title } from "@mantine/core";
import { EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Experience = () => {
  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Experience
      </Title>
      <Title size={24} mt={40}>
        Marketing Manager
      </Title>
      <Text mt={8}>TechWave Solutions, San Francisco, CA</Text>
      <Text mt={8} c="#737373">
        March 2020 - Present
      </Text>
      <List mt={8}>
        <List.Item>
          Develop and execute comprehensive marketing strategies to promote IT
          solutions and services.
        </List.Item>
        <List.Item>
          Lead a team of 10 marketing professionals in creating digital
          campaigns, content marketing, and social media strategies.
        </List.Item>
        <List.Item>
          Increased lead generation by 30% and improved customer engagement
          through targeted marketing efforts.
        </List.Item>
        <List.Item>
          Collaborate with the sales team to align marketing initiatives with
          business goals and drive revenue growth.
        </List.Item>
      </List>
      <Title size={24} mt={40}>
        Digital Marketing Specialist
      </Title>
      <Text mt={8}>ByteLogic Technologies, Austin, TX</Text>
      <Text mt={8} c="#737373">
        June 2015 – February 2020
      </Text>
      <List mt={8}>
        <List.Item>
          Managed and optimized online advertising campaigns across various
          platforms, including Google Ads and social media.
        </List.Item>
        <List.Item>
          Conducted market research and competitive analysis to inform marketing
          strategies and product positioning.
        </List.Item>
        <List.Item>
          Implemented SEO and SEM strategies that boosted organic traffic by 25%
          and improved search engine rankings.
        </List.Item>
        <List.Item>
          Coordinated with the design team to create compelling marketing
          materials and landing pages.
        </List.Item>
      </List>
      <Title size={24} mt={40}>
        Marketing Coordinator
      </Title>
      <Text mt={8}>Innovatech Solutions, New York, NY</Text>
      <Text mt={8} c="#737373">
        January 2012 – May 2015
      </Text>
      <List mt={8}>
        <List.Item>
          Assisted in the development and execution of marketing plans and
          campaigns.
        </List.Item>
        <List.Item>
          Monitored and analyzed campaign performance to identify areas for
          improvement and optimization.
        </List.Item>
        <List.Item>
          Managed the company's social media presence, increasing followers and
          engagement rates.
        </List.Item>
        <List.Item>
          Organized and executed events, webinars, and trade shows to promote
          products and services.
        </List.Item>
      </List>
    </Paper>
  );
};
