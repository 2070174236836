export const AuthLinkedInIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7083 0H5.95831C2.85171 0 0.333313 2.5184 0.333313 5.625V18.375C0.333313 21.4816 2.85171 24 5.95831 24H18.7083C21.8149 24 24.3333 21.4816 24.3333 18.375V5.625C24.3333 2.5184 21.8149 0 18.7083 0Z"
        fill="white"
      />
      <path
        d="M18.7083 0H5.95831C2.85171 0 0.333313 2.5184 0.333313 5.625V18.375C0.333313 21.4816 2.85171 24 5.95831 24H18.7083C21.8149 24 24.3333 21.4816 24.3333 18.375V5.625C24.3333 2.5184 21.8149 0 18.7083 0Z"
        fill="#0A66C2"
      />
      <path
        d="M17.6503 20.408H20.3944C20.4938 20.408 20.5892 20.3685 20.6595 20.2982C20.7299 20.2279 20.7694 20.1325 20.7694 20.0331L20.7708 14.2354C20.7708 11.2051 20.1178 8.87588 16.5766 8.87588C15.2305 8.82581 13.961 9.51975 13.276 10.6785C13.2726 10.6841 13.2675 10.6885 13.2615 10.691C13.2554 10.6934 13.2487 10.6938 13.2424 10.6921C13.236 10.6904 13.2304 10.6867 13.2264 10.6815C13.2224 10.6763 13.2202 10.67 13.2202 10.6634V9.53063C13.2202 9.43117 13.1807 9.33579 13.1104 9.26546C13.04 9.19513 12.9446 9.15563 12.8452 9.15563H10.2411C10.1416 9.15563 10.0463 9.19513 9.97593 9.26546C9.9056 9.33579 9.86609 9.43117 9.86609 9.53063V20.0325C9.86609 20.132 9.9056 20.2273 9.97593 20.2977C10.0463 20.368 10.1416 20.4075 10.2411 20.4075H12.985C13.0844 20.4075 13.1798 20.368 13.2501 20.2977C13.3205 20.2273 13.36 20.132 13.36 20.0325V14.8413C13.36 13.3734 13.6384 11.9519 15.4582 11.9519C17.2521 11.9519 17.2753 13.6315 17.2753 14.9363V20.033C17.2753 20.1324 17.3149 20.2278 17.3852 20.2981C17.4555 20.3685 17.5509 20.408 17.6503 20.408ZM3.89581 5.59012C3.89581 6.70237 4.81147 7.61756 5.92381 7.61756C7.03588 7.61747 7.95097 6.70172 7.95097 5.58966C7.95078 4.47759 7.03559 3.5625 5.92344 3.5625C4.811 3.5625 3.89581 4.47787 3.89581 5.59012ZM4.54822 20.408H7.29575C7.39521 20.408 7.49059 20.3685 7.56092 20.2981C7.63124 20.2278 7.67075 20.1324 7.67075 20.033V9.53063C7.67075 9.43117 7.63124 9.33579 7.56092 9.26546C7.49059 9.19513 7.39521 9.15563 7.29575 9.15563H4.54822C4.44876 9.15563 4.35338 9.19513 4.28305 9.26546C4.21273 9.33579 4.17322 9.43117 4.17322 9.53063V20.033C4.17322 20.1324 4.21273 20.2278 4.28305 20.2981C4.35338 20.3685 4.44876 20.408 4.54822 20.408Z"
        fill="white"
      />
    </svg>
  );
};
