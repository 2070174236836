import React from "react";
import { Flex, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import "../Report.scss";

export const RolesToConsider: React.FC = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Unexpected Roles to Consider
      </Title>
      <Text mb={24} c="dimmed">
        Explore unique and surprising career opportunities that align
        unexpectedly with your skills and experiences, offering new horizons for
        professional growth and fulfillment.
      </Text>
      <Flex c="#fff" className="blocks" direction={matches ? "column" : undefined}>
        <Paper bg="#3A8BD9" p="24px" radius={16} className="block-1">
          <Title order={3}>
            Data Strategy Consultant for Non-Profit Organizations
          </Title>
          <Text mt={12}>
            You have a strong background in email marketing and content
            management which implies analytical and strategic planning
            capabilities. As a Data Strategy Consultant, you would help
            non-profits use data efficiently for marketing efforts, targeting,
            and impact analysis. This role combines technology with social
            service, aligning with your industry preferences and desire for
            personal growth while fostering an environment that encourages
            autonomy.
          </Text>
        </Paper>
        <Paper bg="#6F40E2" p="24px" radius={16} className="block block-2">
          <Title order={3}>E-Government Solutions Specialist</Title>
          <Text mt={12}>
            Given your background in marketing and document control at a
            government organization, and current technological skills, a role
            where you can contribute to the digitization and simplification of
            government services would be rewarding. As an E-Government Solutions
            Specialist, you would analyze and propose how technology can improve
            government interactions for citizens. This matches your interest in
            the government sector and technology, providing challenging goals
            and a competitive salary.
          </Text>
        </Paper>
        <Paper bg="#A64DD7" p="24px" radius={16} className="block block-3">
          <Title order={3}>Real Estate Marketing Technology Advisor</Title>
          <Text mt={12}>
            Real estate is on your list of preferred industries, and you have
            expertise in content and email marketing. A specialty role that
            provides a synthesis of these skills could be providing
            technological solutions for marketing in the real estate sector.
            This would allow you to work with various stakeholders, focus on
            technology, and potentially align with your remote work preference
            and entrepreneurial experience.
          </Text>
        </Paper>
      </Flex>
      <LikeResponse />
    </Paper>
  );
};
