export const DocumentControlIcon = () => {
  return (
    <svg
      width="51"
      height="67"
      viewBox="0 0 51 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_724_3898)">
        <path
          d="M8.56219 60.4408C7.41819 60.4408 6.48219 59.5048 6.48219 58.3608V8.44085C6.48219 7.29685 7.41819 6.36085 8.56219 6.36085H29.3622V16.7608C29.3622 19.0618 31.2212 20.9208 33.5222 20.9208H43.9222V58.3608C43.9222 59.5048 42.9862 60.4408 41.8422 60.4408H8.56219ZM8.56219 0.12085C3.97319 0.12085 0.242188 3.85185 0.242188 8.44085V58.3608C0.242188 62.9498 3.97319 66.6808 8.56219 66.6808H41.8422C46.4312 66.6808 50.1622 62.9498 50.1622 58.3608V20.2058C50.1622 17.9958 49.2912 15.8768 47.7312 14.3168L35.9532 2.55185C34.3932 0.99185 32.2872 0.12085 30.0772 0.12085H8.56219ZM15.8422 33.4008C14.1132 33.4008 12.7222 34.7918 12.7222 36.5208C12.7222 38.2498 14.1132 39.6408 15.8422 39.6408H34.5622C36.2912 39.6408 37.6822 38.2498 37.6822 36.5208C37.6822 34.7918 36.2912 33.4008 34.5622 33.4008H15.8422ZM15.8422 45.8808C14.1132 45.8808 12.7222 47.2718 12.7222 49.0008C12.7222 50.7298 14.1132 52.1208 15.8422 52.1208H34.5622C36.2912 52.1208 37.6822 50.7298 37.6822 49.0008C37.6822 47.2718 36.2912 45.8808 34.5622 45.8808H15.8422Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_3898">
          <rect
            width="49.92"
            height="66.56"
            fill="white"
            transform="translate(0.240234 0.119995)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
