import React from "react";
import { Paper, Text, Title } from "@mantine/core";
import { LikeResponse } from "../../../entities/components/LikeResponse";
import "../Report.scss";

export const HigherPositions: React.FC = () => {
  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Higher Positions You Can Aspire to in Your Field
      </Title>
      <Text mb={24} c="dimmed">
        Discover higher-level positions that align with your expertise and
        experience, revealing opportunities for advancement and leadership in
        your role within Email Marketing and the IT industry.
      </Text>
      <div className="piramid">
        <div className="triangle triangle-1">
          <Text>Senior Email Marketing Manager</Text>
        </div>
        <div className="triangle triangle-2">
          <Text>Head of Customer Retention</Text>
        </div>
        <div className="triangle triangle-3">
          <Text>Email Marketing Director</Text>
        </div>
        <div className="triangle triangle-4">
          <Text>Digital Marketing Director</Text>
        </div>
        <div className="triangle triangle-5">
          <Text>Chief Marketing Officer (CMO)</Text>
        </div>
      </div>
      <Text mt={24}>
        <b>Senior Email Marketing Manager</b>: As you already hold the position
        of Email Marketing Manager, stepping up to a Senior Email Marketing
        Manager would be a logical progression. In this role, you'd take on
        greater strategic responsibilities, overseeing a larger team, and
        potentially managing more significant campaigns or projects.
      </Text>
      <Text mt={24}>
        <b>Head of Customer Retention</b>: Building on your experience as a
        Retention Marketing Manager, you could aim for the Head of Customer
        Retention, where you'd be responsible for developing strategies to
        increase customer loyalty and lifetime value across the organization.
      </Text>
      <Text mt={24}>
        <b>Email Marketing Director</b>: Eventually, you could move up to become
        an Email Marketing Director, where you'd oversee the entire email
        marketing department, strategy, and execution. This would require you to
        integrate your knowledge of marketing with high-level decision-making
        and leadership skills.
      </Text>
      <Text mt={24}>
        <b>Digital Marketing Director</b>: If you decide to widen your scope
        beyond email marketing, becoming a Digital\u00A0 Marketing Director
        would leverage your content and marketing expertise to oversee all
        digital marketing efforts, including social media, content marketing,
        and paid advertising.
      </Text>
      <Text mt={24}>
        <b>Chief Marketing Officer (CMO)</b>: With extensive experience in the
        marketing domain, aiming for a C-suite position like Chief Marketing
        Officer could be a sterling goal. Here, you'd be responsible for the
        overall marketing operations of a company and the development of its
        marketing strategy.
      </Text>
      <LikeResponse />
    </Paper>
  );
};
