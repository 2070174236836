export const ReportIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 5.625C5.90625 5.625 5.625 5.90625 5.625 6.25V18.75C5.625 19.0938 5.90625 19.375 6.25 19.375H18.75C19.0938 19.375 19.375 19.0938 19.375 18.75V6.25C19.375 5.90625 19.0938 5.625 18.75 5.625H6.25ZM3.75 6.25C3.75 4.87109 4.87109 3.75 6.25 3.75H18.75C20.1289 3.75 21.25 4.87109 21.25 6.25V18.75C21.25 20.1289 20.1289 21.25 18.75 21.25H6.25C4.87109 21.25 3.75 20.1289 3.75 18.75V6.25ZM8.75 11.25C9.26953 11.25 9.6875 11.668 9.6875 12.1875V16.5625C9.6875 17.082 9.26953 17.5 8.75 17.5C8.23047 17.5 7.8125 17.082 7.8125 16.5625V12.1875C7.8125 11.668 8.23047 11.25 8.75 11.25ZM11.5625 8.4375C11.5625 7.91797 11.9805 7.5 12.5 7.5C13.0195 7.5 13.4375 7.91797 13.4375 8.4375V16.5625C13.4375 17.082 13.0195 17.5 12.5 17.5C11.9805 17.5 11.5625 17.082 11.5625 16.5625V8.4375ZM16.25 13.75C16.7695 13.75 17.1875 14.168 17.1875 14.6875V16.5625C17.1875 17.082 16.7695 17.5 16.25 17.5C15.7305 17.5 15.3125 17.082 15.3125 16.5625V14.6875C15.3125 14.168 15.7305 13.75 16.25 13.75Z"
        fill="black"
      />
    </svg>
  );
};
