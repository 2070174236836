import { Box, Grid, Space } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { MainLayout } from "../../layouts/MainLayout";
import { ContactInfo } from "../../widgets/profile/ContactInfo";
import { CVBlock } from "../../widgets/profile/CVBlock";
import { Goals } from "../../widgets/profile/Goals";
import { Preferences } from "../../widgets/profile/Preferences";
import { Experience } from "../../widgets/profile/Experience";
import { Skills } from "../../widgets/profile/Skills";
import { Education } from "../../widgets/profile/Education";
import { Certifications } from "../../widgets/profile/Certifications";

export const Profile = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");
  const mobile = useMediaQuery("(max-width: 820px)");

  return (
    <MainLayout>
      <Box m={mobile ? 16 : 40}>
        <Grid mb={mobile ? 24 : 32} gutter={mobile ? 24 : 35}>
          <Grid.Col span={laptop ? 12 : 9}>
            <ContactInfo />
          </Grid.Col>
          <Grid.Col span={laptop ? 12 : 3}>
            <CVBlock />
          </Grid.Col>
        </Grid>
        <Goals />
        <Space h={mobile ? "24px" : "32px"} />
        <Preferences />
        <Grid mb={mobile ? 24 : 32} mt={mobile ? 24 : 32} gutter={mobile ? 24 : 35}>
          <Grid.Col span={laptop ? 12 : 8}>
            <Experience />
          </Grid.Col>
          <Grid.Col span={laptop ? 12 : 4}>
            <Skills />
          </Grid.Col>
        </Grid>
        <Grid gutter={35}>
          <Grid.Col span={laptop ? 12 : 6}>
            <Education />
          </Grid.Col>
          <Grid.Col span={laptop ? 12 : 6}>
            <Certifications />
          </Grid.Col>
        </Grid>
      </Box>
    </MainLayout>
  );
};
