import { FC } from "react";
import { Stepper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CheckIcon } from "../../../shared/svg/CheckIcon";
import "./AuthSteps.scss";


interface Props {
  step: number;
  onStepClick: (index: number) => void;
}

export const AuthSteps: FC<Props> = ({ step, onStepClick }) => {
  const matches = useMediaQuery('(max-width: 520px)');

  return (
    <Stepper completedIcon={<CheckIcon />} iconSize={matches ? 16 : 24} active={step} onStepClick={onStepClick}>
      <Stepper.Step icon={<CheckIcon />} label="Sign up" />
      <Stepper.Step icon={<CheckIcon />} label="Upload your CV" />
      <Stepper.Step icon={<CheckIcon />} label="Answer questions" />
    </Stepper>
  );
};
