import { Flex, Paper, Text, Title } from "@mantine/core";
import { EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Skills = () => {
  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Skills
      </Title>
      <Flex wrap="wrap" gap={8} mt={40}>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Digital Marketing</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>SEO & SEM</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Campaign Management</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Social Media Marketing</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Content Creation</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Brand Strategy</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Data Analysis</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Team Leadership</Text>
        </Paper>
        <Paper px={18} py={8} withBorder radius={25}>
          <Text>Market Research</Text>
        </Paper>
      </Flex>
    </Paper>
  );
};
