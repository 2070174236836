import { Flex, Grid, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CheckedIcon, EditIcon } from "../../shared/svg";
import "./Profile.scss";

export const Goals = () => {
  const laptop = useMediaQuery("(max-width: 1100px)");

  return (
    <Paper withBorder radius="24px" p={32} h="100%" pos="relative">
      <div className="edit-icon">
        <EditIcon />
      </div>
      <Title size={36} lh="50px">
        Goals
      </Title>
      <Grid mt={40} gutter={40}>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">Are you planning to switch careers soon?</Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Yes</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            Please specify the role you're planning to switch to
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Data Analysis & Science</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            What is your main reason for wanting to switch careers?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>
              I am concerned about automation in my field and want to remain
              competitive
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={laptop ? 12 : 6}>
          <Text fw="bold">
            What is your timeline for achieving these goals?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Up to 3 years</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text fw="bold">
            How much are you willing to invest in your professional development?
          </Text>
          <Flex mt={8} gap={8}>
            <CheckedIcon />
            <Text>Prepared to make a significant investment (e.g., advanced training, degrees)</Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
