export const RatingMobileIcon = () => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7437 0C17.3187 0 17.8437 0.325 18.0937 0.84375L22.3812 9.675L31.9562 11.0875C32.5187 11.1687 32.9875 11.5625 33.1625 12.1062C33.3375 12.65 33.1937 13.2375 32.7937 13.6375L25.85 20.525L27.4875 30.25C27.5812 30.8125 27.35 31.3813 26.8812 31.7188C26.4125 32.0563 25.8 32.0938 25.3 31.825L16.7375 27.25L8.18748 31.8188C7.68123 32.0875 7.06873 32.05 6.60623 31.7125C6.14373 31.375 5.90623 30.8062 5.99998 30.2437L7.63748 20.5187L0.693728 13.6375C0.287478 13.2375 0.149978 12.6438 0.324978 12.1062C0.499978 11.5687 0.968728 11.175 1.53123 11.0875L11.1062 9.675L15.3937 0.84375C15.65 0.325 16.1687 0 16.7437 0ZM16.7437 4.9375L13.4625 11.7C13.2437 12.1438 12.825 12.4563 12.3312 12.5312L4.93748 13.6187L10.3062 18.9375C10.65 19.2812 10.8125 19.7687 10.7312 20.25L9.46248 27.7313L16.0375 24.2188C16.4812 23.9813 17.0125 23.9813 17.45 24.2188L24.025 27.7313L22.7625 20.2563C22.6812 19.775 22.8375 19.2875 23.1875 18.9438L28.5562 13.625L21.1625 12.5312C20.675 12.4563 20.25 12.15 20.0312 11.7L16.7437 4.9375Z"
        fill="#6F40E2"
      />
    </svg>
  );
};
