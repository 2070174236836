export const DevelopmentPlanIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 4.6875C4.375 4.16797 3.95703 3.75 3.4375 3.75C2.91797 3.75 2.5 4.16797 2.5 4.6875V18.4375C2.5 19.9922 3.75781 21.25 5.3125 21.25H21.5625C22.082 21.25 22.5 20.832 22.5 20.3125C22.5 19.793 22.082 19.375 21.5625 19.375H5.3125C4.79297 19.375 4.375 18.957 4.375 18.4375V4.6875ZM15 8.4375C15 8.95703 15.418 9.375 15.9375 9.375H18.0508L14.375 13.0508L11.2891 9.96484C10.9219 9.59766 10.3281 9.59766 9.96484 9.96484L6.83984 13.0898C6.47266 13.457 6.47266 14.0508 6.83984 14.4141C7.20703 14.7773 7.80078 14.7812 8.16406 14.4141L10.625 11.9531L13.7109 15.0391C14.0781 15.4062 14.6719 15.4062 15.0352 15.0391L19.3711 10.7031V12.8125C19.3711 13.332 19.7891 13.75 20.3086 13.75C20.8281 13.75 21.2461 13.332 21.2461 12.8125V8.4375C21.2461 7.91797 20.8281 7.5 20.3086 7.5H15.9375C15.418 7.5 15 7.91797 15 8.4375Z"
        fill="black"
      />
    </svg>
  );
};
