export const InfoIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_724_4038)">
        <path
          d="M12.7031 4.375C14.858 4.375 16.9246 5.23102 18.4484 6.75476C19.9721 8.27849 20.8281 10.3451 20.8281 12.5C20.8281 14.6549 19.9721 16.7215 18.4484 18.2452C16.9246 19.769 14.858 20.625 12.7031 20.625C10.5482 20.625 8.48162 19.769 6.95788 18.2452C5.43415 16.7215 4.57812 14.6549 4.57812 12.5C4.57812 10.3451 5.43415 8.27849 6.95788 6.75476C8.48162 5.23102 10.5482 4.375 12.7031 4.375ZM12.7031 22.5C15.3553 22.5 17.8988 21.4464 19.7742 19.5711C21.6496 17.6957 22.7031 15.1522 22.7031 12.5C22.7031 9.84784 21.6496 7.3043 19.7742 5.42893C17.8988 3.55357 15.3553 2.5 12.7031 2.5C10.051 2.5 7.50742 3.55357 5.63206 5.42893C3.75669 7.3043 2.70313 9.84784 2.70312 12.5C2.70313 15.1522 3.75669 17.6957 5.63206 19.5711C7.50742 21.4464 10.051 22.5 12.7031 22.5ZM11.1406 15.625C10.6211 15.625 10.2031 16.043 10.2031 16.5625C10.2031 17.082 10.6211 17.5 11.1406 17.5H14.2656C14.7852 17.5 15.2031 17.082 15.2031 16.5625C15.2031 16.043 14.7852 15.625 14.2656 15.625H13.9531V12.1875C13.9531 11.668 13.5352 11.25 13.0156 11.25H11.1406C10.6211 11.25 10.2031 11.668 10.2031 12.1875C10.2031 12.707 10.6211 13.125 11.1406 13.125H12.0781V15.625H11.1406ZM12.7031 10C13.0346 10 13.3526 9.8683 13.587 9.63388C13.8214 9.39946 13.9531 9.08152 13.9531 8.75C13.9531 8.41848 13.8214 8.10054 13.587 7.86612C13.3526 7.6317 13.0346 7.5 12.7031 7.5C12.3716 7.5 12.0537 7.6317 11.8192 7.86612C11.5848 8.10054 11.4531 8.41848 11.4531 8.75C11.4531 9.08152 11.5848 9.39946 11.8192 9.63388C12.0537 9.8683 12.3716 10 12.7031 10Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_4038">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2.70312 2.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
