import { Box } from "@mantine/core";
import { MainLayout } from "../../layouts/MainLayout";
import { Calendar } from "../../widgets/development-plan/Calendar";

export const DevelopmentPlan = () => {
  return (
    <MainLayout>
      <Box m={40}>
        <Calendar />
      </Box>
    </MainLayout>
  );
};
