import { Box, NavLink, Space } from "@mantine/core";
import {
  CoursesIcon,
  DashboardIcon,
  DevelopmentPlanIcon,
  LogOutIcon,
  ProfileIcon,
  ReportIcon,
} from "../../../shared/svg";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./MainSidebar.scss";

export const MainSidebar = () => {
  const matches = useMediaQuery("(max-width: 670px)");
  const location = useLocation();

  return (
    <Box
      className="sidebar"
      w={240}
      bg="#fff"
      pos="relative"
      mih={matches ? "100vh" : "calc(100vh - 75px)"}
    >
      <Space h={matches ? 64 : 32} />
      <NavLink
        label="Dashboard"
        leftSection={<DashboardIcon />}
        active={location.pathname === "/"}
        to="/"
        component={Link}
      />
      <NavLink
        label="Profile"
        leftSection={<ProfileIcon />}
        active={location.pathname === "/profile"}
        to="/profile"
        component={Link}
      />
      <NavLink
        label="Development plan"
        leftSection={<DevelopmentPlanIcon />}
        active={location.pathname === "/development-plan"}
        to="/development-plan"
        component={Link}
      />
      <NavLink
        label="Courses"
        leftSection={<CoursesIcon />}
        active={location.pathname === "/courses"}
        to="/courses"
        component={Link}
      />
      <NavLink
        label="Report"
        leftSection={<ReportIcon />}
        active={location.pathname === "/report"}
        to="/report"
        component={Link}
      />
      <NavLink
        label="Log out"
        leftSection={<LogOutIcon />}
        to="/auth/sing-up"
        component={Link}
      />
    </Box>
  );
};
