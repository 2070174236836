import { MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { theme } from "./config/matine";
import { Autn } from "../processes/auth/Auth";
import { Login } from "../processes/login/Login";
import useGlobalInputFocus from "../shared/components/input/hooks/useGlobalInputFocus";
import { TermsOfService } from "../pages/terms-of-service/TermsOfService";
import { PrivacyPolicy } from "../pages/privacy-policy/PrivacyPolicy";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Profile } from "../pages/profile/Profile";
import { DevelopmentPlan } from "../pages/development-plan/DevelopmentPlan";
import { Courses } from "../pages/courses/Courses";
import { Report } from "../pages/report/Report";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "./App.scss";

export function App() {
  useGlobalInputFocus();

  return (
    <BrowserRouter>
      <MantineProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/development-plan" element={<DevelopmentPlan />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/report" element={<Report />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/auth/:page" element={<Autn />} />
          <Route path="/login/:page" element={<Login />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
}
