import { FC } from "react";
import { SingUpWiget } from "../../widgets/sing-up/SingUp";

interface Props {
  onSingUp: () => void;
}

export const SingUpPage: FC<Props> = ({ onSingUp }) => {
  return <SingUpWiget onSingUp={onSingUp} />;
};
