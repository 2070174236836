export const LogOutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1328 13.3828C22.6211 12.8945 22.6211 12.1016 22.1328 11.6133L17.1328 6.61328C16.6445 6.125 15.8516 6.125 15.3633 6.61328C14.875 7.10156 14.875 7.89453 15.3633 8.38281L18.2305 11.25H10C9.30859 11.25 8.75 11.8086 8.75 12.5C8.75 13.1914 9.30859 13.75 10 13.75H18.2305L15.3633 16.6172C14.875 17.1055 14.875 17.8984 15.3633 18.3867C15.8516 18.875 16.6445 18.875 17.1328 18.3867L22.1328 13.3867V13.3828ZM8.75 6.25C9.44141 6.25 10 5.69141 10 5C10 4.30859 9.44141 3.75 8.75 3.75H6.25C4.17969 3.75 2.5 5.42969 2.5 7.5V17.5C2.5 19.5703 4.17969 21.25 6.25 21.25H8.75C9.44141 21.25 10 20.6914 10 20C10 19.3086 9.44141 18.75 8.75 18.75H6.25C5.55859 18.75 5 18.1914 5 17.5V7.5C5 6.80859 5.55859 6.25 6.25 6.25H8.75Z"
        fill="black"
      />
    </svg>
  );
};
