// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goal {
  padding: 8px 18px 8px 18px;
  border-radius: 25px;
  background: #6F40E21A;
  height: 100%; }
`, "",{"version":3,"sources":["webpack://./src/widgets/dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,qBAAqB;EACrB,YAAY,EAAA","sourcesContent":[".goal {\n  padding: 8px 18px 8px 18px;\n  border-radius: 25px;\n  background: #6F40E21A;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
