import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Center, Container, Space } from "@mantine/core";
import { AuthSteps } from "../../entities/components/auth-steps/AuthSteps";
import { SingUpPage } from "../../pages/sing-up/SingUp";
import { Logo } from "../../shared/svg";
import { UploadCVPage } from "../../pages/upload-cv/UploadCV";
import { QuestionnairePage } from "../../pages/questionnaire/Questionnaire";

export const Autn = () => {
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const { page } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setStep(() => {
      switch (page) {
        case "sing-up":
          return 0;
        case "upload-cv":
          return 1;
        case "questionnaire":
          return 2;
        default:
          return 0;
      }
    });
  }, [page]);

  const renderPage = () => {
    switch (page) {
      case "sing-up":
        return (
          <SingUpPage
            onSingUp={() => {
              setMaxStep(1);
              navigate("/auth/upload-cv");
            }}
          />
        );
      case "upload-cv":
        return (
          <UploadCVPage
            onUpload={() => {
              setMaxStep(2);
              navigate("/auth/questionnaire");
            }}
          />
        );
      case "questionnaire":
        return <QuestionnairePage onSubmit={() => setStep(3)} />;
    }
  };

  const onStepClick = (index: number) => {
    switch (index) {
      case 0:
        navigate("/auth/sing-up");
        break;
      case 1:
        if (index <= maxStep) {
          navigate("/auth/upload-cv");
        }
        break;
      case 2:
        if (index <= maxStep) {
          navigate("/auth/questionnaire");
        }
        break;
    }
  };

  return (
    <Center mt={50} mb={50}>
      <Container size="sm" w={656}>
        <center>
          <Logo />
          <Space h="24px" />
          <AuthSteps step={step} onStepClick={onStepClick} />
          <Space h="24px" />
          {renderPage()}
        </center>
      </Container>
    </Center>
  );
};
