import { Box, Flex, Paper, Text, Title } from "@mantine/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import { LikeResponse } from "../../../entities/components/LikeResponse";
import { InfoIcon } from "../../../shared/svg";
import { useMediaQuery } from "@mantine/hooks";

interface DataItem {
  name: string;
  value: number;
  info: string;
  fill: string;
}

const data: DataItem[] = [
  {
    name: "Email Marketing",
    value: 90,
    info: "You have held roles specifically focused on email marketing, such as your position as Email Marketing Manager at GigaCloud and Junior Email Marketer at EPC Network, LLC. This showcases your extensive experience in creating and managing email campaigns.",
    fill: "#05D8D2",
  },
  {
    name: "Content Management",
    value: 80,
    info: "You have extensive experience in content management, which includes roles like Content Manager at XYZ Company.",
    fill: "#3A8BD9",
  },
  {
    name: "SEO and SEM",
    value: 70,
    info: "Your experience in SEO and SEM is highlighted by your role as SEO Specialist at ABC Corp.",
    fill: "#6F40E2",
  },
  {
    name: "Data Analysis",
    value: 75,
    info: "You have worked as a Data Analyst at DEF Ltd., showcasing your analytical skills.",
    fill: "#A64DD7",
  },
  {
    name: "Social Media Marketing",
    value: 60,
    info: "Your role as Social Media Manager at GHI Inc. highlights your skills in social media marketing.",
    fill: "#DE57CC",
  },
];

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    payload: DataItem;
  }[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper w={384} p={24} radius={8} shadow="0px -20px 80px 0px #0000000a">
        <Text>{payload[0].payload.info}</Text>
      </Paper>
    );
  }

  return null;
};

export const TopTalents = () => {
  const matches = useMediaQuery("(max-width: 1100px)");

  const CustomLabel: React.FC<any> = (props) => {
    const { x, y, width, name, height } = props;

    return (
      <foreignObject
        x={x + matches ? 10 : 25}
        y={y}
        width={width}
        height={height}
        color="#fff"
        style={{ fontSize: "24px", fontWeight: "bold" }}
      >
        <Flex
          align="center"
          py={30}
          gap={5}
          justify={matches ? "space-between" : undefined}
          pr={matches ? 10 : undefined}
        >
          <Text c="#fff" size={matches ? "16px" : "24px"} fw="bold">
            {name}
          </Text>
          <Box w={24} h={24}>
            <InfoIcon />
          </Box>
        </Flex>
      </foreignObject>
    );
  };

  return (
    <Paper radius="24px" p="32px" mt={32} withBorder>
      <Title size={36} mb={8}>
        Your Top Talents
      </Title>
      <Text mb={24} c="dimmed">
        A highlight of your most distinguished skills, showcasing the areas
        where you excel naturally and make the most significant impact.
      </Text>
      <ResponsiveContainer width="100%" height={567}>
        <BarChart data={data} layout="vertical">
          <CartesianGrid horizontal={false} />
          <XAxis
            type="number"
            tickFormatter={(tick) => `${tick}%`}
            interval={0}
            ticks={
              matches
                ? [0, 20, 40, 60, 80, 100]
                : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            }
          />
          <YAxis type="category" dataKey="name" width={0} tick={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="value" isAnimationActive={false} radius={[8, 8, 8, 8]}>
            <LabelList dataKey="value" content={CustomLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <LikeResponse />
    </Paper>
  );
};
