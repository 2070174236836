export const LinkSentIcon = () => {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_438_3869)">
        <path
          d="M52 9.75C63.2054 9.75 73.9519 14.2013 81.8753 22.1247C89.7987 30.0482 94.25 40.7946 94.25 52C94.25 63.2054 89.7987 73.9519 81.8753 81.8753C73.9519 89.7987 63.2054 94.25 52 94.25C40.7946 94.25 30.0482 89.7987 22.1247 81.8753C14.2013 73.9519 9.75 63.2054 9.75 52C9.75 40.7946 14.2013 30.0482 22.1247 22.1247C30.0482 14.2013 40.7946 9.75 52 9.75ZM52 104C65.7913 104 79.0177 98.5214 88.7696 88.7696C98.5214 79.0177 104 65.7913 104 52C104 38.2087 98.5214 24.9823 88.7696 15.2304C79.0177 5.47856 65.7913 0 52 0C38.2087 0 24.9823 5.47856 15.2304 15.2304C5.47856 24.9823 0 38.2087 0 52C0 65.7913 5.47856 79.0177 15.2304 88.7696C24.9823 98.5214 38.2087 104 52 104ZM74.9531 42.4531C76.8625 40.5438 76.8625 37.4563 74.9531 35.5672C73.0437 33.6781 69.9563 33.6578 68.0672 35.5672L45.5203 58.1141L35.9734 48.5672C34.0641 46.6578 30.9766 46.6578 29.0875 48.5672C27.1984 50.4766 27.1781 53.5641 29.0875 55.4531L42.0875 68.4531C43.9969 70.3625 47.0844 70.3625 48.9734 68.4531L74.9531 42.4531Z"
          fill="#05D8D2"
        />
      </g>
      <defs>
        <clipPath id="clip0_438_3869">
          <rect width="104" height="104" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
