import { FC } from "react";
import { Stack, Title } from "@mantine/core";
import { QuestionnaireForm } from "../../features/questionnaire/QuestionnaireForm";

interface Props {
  onSubmit: () => void;
}

export const QuestionnaireWiget: FC<Props> = ({ onSubmit }) => {
  return (
    <Stack align="stretch" justify="center" gap="36px">
      <Title size={36}>Questionnaire</Title>
      <QuestionnaireForm onSubmit={onSubmit} />
    </Stack>
  );
};
