import { FC, ReactNode } from "react";
import { Box } from "@mantine/core";

interface Props {
  children: ReactNode;
  padding?: boolean
}

export const PDFPage: FC<Props> = ({ children, padding }) => (
  <Box w="1191px" h="1684.35px" p={padding ? 84 : 0}>
    {children}
  </Box>
);
